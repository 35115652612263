
import surgicalBg from "../../assets/surgical_cta_bg.png";
import "../../styles/components/CTAs.scss";

export const surgicalVideoCTA = () => {
    return `<section class="relative bg-white  rounded-2.5xl px-3 shadow-card sm:py-0 py-5">
      <div class="absolute -left-20 sm:bottom-0 top-0"><img src=${surgicalBg} alt="surgucal-bg"  width="570" height="100" class="md:w-128 sm:w-[580px] w-full h-auto"/></div>
      <section class="flex xl:flex-row lg:flex-col sm:flex-row flex-col justify-items-center items-center relative">
          <div class="xl:w-2/5 lg:w-full sm:w-2/5">
            <img
              src="https://encord.cdn.prismic.io/encord/aae6680f-94b3-4c51-9d05-4b790bcb9ce6_surgical_asset.svg"
              class="w-auto h-full object-cover"
              alt="surgical_asset"
            />
          </div>
          <div class="pt-10 sm:pb-3 pb-10 xl:w-2/5 lg:w-full sm:w-2/5 flex flex-col justify-items-center gap-y-3">
            <div class="text-purple-1100 text-center text-2xl font-semibold tracking-tighter_3 leading-7">
              The leading training data platform for surgical video
            </div>
            <a
            href="https://encord.com/try-it-free/?&utm_campaign=state-of-surgical-cta"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="opens in a new tab"
            class="pb-2 rounded-2.5xl flex justify-center !no-underline demo-cta"
            >
            <button class="text-white hover:text-purple-1500 px-5 border-2 bg-purple-1500 hover:bg-white border-purple-1500 py-1.5 sm:px-4 sm:py-3 rounded-full sm:text-[14.94px] text-2xs leading-[18.08px] font-bold mx-auto">
              Try Encord today
            </button>
            </a>
            <div class="flex flex-wrap justify-center items-center gap-x-4 gap-y-2 mx-auto mt-1 logo_grayscale_opacity">
            <img src="https://encord.cdn.prismic.io/encord/cf908be0-0f6a-4138-8a91-f0e9d6ef5cd0_uhn-healthcare-page+1.svg"
              alt="brand logo"
              class="w-auto logo_grayscale h-8"
              loading="lazy"
              width="0"
              height="0" />
              
              <img src="https://encord.cdn.prismic.io/encord/57baafa9-a353-4cb1-a199-068fda0d100b_asensus_logo.svg"
              alt="brand logo"
              class="w-auto logo_grayscale h-6"
              loading="lazy"
              width="0"
              height="0" />
  
              <img src="https://encord.cdn.prismic.io/encord/153c5e3f-6ac1-4266-ab01-8d04542cd987_homepage-standford-logo.svg"
              alt="brand logo"
              class="w-auto logo_grayscale h-3"
              loading="lazy"
              width="0"
              height="0" />
  
              <img src="https://encord.cdn.prismic.io/encord/35b9e675-f439-4029-900b-c59e58698f97_mgh-logo.svg"
              alt="brand logo"
              class="w-auto logo_grayscale h-4"
              loading="lazy"
              width="0"
              height="0" />
  
              <img src="https://encord.cdn.prismic.io/encord/50420194-0aba-4453-b361-51a1ab54cf5a_sdsc_logo.svg"
              alt="brand logo"
              class="w-auto logo_grayscale h-4"
              loading="lazy"
              width="0"
              height="0" />
  
              <img src="https://encord.cdn.prismic.io/encord/5cf3ebd1-5bb8-4408-a765-339a742ec4c5_orsi_logo.svg"
              alt="brand logo"
              class="w-auto logo_grayscale h-4"
              loading="lazy"
              width="0"
              height="0" />
  
              <img src="https://encord.cdn.prismic.io/encord/fc124c78-10e7-4f95-9d76-1df49aac18cf_homepage-iterative-logo.svg"
              alt="brand logo"
              class="w-auto [filter:opacity(0.4)] h-6"
              loading="lazy"
              width="0"
              height="0" />
  
              <img src="https://encord.cdn.prismic.io/encord/20a27b1a-c927-4196-b893-4032f79c1d79_childrens_national_logo.svg"
              alt="brand logo"
              class="w-auto logo_grayscale h-6"
              loading="lazy"
              width="0"
              height="0" />
            </div>
          </div>
          <div class="xl:w-1/3 lg:w-full sm:w-1/3">
            <img
              src="https://encord.cdn.prismic.io/encord/7f6b32e8-d66e-49af-882b-824dd77caabb_surgical_cta_asset2.svg"
              class="w-full h-auto"
              alt="surgical_asset-cta"
            />
          </div>
          </section>
        </section>`;
  };