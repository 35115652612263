import React from "react";

export const UpIconHeaderMenu = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    role="presentation"
  >
    <path
      d="M9.75 7.875L6 4.125L2.25 7.875"
      stroke="#1D0642"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
