
import "../../styles/components/CTAs.scss";


export const medicalCta = () => {
    return `<section class="flex flex-col-reverse sm:flex-row gap-3 2xl:gap-4 my-9 rounded-2.5xl px-5 2xl:px-8 cta_bg_dark">
      <section class="flex flex-col flex-1 justify-center py-10 text-white">
      <div class="text-2xl xl:text-xl lgxl_mid:text-xl 2xl:text-2xl max-w-sm 2xl:max-w-none font-semibold">Collaborative DICOM annotation platform for medical imaging</div>
      
      <div class="text-base my-2 xl:text-sm 2xl:text-base !text-white">CT, X-ray, mammography, MRI, PET scans, ultrasound</div> 
  
      <a
          href="https://encord.com/try-it-free/?&utm_campaign=cta-blog-medical-dark"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="opens in a new tab"
          class=" max-w-fit flex demo-cta text-purple-500 my-3 px-[20px] py-[5px] sm:px-[38px] sm:py-[14px] rounded-full sm:text-[14.94px] text-2xs leading-[18.08px] font-bold bg-white"
        >
            See it in action
        </a>
      </section>
  
        <section class="flex-1 mt-6">
          <img 
            src="https://images.prismic.io/encord/e35a12a6-02f0-4b79-bb9a-d89e28ee4577_medical-cta-image.png?auto=compress,format" 
            height="747" 
            width="949" 
            alt="medical banner" 
            class="h-full"
          />
        </section>
    </section>`;
  };