import React from "react";
import "../../../styles/components/Slices/diagramMonet.scss";

const LeftHeaderArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="178"
      height="20"
      viewBox="0 0 178 20"
      fill="none"
      role="presentation"
    >
      <path
        d="M0.765625 19.9998V0.799805H177.166"
        stroke="url(#paint0_linear_left_header)"
        strokeWidth="1.2"
        strokeDasharray="2.4 2.4"
        className="moving_dots"
      />
      <defs>
        <linearGradient
          id="paint0_linear_left_header"
          x1="0"
          y1="0"
          x2="0"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#5E44FF" />
          <stop offset="0.5" stopColor="#BFBFBF" />
          <stop offset="1" stopColor="#5E44FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LeftHeaderArrow;
