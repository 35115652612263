import React from "react";
import Layout from "../components/layout";
import Slices from "../components/Slices";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { linkResolver } from "../../linkResolver";
import { ModalProvider } from "../Contexts/openModal";

const PageV3 = ({ data, location }) => {
  /**
   * isFirstSliceDark is the value the determine that header dark/light mode on the basis of the first slice coming from the prismic
   */
  const { prismicPageV3: { data: _data = {} } = {} } = data;
  const isFirstSliceDark = !!Object.keys(
    _data.body.find((x, i) => i === 0 && x.primary?.theme === "dark") || {}
  )?.length;

  return (
    <Layout
      location={location}
      theme={isFirstSliceDark ? "transparent" : "newDefault"}
    >
      <ModalProvider>
        <Slices slices={data} location={location} />
      </ModalProvider>
    </Layout>
  );
};

export const query = graphql`
  {
    prismicPageV3(uid: { eq: "index" }) {
      data {
        meta_description {
          text
        }
        meta_heading {
          text
        }
        banner_title {
          text
          html
        }
        body {
          ... on PrismicPageV3DataBodyTestimonialMonet {
            id
            slice_type
            items {
              author_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              author_job_designation {
                html
                text
              }
              author_name {
                text
              }
              testimonial_text {
                text
              }
              cta_text {
                text
              }
              cta_link {
                url
              }
              cta_icon {
                url
              }
            }
            primary {
              caption_title {
                text
              }
              theme
              title {
                text
              }
              caption_title_style
              show_caption_title
            }
          }
          ... on PrismicPageV3DataBodyCareerBenifets {
            id
            slice_type
            primary {
              caption_text {
                text
              }
              caption_title {
                text
              }
              descriptor_text {
                text
              }
              show_caption
            }
            slice_label
            items {
              benefit_icon {
                alt
                url
              }
              benefit_text {
                text
              }
            }
          }
          ... on PrismicPageV3DataBodyCompany {
            id
            slice_type
            primary {
              card_text {
                text
              }
              cta_link {
                url
              }
              cta_text {
                text
              }
              section_description {
                text
              }
              section_title {
                text
              }
            }
            items {
              company_blogs {
                id
                slug
                document {
                  ... on PrismicBlog {
                    id
                    uid
                    data {
                      title {
                        text
                      }
                      read_time
                      image {
                        gatsbyImageData
                        alt
                      }
                      content {
                        text
                      }
                    }
                    first_publication_date
                    tags
                  }
                }
              }
            }
          }
          ... on PrismicPageV3DataBodyFeatureTurner {
            id
            slice_type
            items {
              cell_title {
                text
                html
              }
              cell_image {
                alt
                url
                gatsbyImageData(
                  width: 450
                  height: 450
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
              video_link {
                url
              }
              cell_description {
                text
              }
            }
            primary {
              layout_style
              description {
                text
              }
              caption_title {
                text
              }
              title {
                text
              }
              show_cta
              cta_text {
                text
              }
              caption_title_style
              show_caption_title
            }
          }
          ... on PrismicPageV3DataBodyFeatureDali {
            id
            slice_type
            items {
              description {
                text
                html
              }
              hover_icon {
                alt
                url
              }
              icon {
                alt
                url
              }
              title {
                text
              }
            }
            primary {
              description {
                text
              }
              show_cta
              cta_link {
                url
              }
              caption_title {
                text
              }
              title {
                text
              }
              cta_text {
                text
              }
              caption_title_style
              show_caption_title
            }
          }
          ... on PrismicPageV3DataBodyFeatureMonet {
            id
            slice_type
            primary {
              cta_link {
                url
              }
              cta_text {
                text
              }
              description {
                text
              }
              caption_title {
                text
              }
              caption_title_style
              show_caption_title
              title {
                text
              }
              show_cta
            }
            items {
              description {
                text
              }
              icon {
                url
              }
              hovered_icon {
                url
              }
              image {
                url
                gatsbyImageData(
                  width: 368
                  height: 380
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
              title {
                text
              }
              video_link {
                url
              }
            }
          }
          ... on PrismicPageV3DataBodyHeroMonet {
            id
            slice_type
            primary {
              heading_section_position
              cta_link {
                url
              }
              show_cta
              cta_text {
                text
              }
              description {
                text
                html
              }
              trusted_by_logo_alignment
              caption_title {
                text
              }
              caption_title_style
              show_caption_title
              show_logo_caption_title
              title {
                text
                html
              }
              hs_class {
                text
              }
              hs_id {
                text
              }
              show_email_cta
            }
            items {
              trusted_by_logo {
                url
              }
            }
          }
          ... on PrismicPageV3DataBodyTestimonialTurner {
            id
            slice_type
            items {
              author {
                text
              }
              company_logo {
                url
              }
              cta_link {
                url
              }
              cta_text {
                text
              }
              designation {
                text
              }
              title {
                text
                html
              }
            }
          }
          ... on PrismicPageV3DataBodyTestimonialRaphael {
            id
            slice_type
            primary {
              caption_title {
                text
              }
              card_color
              card_layout
              heading_section_position
              caption_title_style
              show_caption_title
              title {
                text
              }
            }
            items {
              author {
                text
              }
              author_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              company_logo {
                alt
                url
              }
              job_designation {
                text
              }
              description {
                text
              }
              showlogo
            }
          }
          ... on PrismicPageV3DataBodyHeroSupportingAssetMonet {
            id
            slice_type
            items {
              gallery_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
          ... on PrismicPageV3DataBodyCtaPicasso {
            id
            primary {
              title {
                text
              }
            }
            items {
              card_image {
                alt
                gatsbyImageData(
                  width: 10
                  placeholder: BLURRED
                  layout: CONSTRAINED
                  height: 10
                )
                url
              }
              cta_link {
                url
              }
              cta_text {
                text
              }
              description {
                text
              }
              product_image {
                gatsbyImageData(
                  width: 10
                  placeholder: BLURRED
                  layout: CONSTRAINED
                  height: 10
                )
                alt
                url
              }
              product_name {
                text
              }
              title {
                text
              }
            }
            slice_type
          }
          ... on PrismicPageV3DataBodyFeatureDegard {
            id
            slice_type
            items {
              description {
                text
              }
              hover_icon {
                alt
                url
              }
              icon {
                alt
                url
              }
              title {
                text
              }
            }
          }
          ... on PrismicPageV3DataBodyFeatureMatisse {
            id
            slice_type
            primary {
              caption_title {
                text
              }
              card_sub_title {
                text
              }
              description {
                text
              }
              title {
                html
                text
              }
              theme
            }
            items {
              description {
                text
              }
              hover_icon {
                alt
                url
              }
              icon {
                alt
                url
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              title {
                text
              }
            }
          }
          ... on PrismicPageV3DataBodyFeatureKlimt {
            id
            slice_type
            primary {
              heading {
                text
                html
              }
            }
            items {
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              link {
                url
              }
              title {
                text
              }
            }
          }
          ... on PrismicPageV3DataBodyHeroRaphael {
            id
            slice_type
            primary {
              caption_title_style
              caption_title {
                text
              }
              description {
                text
              }
              cta_link {
                url
              }
              cta_text {
                text
              }
              hero_image {
                gatsbyImageData(
                  width: 10
                  placeholder: BLURRED
                  layout: CONSTRAINED
                  height: 10
                )
                url
              }
              video_link {
                url
              }
              show_cta
              theme
              title {
                html
                text
              }
              show_email_cta
              hs_class {
                text
              }
              hs_id {
                text
              }
            }
            items {
              trusted_by_logo {
                url
                gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
          ... on PrismicPageV3DataBodyFeatureRaphael {
            id
            slice_type
            items {
              title {
                text
              }
              image {
                alt
                url
                gatsbyImageData(
                  width: 500
                  height: 311
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
              description {
                text
              }
            }
            primary {
              caption_title {
                text
              }
              caption_title_style
              show_caption_title
              layout_style
              title {
                text
              }
              cta_link {
                url
              }
              cta_text {
                text
              }
              description {
                text
              }
              show_cta
              show_email_cta
              hs_class {
                text
              }
              hs_id {
                text
              }
            }
          }
          ... on PrismicPageV3DataBodyFeaturePicasso {
            id
            primary {
              caption_title {
                text
              }
              caption_title_style
              cta_link {
                url
              }
              cta_text {
                text
              }
              description {
                text
              }
              heading_section_position
              show_caption_title
              show_cta
              title {
                text
              }
            }
            items {
              description {
                text
              }
              feature_image {
                alt
                url
              }
              title {
                text
              }
              mobile_asset {
                alt
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicPageV3DataBodyCareerJobs {
            id
            slice_type
            items {
              employement_types
              job_title {
                text
              }
              location_1_job_description_link {
                url
              }
              location_2_job_description_link {
                url
              }
              location_1 {
                text
              }
              location_2 {
                text
              }
              location_types
            }
            primary {
              department {
                text
              }
              section_header {
                text
              }
            }
          }
          ... on PrismicPageV3DataBodyHeroDali {
            id
            primary {
              caption_title {
                text
              }
              caption_title_dropdown
              show_caption_title
              cta_link {
                url
              }
              cta_text {
                text
              }
              descriptor_text {
                text
                html
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              show_email_cta
              title {
                text
                html
              }
              hs_class {
                text
              }
              hs_id {
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicPageV3DataBodySectionMonet {
            id
            slice_label
            slice_type
            primary {
              caption_title {
                text
              }
              caption_title_dropdown
              show_caption_title
              cta_link {
                url
              }
              cta_text {
                text
              }
              descriptor_text {
                text
              }
              show_cta
              layout_alignment
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              mobile_asset {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              header_title {
                text
              }
            }
          }
          ... on PrismicPageV3DataBodyLogosMonet {
            id
            items {
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              logo_link {
                url
              }
            }
            primary {
              heading_section_position
              caption_title {
                text
              }
              caption_title_dropdown
              show_caption_title
              cta_link {
                url
              }
              cta_text {
                text
              }
              descriptor_text {
                text
              }
              text_area
              show_cta
              title {
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicPageV3DataBodySectionDali {
            id
            items {
              caption_title {
                text
              }
              caption_title_icon {
                url
              }
              hovered_icon {
                url
              }
              cta_link {
                url
              }
              cta_title {
                text
              }
              descriptor_text {
                text
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              title {
                text
              }
            }
            primary {
              caption_title {
                text
              }
              caption_title_dropdown
              show_caption_title
              cta_link {
                url
              }
              cta_text {
                text
              }
              descriptor_text {
                text
              }
              header_title {
                text
              }
              show_cta
            }
            slice_label
            slice_type
          }
          ... on PrismicPageV3DataBodyFeatureMunch {
            id
            primary {
              theme
              title {
                text
              }
              show_cta
              description {
                text
              }
              cta_text {
                text
              }
              cta_link {
                url
              }
              caption_title {
                text
              }
              show_caption_title
            }
            slice_label
            slice_type
            items {
              caption_title {
                text
              }
              descriptor_text {
                text
              }
              header_title {
                text
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              show_cta
              cta_text {
                text
              }
              cta_link {
                url
              }
            }
          }
          ... on PrismicPageV3DataBodyStatsMonet {
            id
            items {
              cta_link {
                url
              }
              cta_title {
                text
              }
              logo {
                url
                alt
                gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
              }
              text {
                text
                html
              }
            }
            primary {
              descriptor_text {
                text
              }
              section_title {
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicPageV3DataBodySectionKusama {
            id
            slice_label
            slice_type
            primary {
              cta_link {
                url
              }
              cta_title {
                text
              }
              descriptor_text {
                text
              }
              icon {
                url
                alt
              }
              show_cta
              section_title {
                text
              }
            }
            items {
              show_cta
              title {
                text
              }
              icon {
                url
                alt
              }
              hovered_icon {
                url
                alt
              }
              descriptor_text {
                text
              }
              mobile_card_image {
                url
                alt
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              cta_link {
                url
              }
              cta_text {
                text
              }
              image {
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              video_link {
                url
              }
            }
          }
          ... on PrismicPageV3DataBodySectionPollock {
            id
            slice_type
            slice_label
            primary {
              cta_link {
                url
              }
              show_cta
              section_title {
                text
              }
              icon {
                url
                alt
              }
              descriptor_text {
                text
              }
              cta_title {
                text
              }
            }
            items {
              show_cta
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              header_title {
                text
              }
              descriptor_text {
                text
              }
              cta_title {
                text
              }
              cta_link {
                url
              }
            }
          }
          ... on PrismicPageV3DataBodyHeroKusama {
            id
            slice_type
            slice_label
            primary {
              show_email_cta
              cta_link {
                url
              }
              banner_link {
                url
              }
              banner_text {
                text
                html
              }
              descriptor_text {
                text
              }
              hero_title {
                text
                html
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              hs_class {
                text
              }
              hs_id {
                text
              }
              cta_title {
                text
              }
              caption_title {
                text
              }
              show_caption_title
              theme
              heading_section_position
              top_spacing
              bottom_spacing
              logos_caption_title {
                text
              }
              static_image_1 {
                alt
                url
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
              static_image_2 {
                alt
                url
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
              static_image_3 {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
            items {
              logo {
                url
                alt
                gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
          ... on PrismicPageV3DataBodySectionKahlo {
            id
            slice_type
            slice_label
            items {
              descriptor_text {
                text
              }
              header_title {
                text
              }
              hovered_icon {
                alt
                url
              }
              icon {
                alt
                url
              }
            }
          }
          ... on PrismicPageV3DataBodyDiagramMonet {
            id
            slice_type
            slice_label
            items {
              cta_link {
                url
              }
              cta_text {
                text
              }
              icon {
                alt
                url
              }
              result_caption {
                text
              }
              result_label {
                text
              }
              title {
                text
              }
            }
            primary {
              ai_model_image_1 {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              ai_model_image_2 {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              ai_model_image_3 {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              ai_model_image_4 {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              ai_model_title {
                text
              }
              caption_title {
                text
              }
              caption_title_dropdown
              cta_link {
                url
              }
              cta_text {
                text
              }
              model_prediction_icon {
                url
                alt
              }
              model_prediction_title {
                text
              }
              raw_data_image_1 {
                url
                alt
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              raw_data_image_2 {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              raw_data_image_3 {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              raw_data_image_4 {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              raw_data_title {
                text
              }
              section_header {
                text
              }
              show_cta
              show_caption_title
            }
          }
          ... on PrismicPageV3DataBodyFooterBanner {
            id
            slice_label
            slice_type
            primary {
              cta_description {
                text
              }
              show_cta
              cta_heading {
                text
              }
              cta_link {
                url
              }
              cta_text {
                text
              }
            }
          }
          ... on PrismicPageV3DataBodyLogosDali {
            id
            slice_type
            slice_label
            primary {
              theme
              caption_title {
                text
              }
            }
            items {
              logo {
                alt
                url
                gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
          ... on PrismicPageV3DataBodyFormMonet {
            id
            slice_label
            slice_type
            primary {
              form_bottom_text {
                html
                text
              }
              form_class {
                text
              }
            }
          }
          ... on PrismicPageV3DataBodyFormKusama {
            id
            items {
              logo {
                alt
                url
                gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
              }
            }
            slice_label
            primary {
              form_bottom_text {
                text
                html
              }
              form_class {
                text
              }
              form_heading {
                text
              }
              theme
              form_description {
                text
              }
              logo_heading {
                text
              }
            }
            slice_type
          }
          ... on PrismicPageV3DataBodyFormCc96010fd9a642e1a736c9ce8ffc8a78Monet {
            id
            primary {
              form_class {
                text
              }
              form_bottom_text {
                text
                html
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicPageV3DataBodySectionVinci {
            id
            items {
              member_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              member_name {
                text
              }
              member_position {
                text
              }
            }
            slice_label
            slice_type
            primary {
              header_title {
                text
                html
              }
            }
          }
          ... on PrismicPageV3DataBodyHomepageQuiz {
            id
            slice_label
            slice_type
            items {
              option {
                text
              }
            }
            primary {
              theme
              question_title {
                text
              }
            }
          }
          ... on PrismicPageV3DataBodyHomepageQuizform {
            id
            primary {
              caption_title {
                text
                html
              }
              description {
                html
                text
              }
              title {
                html
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicPageV3DataBodyQuizResult {
            id
            slice_type
            slice_label
            primary {
              caption_title {
                html
                text
              }
              title {
                html
                text
              }
              description {
                html
                text
              }
            }
            items {
              image {
                alt
                url
              }
              title {
                html
                text
              }
            }
          }
          ... on PrismicPageV3DataBodySectionWarhol {
            id
            slice_label
            slice_type
            primary {
              caption_title {
                text
              }
              caption_title_style
              content_section_position
              heading_section_position
              content {
                html
                text
              }
            }
          }
          ... on PrismicPageV3DataBodyFooterRaphael {
            id
            slice_type
            slice_label
            primary {
              cta_description {
                text
              }
              cta_heading {
                html
                text
              }
              cta_link {
                url
              }
              cta_text {
                text
              }
              show_cta
            }
          }
          ... on PrismicPageV3DataBodyCellMonet {
            id
            slice_type
            slice_label
            items {
              author_name {
                html
              }
              date
              description {
                text
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              press_link {
                url
              }
              title {
                text
              }
            }
          }
          ... on PrismicPageV3DataBodyImageMonet {
            id
            primary {
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
            slice_type
            slice_label
          }
          ... on PrismicPageV3DataBodySectionRaphael {
            id
            slice_type
            slice_label
            primary {
              border_style
              cta_link {
                url
              }
              title {
                text
                html
              }
              cta_text {
                text
              }
              show_cta
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              description {
                html
                text
              }
              bottom_spacing
              top_spacing
              reversed
              video_link {
                url
              }
              caption_title {
                text
              }
              caption_title_style
              show_caption_title
              section_caption_text {
                text
              }
              section_caption_title_style
              show_section_caption
              show_section_cta
              section_cta_link {
                url
              }
              section_title {
                text
                html
              }
              section_description {
                text
              }
              section_cta_text {
                text
              }
            }
          }
          ... on PrismicPageV3DataBodySectionMunch {
            id
            slice_type
            slice_label
            primary {
              bottom_spacing
              caption_title {
                text
              }
              caption_title_style
              show_cta
              show_caption_title
              layout_style
              heading_section_position
              top_spacing
              title {
                text
              }
              cta_link {
                url
              }
              cta_text {
                text
              }
              description {
                text
              }
            }
            items {
              label {
                text
              }
              label_icon {
                alt
                url
              }
              label_slide_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              label_slide_video {
                url
              }
              active_label_icon {
                alt
                url
              }
            }
          }
          ... on PrismicPageV3DataBodySectionTurner {
            id
            slice_type
            slice_label
            items {
              cta_link {
                url
              }
              cta_text {
                text
              }
              description {
                text
              }
              image {
                alt
                url
              }
              show_cta
              title {
                text
              }
            }
            primary {
              bottom_spacing
              caption_title {
                text
              }
              caption_title_style
              cta_link {
                url
              }
              cta_text {
                text
              }
              description {
                text
              }
              heading_section_position
              layout_style
              show_caption_title
              show_cta
              show_email_cta
              title {
                text
                html
              }
              top_spacing
            }
          }
          ... on PrismicPageV3DataBodySectionHopper {
            id
            slice_type
            slice_label
            items {
              card_title {
                text
              }
              card_link {
                url
              }
              card_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              video_link {
                url
              }
            }
            primary {
              top_spacing
              title {
                text
              }
              show_cta
              show_caption_title
              layout_style
              heading_section_position
              description {
                text
              }
              cta_text {
                text
              }
              cta_link {
                url
              }
              caption_title_style
              caption_title {
                text
              }
              bottom_spacing
            }
          }
          ... on PrismicPageV3DataBodyCarouselMonet {
            id
            slice_type
            slice_label
            primary {
              top_spacing
              show_cta
              show_caption_title
              layout_style
              heading_section_position
              title {
                text
              }
              description {
                text
              }
              cta_text {
                text
              }
              cta_link {
                url
              }
              caption_title_style
              caption_title {
                text
              }
              bottom_spacing
            }
            items {
              video_link {
                url
              }
              card_title {
                text
              }
              card_description {
                text
              }
              card_asset {
                url
                alt
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
          ... on PrismicPageV3DataBodySectionRand {
            id
            slice_type
            slice_label
            items {
              card_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              card_link {
                url
              }
              card_title {
                text
              }
              card_video {
                url
              }
              content_1_description {
                text
              }
              content_1_title {
                text
              }
              content_2_description {
                text
              }
              content_2_title {
                text
              }
              hover_icon {
                url
                alt
              }
              icon {
                alt
                url
              }
            }
            primary {
              bottom_spacing
              caption_title_style
              caption_title {
                text
              }
              cta_link {
                url
              }
              cta_text {
                text
              }
              description {
                text
              }
              heading_section_position
              layout_style
              show_caption_title
              show_cta
              title {
                text
              }
              top_spacing
            }
          }
          ... on PrismicPageV3DataBodySectionVermeer {
            id
            slice_label
            slice_type
            items {
              card_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              card_link {
                url
              }
              card_logo {
                alt
                url
              }
              card_title {
                text
                html
              }
              video_asset {
                url
              }
            }
            primary {
              bottom_spacing
              caption_title_style
              heading_section_position
              layout_style
              show_caption_title
              show_cta
              top_spacing
              caption_title {
                text
              }
              cta_link {
                url
              }
              cta_text {
                text
              }
              description {
                text
              }
              title {
                text
              }
            }
          }
        }
      }
      uid
      _previewable
    }
  }
`;

export const Head = ({ data }) => {
  return (
    <SEO
      title={`${data?.prismicPageV3?.data?.meta_heading?.text}`}
      description={data?.prismicPageV3?.data?.meta_description?.text}
    />
  );
};

export default withPrismicPreview(PageV3, [
  {
    repositoryName: "encord",
    linkResolver: linkResolver,
  },
]);
