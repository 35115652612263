import React from "react";
import sideBlogCtaBannerDesktopBG from "../../../assets/backgrounds/Ctabackground/VectorDesktop.png";
import sideBlogCtaBannerTabletBG from "../../../assets/backgrounds/Ctabackground/VectorTablet.png";
import CustomButton from "../../../components/CustomButton";
import { useMediaQuery } from "react-responsive";

const SideBlogCtaBanner = () => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isTablet = useMediaQuery({ minWidth: 570, maxWidth: 1023 });
  const isMobile = useMediaQuery({ maxWidth: 569 });
  // const [ratingValue, setRatingValue] = useState(4.8);

  // useEffect(() => {
  //   const fetchRatingValue = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://data.accentapi.com/feed/25429074.json?no_cache=20240624152255"
  //       );
  //       const ratingValueString = response.data?.bio?.ratingValue;
  //       const ratingValueFloat = parseFloat(ratingValueString);
  //       setRatingValue(ratingValueFloat);
  //     } catch (error) {
  //       console.error("Error fetching the rating value:", error);
  //     }
  //   };

  //   fetchRatingValue();
  // }, []);
  // console.log('ratingValue', ratingValue)
  return (
    // <section className="relative  px-3.5 sm:px-8 lg:px-5 py-10 flex flex-col border border-gray-1600 rounded-[10px] text-left sideBlogCtaBanner_bg z-0 ">
    //   <>
    //     <img
    //       src={sideBlogCtaBannerDesktopBG}
    //       className="h-full w-full absolute inset-0 z-10 rounded-2.5xl hidden md:block"
    //       alt="sideBlogCtaBannerDesktopBG"
    //     />
    //     <img
    //       src={sideBlogCtaBannerTabletBG}
    //       className="h-full w-full absolute inset-0 z-10 rounded-2.5xl hidden sm:block md:hidden"
    //       alt="sideBlogCtaBannerTabletBG"
    //     />
    //     <img
    //       src={sideBlogCtaBannerDesktopBG}
    //       className="h-full w-full absolute inset-0 z-10 rounded-2.5xl block sm:hidden"
    //       alt="sideBlogCtaBannerMobileBG"
    //     />
    //   </>
    //   <h6 className="text-purple-1900 text-xs font-semibold tracking-[1.2px] font-manrope uppercase mb-5 z-10">
    //     Encord data engine
    //   </h6>
    //   <h1 className="z-10 text-white text-2xl font-semibold -tracking-[0.6px] font-manrope mb-2.5">
    //     The Unified AI Data Development Platform
    //   </h1>
    //   <p className="z-10 text-[#ffffffb3] font-inter text-sm -tracking-[0.35px] mb-5 max-w-full sm:max-w-[445px] lg:max-w-full">
    //     Consolidates & automate your data curation, annotation, and validation
    //     workflows to power your AI models with the right data.
    //   </p>

    //   <CustomButton
    //     link={`/try-it-free/`}
    //     text={"Learn more"}
    //     icon={
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="14"
    //         height="14"
    //         viewBox="0 0 14 14"
    //         fill="none"
    //       >
    //         <path
    //           d="M2.07874 7.51953L10.0782 7.51953L5.29027 11.6758C5.2137 11.7428 5.26019 11.8672 5.36136 11.8672L6.57132 11.8672C6.62464 11.8672 6.67523 11.848 6.71487 11.8139L11.8815 7.33086C11.9288 7.28984 11.9667 7.23913 11.9928 7.18217C12.0188 7.1252 12.0322 7.06331 12.0322 7.00068C12.0322 6.93806 12.0188 6.87617 11.9928 6.8192C11.9667 6.76224 11.9288 6.71153 11.8815 6.67051L6.6848 2.16016C6.66429 2.14238 6.63968 2.13281 6.6137 2.13281L5.36273 2.13281C5.26155 2.13281 5.21507 2.25859 5.29163 2.32422L10.0782 6.48047L2.07874 6.48047C2.01858 6.48047 1.96937 6.52969 1.96937 6.58984L1.96937 7.41016C1.96937 7.47031 2.01858 7.51953 2.07874 7.51953Z"
    //           fill="#434343"
    //         />
    //       </svg>
    //     }
    //     direction="right"
    //     buttonClass="text-sm !font-semibold bg-white no-underline tracking-tight !text-gray-2500 !font-normal font-manrope !px-3 z-20"
    //   />
    // </section>
    <div className="cta_blog_border_animation border border-gray-1600 rounded-[14px] p-[1px]">
      <section className="relative px-[18px] py-6 flex flex-col rounded-[14px] text-left bg-white z-0 ">
        <>
          {isDesktop && (
            <img
              src={sideBlogCtaBannerDesktopBG}
              className="h-full w-full absolute inset-0 z-10 rounded-[14px] hidden lg:block"
              alt="sideBlogCtaBannerDesktopBG"
            />
          )}
          {isTablet && (
            <img
              src={sideBlogCtaBannerTabletBG}
              className="h-full w-full absolute inset-0 z-10 rounded-[14px] hidden sm:block lg:hidden"
              alt="sideBlogCtaBannerTabletBG"
            />
          )}
          {isMobile && (
            <img
              src={sideBlogCtaBannerDesktopBG}
              className="h-full w-full absolute inset-0 z-10 rounded-[14px] block sm:hidden"
              alt="sideBlogCtaBannerMobileBG"
            />
          )}
        </>
        <img
          src="https://encord.cdn.prismic.io/encord/ZmrVVZm069VX1tfd_Union.svg"
          className="max-w-6 max-h-7 mb-2.5"
          alt="encord logo"
        />
        <p className="z-10 text-purple-1100 text-2xl font-semibold -tracking-[0.6px] font-manrope mb-2.5">
          Better Data, Better AI
        </p>
        <p className="z-10 text-gray-600 font-inter text-sm -tracking-[0.35px]">
          Enhance your AI with automated data curation, annotation, and
          validation.
        </p>
        {/* <div className="pt-2.5 pb-5 sm:py-5 flex justify-start gap-3.5 lg:justify-between items-center">
          <div className="flex items-center gap-2">
            <img src={G2Logo} alt="G2Logo" className="w-[22px] h-[22px]" />
            <StarsLogo />
          </div>
          <p className="text-xs font-medium leading-normal text-gray-600 font-inter -tracking-wider">
            4.8/5
          </p>
        </div> */}
        {/* Week 1 Experiment Button */}
        {/* <CustomButton
          link="https://app.encord.com/register"
          text={"Try Encord for Free"}
          buttonClass="freemium-cta text-sm !font-semibold bg-purple-1500 no-underline -tracking-[0.35px] !text-white !font-normal font-manrope !px-3 z-20 hover:bg-[#5E24FF]"
        /> */}

        {/* Week 2 Experiment Button*/}
        {/* <CustomButton
          link={`/`}
          text={"Explore Our Product Suite"}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M2.07874 7.51953L10.0782 7.51953L5.29027 11.6758C5.2137 11.7428 5.26019 11.8672 5.36136 11.8672L6.57132 11.8672C6.62464 11.8672 6.67523 11.848 6.71487 11.8139L11.8815 7.33086C11.9288 7.28984 11.9667 7.23913 11.9928 7.18217C12.0188 7.1252 12.0322 7.06331 12.0322 7.00068C12.0322 6.93806 12.0188 6.87617 11.9928 6.8192C11.9667 6.76224 11.9288 6.71153 11.8815 6.67051L6.6848 2.16016C6.66429 2.14238 6.63968 2.13281 6.6137 2.13281L5.36273 2.13281C5.26155 2.13281 5.21507 2.25859 5.29163 2.32422L10.0782 6.48047L2.07874 6.48047C2.01858 6.48047 1.96937 6.52969 1.96937 6.58984L1.96937 7.41016C1.96937 7.47031 2.01858 7.51953 2.07874 7.51953Z"
                fill="#434343"
              />
            </svg>
          }
          direction="right"
          buttonClass="product-cta text-sm !font-semibold bg-gray-1600 no-underline !-tracking-[0.35px] !text-gray-2500 !font-normal font-manrope !px-3 z-20 hover:bg-gray-2900"
        /> */}
        <CustomButton
          link={`https://encord.com/try-it-free/?&utm_campaign=cta-blog-sticky`}
          text={"Try it today"}
          buttonClass="demo-cta text-sm !font-semibold bg-purple-1500 no-underline -tracking-[0.35px] !text-white !font-normal font-manrope !px-3 z-20 hover:bg-[#5E24FF] mt-2.5 sm:mt-5"
        />
      </section>
    </div>
  );
};

export default SideBlogCtaBanner;
