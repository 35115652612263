import "../../styles/components/CTAs.scss";

export const samCTA = () => {
  return `<section class="flex flex-col-reverse sm:flex-row gap-3 2xl:gap-4 my-9 rounded-2.5xl md:pl-5 xl:pl-8 2xl:pl-14 cta_bg_dark">
      <section class="flex flex-col flex-[0.58] justify-center py-6 md:py-10 text-white mx-auto px-3 md:px-0">
      <div class="text-lg xl:text-xl lgxl_mid:text-xl 2xl:text-2xl font-semibold">Supercharge Your Annotations with the</div>
      
      <div class="text-xl my-2 xl:text-3xl 2xl:text-3.5xl !text-white font-semibold">Segment Anything Model</div> 
  
      <a
          href="https://encord.com/try-it-free/?&utm_campaign=cta-blog-medical-dark"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="opens in a new tab"
          class="mb-2 max-w-fit flex demo-cta text-purple-500 my-3 px-[20px] py-[5px] sm:px-[38px] sm:py-[14px] rounded-full sm:text-[14.94px] text-2xs leading-[18.08px] font-bold bg-white"
        >
          Label with SAM in Encord
        </a>
      </section>
  
        <section class="flex-[0.42] md:rounded-tr-[12px] rounded-tl-[20px] md:rounded-tl-[12px] rounded-tr-[20px] overflow-hidden">
          <img 
            src="https://images.prismic.io/encord/f6ca3870-2671-4666-b598-ead5c00a6e4f_sam-model.gif" 
            height="747" 
            width="949" 
            alt="medical banner" 
            class="h-full md:rounded-tr-[12px] rounded-tl-[20px] md:rounded-tl-[12px] rounded-tr-[20px] md:mt-6"
          />
        </section>
    </section>`;
};
