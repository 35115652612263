
import webinarVLMBg from "../../assets/backgrounds/webinarVLMCTA_bg.png";
import "../../styles/components/CTAs.scss";

export const webinarVLMCTA = () => {
    return `
    <section class="flex justify-between items-center flex-col sm:flex-row cta_bg_webinar_VLM relative overflow-hidden rounded-2.5xl xl:p-9.5 sm:p-8 p-6 text-white tracking-tighter_3 font-medium">
      <img
        src=${webinarVLMBg}
        height=485
        width=421
        alt="medical banner"
        class="h-auto sm:w-[650px] w-[420px] max-w-none absolute sm:-right-72 -right-28 xl:-top-2 sm:-top-5 -top-16 z-10 sm:rotate-0 -rotate-6"
        />
      <section class="flex flex-col justify-center relative">
        <div class="flex gap-x-2 items-center justify-center sm:justify-normal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g opacity="0.4" filter="url(#filter0_f_255_2719)">
              <circle cx="12" cy="12" r="8" fill="#F5222D"/>
            </g>
            <circle cx="12" cy="12" r="8" fill="url(#paint0_radial_255_2719)"/>
            <circle cx="12.0001" cy="12" r="2.4" fill="#F5222D"/>
            <defs>
              <filter id="filter0_f_255_2719" x="0" y="0" width="24" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_255_2719"/>
              </filter>
              <radialGradient id="paint0_radial_255_2719" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12 12) rotate(153.435) scale(5.36656)">
                <stop offset="0.640625" stop-color="#F5222D"/>
                <stop offset="1" stop-color="#F5222D" stop-opacity="0"/>
              </radialGradient>
            </defs>
          </svg>
          <div class="font-semibold text-xs uppercase tracking-widest_1 opacity-70 font-manrope">Upcoming Webinar</div>
        </div>
  
        <div class="font-semibold xl:text-3.25xl sm:text-2xl text-xl leading-10 my-3 font-manrope sm:text-left text-center">Vision Language Model<span class="md:hidden">s</span><span class="md:inline-block hidden xl:ml-2 ml-1"> Webinar</span></div>
        <div class="flex gap-x-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            role="presentation"
            viewBox="0 0 21 21"
            fill="none"
            class="sm:w-5 w-6 h-auto"
          >
            <path
              d="M18.9726 4.57958H15.7014V3.43468C15.7014 3.34472 15.6278 3.27112 15.5379 3.27112H14.393C14.303 3.27112 14.2294 3.34472 14.2294 3.43468V4.57958H11.2036V3.43468C11.2036 3.34472 11.13 3.27112 11.04 3.27112H9.89512C9.80516 3.27112 9.73156 3.34472 9.73156 3.43468V4.57958H6.70575V3.43468C6.70575 3.34472 6.63215 3.27112 6.54219 3.27112H5.39729C5.30733 3.27112 5.23373 3.34472 5.23373 3.43468V4.57958H1.96258C1.60071 4.57958 1.30835 4.87194 1.30835 5.23381V17.0099C1.30835 17.3718 1.60071 17.6642 1.96258 17.6642H18.9726C19.3344 17.6642 19.6268 17.3718 19.6268 17.0099V5.23381C19.6268 4.87194 19.3344 4.57958 18.9726 4.57958ZM18.1548 16.1922H2.78037V6.0516H5.23373V7.1965C5.23373 7.28646 5.30733 7.36006 5.39729 7.36006H6.54219C6.63215 7.36006 6.70575 7.28646 6.70575 7.1965V6.0516H9.73156V7.1965C9.73156 7.28646 9.80516 7.36006 9.89512 7.36006H11.04C11.13 7.36006 11.2036 7.28646 11.2036 7.1965V6.0516H14.2294V7.1965C14.2294 7.28646 14.303 7.36006 14.393 7.36006H15.5379C15.6278 7.36006 15.7014 7.28646 15.7014 7.1965V6.0516H18.1548V16.1922ZM8.50488 10.1405H4.74306C4.6531 10.1405 4.5795 10.2141 4.5795 10.3041V11.2854C4.5795 11.3754 4.6531 11.449 4.74306 11.449H8.50488C8.59484 11.449 8.66844 11.3754 8.66844 11.2854V10.3041C8.66844 10.2141 8.59484 10.1405 8.50488 10.1405ZM8.50488 12.921H4.74306C4.6531 12.921 4.5795 12.9946 4.5795 13.0846V14.0659C4.5795 14.1559 4.6531 14.2295 4.74306 14.2295H8.50488C8.59484 14.2295 8.66844 14.1559 8.66844 14.0659V13.0846C8.66844 12.9946 8.59484 12.921 8.50488 12.921ZM14.8059 9.29412L12.6879 12.232L11.6084 10.7375C11.547 10.6517 11.4489 10.6026 11.3446 10.6026H10.2222C10.0893 10.6026 10.0117 10.7539 10.0893 10.8622L12.4221 14.0966C12.4522 14.1384 12.4918 14.1724 12.5377 14.1959C12.5835 14.2194 12.6343 14.2316 12.6858 14.2316C12.7373 14.2316 12.7881 14.2194 12.834 14.1959C12.8798 14.1724 12.9194 14.1384 12.9496 14.0966L16.3229 9.42088C16.4006 9.31252 16.3229 9.16123 16.19 9.16123H15.0676C14.9654 9.15919 14.8673 9.2103 14.8059 9.29412Z"
              fill="white"
            />
          </svg>
          <div class="text-sm tracking-tight_1 sm:w-auto w-[172px]">February 29th at 9am PST / 12pm EST / 5pm GMT</div>
        </div>
        <div class="xl:my-5 my-3.5 xl:text-base text-sm xl:max-w-md max-w-xs sm:block hidden">Learn about how models like LLaVA and GPT-4 Vision can aid your annotation projects.</div>
        <a
          href="https://encord.com/lp/llava-webinar/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="opens in a new tab"
          class="demo-cta !no-underline text-purple-1500 py-2 px-4 tracking-tight_1 text-base rounded-full font-manrope font-medium bg-white w-max sm:flex hidden "
        >
          Register now
        </a>
      </section>
  
      <section class="flex xl:-mr-5 sm:-mr-1 sm:gap-x-0 gap-x-2 my-6 sm:my-0">
        <div class="relative text-center flex flex-col items-center w-28">
          <img
            src="https://encord.cdn.prismic.io/encord/f472d9cc-986f-4bf3-844f-addca641b4b5_Frederik+Hvilsh%C3%B8j.svg"
            height="105"
            width="105"
            alt="Frederik Hvilshøj"
            class="w-24 h-auto"
          />
          <div class="text-xs font-semibold mt-3.5">Frederik Hvilshøj</div>
          <div class="text-2xs leading-4 text-gray-2200 mt-0.5">Lead Machine Learning Engineer</div>
        </div>
  
        <div class="relative text-center flex flex-col items-center w-28">
          <img
            src="https://encord.cdn.prismic.io/encord/b2309ef7-9a24-42c4-a6b8-e1b5eb44cbcb_Justin+Sharps.svg"
            height="105"
            width="105"
            alt="Justin Sharps"
            class="w-24 h-auto"
          />
          <div class="text-xs font-semibold mt-3.5">Justin Sharps</div>
          <div class="text-2xs leading-4 text-gray-2200 mt-0.5">Head of Product Engineering</div>
        </div>
      </section>
      <a
          href="https://encord.com/lp/llava-webinar/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="opens in a new tab"
          class="demo-cta !no-underline text-purple-1500 py-2 px-4 tracking-tight_1 text-base rounded-full font-manrope font-medium bg-white w-max flex sm:hidden"
        >
          Register now
        </a>
    </section>`;
  };