import React from "react";

const LeftToUpArrow = () => {
  return (
    <svg
      width="255"
      height="91"
      viewBox="0 0 255 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hidden col-span-2 lg:block -mr-[113px]"
      role="presentation"
    >
      <path d="M2.99998 0L5.88675 5H0.113235L2.99998 0Z" fill="#5E44FF" />

      <path
        class="moving_dots"
        d="M2.99998 4V90.5"
        stroke="url(#paint0_linear_211_12793)"
        stroke-width="1.2"
        stroke-dasharray="2.4 2.4"
      />

      <path
        class="moving_dots"
        d="M5.88675 90H260"
        stroke="url(#paint0_linear_211_12793)"
        stroke-width="1.2"
        stroke-dasharray="2.4 2.4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_211_12793"
          x1="2.5"
          y1="0"
          x2="2.5"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#BFBFBF" />
          <stop offset="1" stop-color="#5E44FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LeftToUpArrow;
