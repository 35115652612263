import React, { useContext, useEffect, useState } from "react";
import ModalContext from "../../../Contexts/openModal";
import Farrow from "../../../assets/icons/NewAnnotate/Farrow";
import bg_video from "../../../assets/new-home-page/bg_graphic_flow.webm";
import graphicPoster from "../../../assets/new-home-page/bg_graphics_poster.webp";
import "../../../styles/components/Slices/heroKusama.scss";
import CustomButton from "../../CustomButton";
import VideoComponent from "../../VideoComponent";
import SingleEmailForm2 from "../../Pieces/SingleEmailForm2";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import KusamaVector from "../../../assets/backgrounds/heroKusamaVector.svg";
import { LogoMarquee } from "../LogosDali";
import { motion, useScroll, useTransform } from "framer-motion";
import useWindowWidth from "../../../hooks/useBetterMediaQuery";
import SlicesVideoComponent from "../SlicesVideoComponent";
import CtaBannerMunchForm from "../../Pieces/CtaBannerMunchForm";
import posterVideo from "../../../assets/videoPoster.png";
import initialImage from "../../../assets/dataAgentsLP.png";

const HeroKusama = ({ slice, location }) => {
  const { toggleModal, setEmail } = useContext(ModalContext);
  const imageUrl = slice?.primary?.image?.url;
  const theme = slice?.primary?.theme ?? "light";
  const leftAllign = slice?.primary?.heading_section_position == "left";
  const isDesktop = useWindowWidth(1024);
  const isTablet = useWindowWidth(521);
  const topSpacing = slice?.primary?.top_spacing;
  const bottomSpacing = slice?.primary?.bottom_spacing;
  const { scrollYProgress } = useScroll();
  const [isLoading, setIsLoading] = useState(true);
  const robotics = location?.pathname === "/industry/robotics/";
  const testingPage = location?.pathname === "/testing-slice/";
  const dataAgents = location?.pathname === "/data-agents/";
  const showFlowlyGraphic = robotics || testingPage || dataAgents;
  console.log(
    "HeroKusamaslice",
    slice?.primary?.cta_link?.text && slice?.primary?.secondary_cta_title?.text
  );
  useEffect(() => {
    if (!imageUrl) return;

    const img = new Image();
    img.src = imageUrl;

    img.onload = () => setIsLoading(false);
  }, [imageUrl]);

  const translateX1 = useTransform(
    scrollYProgress,
    isTablet ? [0, 0.08, 0.9] : [0, 0.03, 0.9],
    [-100, 0, -100]
  );
  const translateX2 = useTransform(
    scrollYProgress,
    isTablet ? [0, 0.08, 0.9] : [0, 0.03, 0.9],
    [100, 0, 100]
  );
  const translateX3 = useTransform(
    scrollYProgress,
    isTablet ? [0, 0.08, 0.9] : [0, 0.03, 0.9],
    [100, 0, 100]
  );

  const opacity1 = useTransform(
    scrollYProgress,
    isTablet ? [0, 0.08, 0.9] : [0, 0.03, 0.9],
    [0, 1, 0]
  );
  const opacity2 = useTransform(
    scrollYProgress,
    isTablet ? [0, 0.08, 0.9] : [0, 0.03, 0.9],
    [0, 1, 0]
  );
  const opacity3 = useTransform(
    scrollYProgress,
    isTablet ? [0, 0.08, 0.9] : [0, 0.03, 0.9],
    [0, 1, 0]
  );

  const sliceEmpty =
    slice?.primary?.banner_text?.text ||
    slice?.primary?.show_caption_title ||
    slice?.primary?.hero_title?.text ||
    slice?.primary?.descriptor_text?.text ||
    slice?.primary?.cta_banner_caption_title?.text ||
    slice?.items?.length !== 0;

  return (
    <>
      <section
        className={`relative ${theme == "light-gradient" ? "light_gradient" : theme == "dark" ? "dark_gradient" : ""}`}
      >
        {/* Slice Background */}
        {!showFlowlyGraphic &&
          theme !== "light-gradient" &&
          theme !== "dark" &&
          isDesktop && (
            <VideoComponent
              autoPlay
              poster={graphicPoster}
              className="absolute left-0 right-0 z-0 [mix-blend-mode:darken] top-24 lg:top-2 sm:top-16"
            >
              <source
                className="row-start-1 row-end-4"
                src={bg_video}
                type="video/webm"
              />
            </VideoComponent>
          )}

        {(theme === "light-gradient" || theme === "dark") && (
          <img
            src={KusamaVector}
            loading="eager"
            alt="stats"
            className="absolute bottom-0 left-0 z-0 block object-cover lg:w-full"
          />
        )}
        <section className="relative px-4 mx-auto overflow-hidden sm:px-12 lg:px-5 max-w-7xl">
          <article
            className={`relative lg:mx-11 ${topSpacing == "80" ? "sm:pt-20 pt-8 " : topSpacing == "40" ? "sm:pt-10 pt-8" : topSpacing == "0" ? "pt-0" : ""} ${bottomSpacing == "80" ? "sm:pb-20 pb-8" : bottomSpacing == "40" ? "sm:pb-10 pb-8" : bottomSpacing == "0" ? "pb-0" : ""} `}
          >
            <section className={`flex flex-col lg:gap-10 max-w-7xl mx-auto `}>
              {leftAllign ? (
                <section
                  id="e-book"
                  className={`flex flex-col justify-start sm:gap-7 gap-5`}
                >
                  <>
                    {slice?.primary?.banner_text?.text &&
                    slice?.primary?.banner_link?.url ? (
                      <a
                        href={slice?.primary?.banner_link?.url}
                        target="_blank"
                        aria-label="opens in a new tab"
                        className="bg-purple-2001 px-3.5 py-1.5 flex gap-2 text-sm sm:text-base font-medium leading-5 font-inter tracking-[-0.7px] items-center rounded-lg "
                      >
                        <div
                          className="text-gray-600 banner-text-strong-style"
                          dangerouslySetInnerHTML={{
                            __html: slice?.primary?.banner_text.html,
                          }}
                        />
                        <Farrow styleClass="sm:w-auto w-5" />
                      </a>
                    ) : (
                      slice?.primary?.banner_text?.text && (
                        <div className="bg-purple-2001 px-3.5 py-1.5 flex gap-2 text-sm sm:text-base font-medium leading-5 font-inter tracking-[-0.7px] items-center rounded-lg ">
                          <div
                            className="text-gray-600 banner-text-strong-style"
                            dangerouslySetInnerHTML={{
                              __html: slice?.primary?.banner_text.html,
                            }}
                          />
                          <Farrow styleClass="sm:w-auto w-5" />
                        </div>
                      )
                    )}
                    {slice?.primary?.show_caption_title &&
                      slice?.primary?.caption_title?.text && (
                        <p
                          className={`text-xs sm:text-base font-medium leading-4 sm:leading-6 uppercase font-inter tracking-widest_1 text-purple-1500 `}
                        >
                          {slice?.primary?.caption_title?.text}
                        </p>
                      )}
                    {slice?.primary?.hero_title?.text && (
                      <h1
                        role="heading"
                        className={`text-2.5xl flex justify-start sm:text-[60px] font-manrope leading-[40px] ${theme == "dark" ? `text-white gradient-text` : " text-purple-1100 strong_color_style "} sm:leading-[68px] font-semibold tracking-[-2.2px] sm:tracking-[-3px]`}
                        dangerouslySetInnerHTML={{
                          __html: slice?.primary?.hero_title?.html,
                        }}
                      />
                    )}
                    {slice?.primary?.descriptor_text?.text && (
                      <p
                        className={`sm:text-lg text-base leading-6 sm:leading-7 ${theme == "light-gradient" ? "text-gray-2700 opacity-80" : theme == "dark" ? "text-white opacity-80" : "text-gray-2500"}  font-normal tracking-tight_1 ${robotics ? "lg:!max-w-[900px]" : "max-w-[792px]"} font-inter`}
                      >
                        {slice?.primary?.descriptor_text?.text}
                      </p>
                    )}
                    {slice?.primary?.cta_banner_caption_title?.text ||
                    slice?.primary?.cta_banner_title?.text ? (
                      <div className="px-3.5 py-[18px] sm:p-[18px] max-w-[611px] cta-banner-munch-shadow rounded-[9px] mb-10 mt-5 lg:mb-5 bg-white border border-gray-1600 flex flex-col items-center sm:items-start justify-center sm:justify-start sm:flex-row gap-[18px]">
                        {slice?.primary?.cta_banner_thumbnail?.url && (
                          <GatsbyImage
                            image={getImage(
                              slice?.primary?.cta_banner_thumbnail
                            )}
                            alt="hero kusama image"
                            className="max-w-[91px] h-full w-full rounded-[3px]"
                            loading="lazy"
                          />
                        )}
                        <div className="flex flex-col flex-1 gap-3">
                          <div className="flex flex-col items-center justify-center gap-1 sm:items-start sm:justify-start">
                            {slice?.primary?.cta_banner_caption_title?.text && (
                              <p className="text-sm font-medium text-center  text-purple-1500 font-inter sm:text-base tracking-tighter_3 sm:text-start">
                                {slice?.primary?.cta_banner_caption_title?.text}
                              </p>
                            )}
                            {slice?.primary?.cta_banner_title?.text && (
                              <p className="text-gray-2700 font-manrope text-2xl sm:text-2.5xl font-semibold tracking-tightest_5 sm:tracking-tightest_6 text-center sm:text-start">
                                {slice?.primary?.cta_banner_title?.text}
                              </p>
                            )}
                          </div>
                          <CtaBannerMunchForm
                            openModal={toggleModal}
                            setEmail={setEmail}
                            border={theme === "dark" ? "dark" : ""}
                            formHsClass={slice?.primary?.hs_class?.text}
                            formId={slice?.primary?.hs_id?.text}
                            buttonText={slice?.primary?.cta_title?.text}
                          />
                        </div>
                      </div>
                    ) : slice?.primary?.show_email_cta ? (
                      <SingleEmailForm2
                        openModal={toggleModal}
                        setEmail={setEmail}
                        border={theme === "dark" ? "dark" : ""}
                        formHsClass={slice?.primary?.hs_class?.text}
                        formId={slice?.primary?.hs_id?.text}
                        buttonText={slice?.primary?.cta_title?.text}
                      />
                    ) : slice?.primary?.cta_title?.text &&
                      slice?.primary?.secondary_cta_title?.text ? (
                      <div className="flex items-center flex_style gap-3">
                        {slice?.primary?.secondary_cta_link?.url ? (
                          <CustomButton
                            target="_blank"
                            link={slice?.primary?.secondary_cta_link?.url}
                            buttonClass="bg-white hover:border-gray-2500 text-gray-2500 border border-gray-2200"
                            text={slice?.primary?.secondary_cta_title?.text}
                          />
                        ) : (
                          slice?.primary?.secondary_cta_title?.text && (
                            <CustomButton
                              onClick={toggleModal}
                              buttonClass="bg-purple-1500 hover:bg-purple-1800 text-white"
                              text={slice?.primary?.secondary_cta_title?.text}
                            />
                          )
                        )}
                        {slice?.primary?.cta_link?.url ? (
                          <CustomButton
                            target="_blank"
                            link={slice?.primary?.cta_link?.url}
                            buttonClass="bg-purple-1500 hover:bg-purple-1800 text-white"
                            text={slice?.primary?.cta_title?.text}
                          />
                        ) : (
                          slice?.primary?.cta_title?.text && (
                            <CustomButton
                              onClick={toggleModal}
                              buttonClass="bg-purple-1500 hover:bg-purple-1800 text-white"
                              text={slice?.primary?.cta_title?.text}
                            />
                          )
                        )}
                      </div>
                    ) : slice?.primary?.cta_link?.url ? (
                      <CustomButton
                        target="_blank"
                        link={slice?.primary?.cta_link?.url}
                        buttonClass="bg-purple-1500 hover:bg-purple-1800 text-white"
                        text={slice?.primary?.cta_title?.text}
                      />
                    ) : (
                      slice?.primary?.cta_title?.text && (
                        <CustomButton
                          onClick={toggleModal}
                          buttonClass="bg-purple-1500 hover:bg-purple-1800 text-white"
                          text={slice?.primary?.cta_title?.text}
                        />
                      )
                    )}
                    {slice?.items?.length !== 0 &&
                      (isLoading ? (
                        <div className="flex justify-center h-24 lg:h-[170px] lg:w-[1152px] mx-auto" />
                      ) : (
                        <div
                          onLoad={() => setIsLoading(false)}
                          className="-mb-10"
                        >
                          {slice?.primary?.logos_caption_title?.text && (
                            <p className="text-center uppercase text-gray-600 sm:text-sm text-2xs tracking-[0.07em]">
                              {slice?.primary?.logos_caption_title?.text}
                            </p>
                          )}
                          <LogoMarquee logoArray={slice} />
                        </div>
                      ))}
                  </>
                </section>
              ) : (
                sliceEmpty && (
                  <section
                    className={`flex flex-col justify-center sm:gap-7 gap-5 items-center`}
                    id="e-book"
                  >
                    <>
                      {slice?.primary?.banner_text?.text &&
                      slice?.primary?.banner_link?.url ? (
                        <a
                          href={slice?.primary?.banner_link?.url}
                          target="_blank"
                          aria-label="opens in a new tab"
                          className="bg-purple-2001 px-3.5 py-1.5  transition-colors duration-500 flex gap-2 text-sm sm:text-base font-medium leading-5 font-inter tracking-[-0.7px] items-center rounded-lg group"
                        >
                          <div
                            className="text-gray-600 banner-text-strong-style"
                            dangerouslySetInnerHTML={{
                              __html: slice?.primary?.banner_text.html,
                            }}
                          />
                          <Farrow styleClass="sm:w-auto w-5 duration-500 ease-in-out mx-1 group-hover:ml-1.5 group-hover:mr-0.5" />
                        </a>
                      ) : (
                        slice?.primary?.banner_text?.text && (
                          <div className="bg-purple-2001 px-3.5 py-1.5  flex gap-2 text-sm sm:text-base font-medium leading-5 font-inter tracking-[-0.7px] items-center rounded-lg group ">
                            <div
                              className="text-gray-600 banner-text-strong-style"
                              dangerouslySetInnerHTML={{
                                __html: slice?.primary?.banner_text.html,
                              }}
                            />

                            <Farrow styleClass="sm:w-auto w-5 duration-500 ease-in-out mx-1 group-hover:ml-1.5 group-hover:mr-0.5" />
                          </div>
                        )
                      )}
                      {slice?.primary?.show_caption_title &&
                        slice?.primary?.caption_title?.text && (
                          <p
                            className={`text-xs sm:text-base font-medium leading-4 sm:leading-6 uppercase font-inter tracking-widest_1 text-purple-1500 text-center `}
                          >
                            {slice?.primary?.caption_title?.text}
                          </p>
                        )}
                      {slice?.primary?.hero_title?.text && (
                        <h1
                          role="heading"
                          className={`text-3.25xl sm:text-[60px] font-manrope leading-[40px] ${theme == "dark" ? `text-white gradient-text` : " text-purple-1100 strong_color_style "} sm:leading-[68px] font-semibold lg:!max-w-[1152px] tracking-[-1.8px] sm:tracking-[-3px] text-center`}
                          dangerouslySetInnerHTML={{
                            __html: slice?.primary?.hero_title?.html,
                          }}
                        />
                      )}
                      {slice?.primary?.descriptor_text?.text && (
                        <p
                          className={`sm:text-lg text-base leading-6 sm:leading-7 ${theme == "light-gradient" ? "text-gray-2700 opacity-80" : theme == "dark" ? "text-white opacity-80" : "text-gray-2500"}  font-normal tracking-tight_1 font-inter  ${robotics ? "lg:!max-w-[900px]" : "max-w-[792px]"}  text-center`}
                        >
                          {slice?.primary?.descriptor_text?.text}
                        </p>
                      )}

                      {slice?.primary?.cta_banner_caption_title?.text ||
                      slice?.primary?.cta_banner_title?.text ? (
                        <div className="px-3.5 py-[18px] sm:p-[18px] max-w-[611px] cta-banner-munch-shadow rounded-[9px] mb-10 mt-5 lg:mb-5 bg-white border border-gray-1600 flex flex-col items-center sm:items-start justify-center sm:justify-start sm:flex-row gap-[18px]">
                          {slice?.primary?.cta_banner_thumbnail?.url && (
                            <GatsbyImage
                              image={getImage(
                                slice?.primary?.cta_banner_thumbnail
                              )}
                              alt="hero kusama image"
                              className="max-w-[91px] h-full w-full rounded-[3px]"
                              loading="lazy"
                            />
                          )}
                          <div className="flex flex-col flex-1 gap-3">
                            <div className="flex flex-col items-center justify-center gap-1 sm:items-start sm:justify-start">
                              {slice?.primary?.cta_banner_caption_title
                                ?.text && (
                                <p className="text-sm font-medium text-center  text-purple-1500 font-inter sm:text-base tracking-tighter_3 sm:text-start">
                                  {
                                    slice?.primary?.cta_banner_caption_title
                                      ?.text
                                  }
                                </p>
                              )}
                              {slice?.primary?.cta_banner_title?.text && (
                                <p className="text-gray-2700 font-manrope text-2xl sm:text-2.5xl font-semibold tracking-tightest_5 sm:tracking-tightest_6 text-center sm:text-start">
                                  {slice?.primary?.cta_banner_title?.text}
                                </p>
                              )}
                            </div>
                            <CtaBannerMunchForm
                              openModal={toggleModal}
                              setEmail={setEmail}
                              border={theme === "dark" ? "dark" : ""}
                              formHsClass={slice?.primary?.hs_class?.text}
                              formId={slice?.primary?.hs_id?.text}
                              buttonText={slice?.primary?.cta_title?.text}
                            />
                          </div>
                        </div>
                      ) : slice?.primary?.show_email_cta ? (
                        <SingleEmailForm2
                          openModal={toggleModal}
                          setEmail={setEmail}
                          border={theme === "dark" ? "dark" : ""}
                          formHsClass={slice?.primary?.hs_class?.text}
                          formId={slice?.primary?.hs_id?.text}
                          buttonText={slice?.primary?.cta_title?.text}
                        />
                      ) : slice?.primary?.cta_title?.text &&
                        slice?.primary?.secondary_cta_title?.text ? (
                        <div className="flex items-center flex_style gap-3">
                          {slice?.primary?.secondary_cta_link?.url ? (
                            <CustomButton
                              target="_blank"
                              link={slice?.primary?.secondary_cta_link?.url}
                              buttonClass="bg-white hover:border-gray-2500 text-gray-2500 border border-gray-2200"
                              text={slice?.primary?.secondary_cta_title?.text}
                            />
                          ) : (
                            slice?.primary?.secondary_cta_title?.text && (
                              <CustomButton
                                onClick={toggleModal}
                                buttonClass="bg-purple-1500 hover:bg-purple-1800 text-white"
                                text={slice?.primary?.secondary_cta_title?.text}
                              />
                            )
                          )}
                          {slice?.primary?.cta_link?.url ? (
                            <CustomButton
                              target="_blank"
                              link={slice?.primary?.cta_link?.url}
                              buttonClass="bg-purple-1500 hover:bg-purple-1800 text-white"
                              text={slice?.primary?.cta_title?.text}
                            />
                          ) : (
                            slice?.primary?.cta_title?.text && (
                              <CustomButton
                                onClick={toggleModal}
                                buttonClass="bg-purple-1500 hover:bg-purple-1800 text-white"
                                text={slice?.primary?.cta_title?.text}
                              />
                            )
                          )}
                        </div>
                      ) : slice?.primary?.cta_link?.url ? (
                        <CustomButton
                          target="_blank"
                          link={slice?.primary?.cta_link?.url}
                          buttonClass="bg-purple-1500 hover:bg-purple-1800 text-white"
                          text={slice?.primary?.cta_title?.text}
                        />
                      ) : (
                        slice?.primary?.cta_title?.text && (
                          <CustomButton
                            onClick={toggleModal}
                            buttonClass="bg-purple-1500 hover:bg-purple-1800 text-white"
                            text={slice?.primary?.cta_title?.text}
                          />
                        )
                      )}

                      {slice?.items?.length !== 0 &&
                        (isLoading ? (
                          <div className="flex items-center justify-center h-24 lg:h-[170px] lg:w-[1152px] mx-auto animate-pulse" />
                        ) : (
                          <div
                            onLoad={() => setIsLoading(false)}
                            className="-mb-10"
                          >
                            {slice?.primary?.logos_caption_title?.text && (
                              <p className="text-center uppercase text-gray-600 sm:text-sm text-2xs tracking-[0.07em]">
                                {slice?.primary?.logos_caption_title?.text}
                              </p>
                            )}
                            <LogoMarquee logoArray={slice} />
                          </div>
                        ))}
                    </>
                  </section>
                )
              )}

              {/* Asset Section */}
              {slice?.primary?.video_link?.url ? (
                <div
                  className={`items-center relative mx-auto rounded-md ${
                    theme === "dark"
                      ? "bg-none"
                      : theme === "light-gradient"
                        ? "bg-none"
                        : "bg-gray-1500 sm:p-7 p-3"
                  }`}
                >
                  <SlicesVideoComponent
                    loop
                    autoPlay
                    muted
                    src={slice?.primary?.video_link?.url}
                    poster={dataAgents ? initialImage : posterVideo}
                    videoComponentClass="w-full h-auto mx-auto rounded-md"
                    dataAosDelay={dataAgents ? "0" : "150"}
                    dataAos={dataAgents ? "none" : "fade"}
                  />
                  {dataAgents && (
                    <StaticImage
                      src={"../../../assets/dataAgentsLP.png"}
                      className={`!absolute sm:inset-7 inset-3 h-auto mx-auto rounded-md -z-10`}
                      placeholder="dominantColor"
                      loading="eager"
                      imgClassName="!absolute sm:inset-7 inset-3 h-auto mx-auto rounded-md -z-10"
                    />
                  )}
                </div>
              ) : imageUrl ? (
                <div
                  className={`items-center relative mx-auto rounded-md ${
                    theme === "dark"
                      ? "bg-none"
                      : theme === "light-gradient"
                        ? "bg-none"
                        : "bg-gray-1500 sm:p-7 p-3"
                  }`}
                >
                  {isLoading ? (
                    <div className="flex justify-center w-[288px] sm:rounded-[20px] rounded-lg h-[203px] sm:w-[672px] sm:h-[348px] lg:w-[896px] lg:h-[463px] xl:w-[1152px] xl:h-[550px] mx-auto animate-pulse bg-gray-100" />
                  ) : (
                    <img
                      src={slice?.primary?.image?.url}
                      alt="hero kusama image"
                      className="sm:rounded-[20px] rounded-lg transition-opacity duration-500"
                      loading="eager"
                      width={1152}
                      height={550}
                      onLoad={() => setIsLoading(false)}
                    />
                  )}

                  {slice?.primary?.static_image_1?.url && (
                    <motion.div
                      style={{ x: translateX1, opacity: opacity1 }}
                      className="p-1 sm:p-[7.29px] static_image_bg rounded-lg sm:rounded-[14px] -left-2 sm:-left-10 bottom-2 sm:bottom-10 absolute"
                    >
                      <GatsbyImage
                        image={getImage(slice?.primary?.static_image_1)}
                        alt="hero kusama image"
                        className="xl:max-w-[261px] max-w-[80px] sm:max-w-[170px] sm:h-[100px] xl:h-[191px] w-full rounded justify-center items-center"
                        loading="lazy"
                      />
                    </motion.div>
                  )}

                  {slice?.primary?.static_image_2?.url && (
                    <motion.div
                      style={{ x: translateX2, opacity: opacity2 }}
                      className="right-0 sm:-right-10 xl:-right-14 p-1 sm:p-[5.14px] static_image_bg rounded-lg sm:rounded-[10px] sm:block absolute top-5 sm:top-10 lg:top-36"
                    >
                      <GatsbyImage
                        image={getImage(slice?.primary?.static_image_2)}
                        alt="hero kusama image"
                        className="xl:max-w-[250px] max-w-[80px] sm:max-w-[180px] sm:h-[120px] xl:h-[170px] rounded w-full"
                        loading="lazy"
                      />
                    </motion.div>
                  )}

                  {slice?.primary?.static_image_3?.url && (
                    <motion.div
                      style={{ x: translateX3, opacity: opacity3 }}
                      className="right-0 sm:-right-10 p-[3.43px] static_image_bg rounded-md sm:block bottom-2 sm:bottom-10 absolute"
                    >
                      <GatsbyImage
                        image={getImage(slice?.primary?.static_image_3)}
                        alt="hero kusama image"
                        className="xl:max-w-[167px] max-w-[50px] sm:max-w-[120px] sm:h-[80px] w-full rounded sm:rounded-md xl:h-[121px]"
                        loading="lazy"
                      />
                    </motion.div>
                  )}
                </div>
              ) : null}
            </section>
          </article>
        </section>
      </section>
    </>
  );
};
export default HeroKusama;
