import React, { useContext } from "react";
import CustomButton from "../../CustomButton";
import { Chevron } from "../../../assets/icons";
import HeadingSection from "../../Pieces/HeadingSection";
import ModalContext from "../../../Contexts/openModal";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
const SectionTurner = ({ slice }) => {
  const topSpacing = slice?.primary?.top_spacing;
  const bottomSpacing = slice?.primary?.bottom_spacing;
  const { toggleModal } = useContext(ModalContext);
  const emptyHeading = !!(
    slice?.primary?.caption_title?.text ||
    slice?.primary?.show_cta ||
    slice?.primary?.description?.text ||
    slice?.primary?.title?.text
  );

  return (
    <section
      className={`bg-gray-2800 py-8 ${topSpacing === "80" && "sm:pt-20"} ${topSpacing === "40" && "sm:pt-10"} ${bottomSpacing === "80" && "sm:pb-20"} ${bottomSpacing === "40" && "sm:pb-10"}`}
    >
      <section className="max-w-6xl px-4 mx-auto lg:px-6 xl:px-0 sm:px-12">
        {emptyHeading && (
          <HeadingSection
            data={slice?.primary}
            toggleModal={toggleModal}
            // descriptionStyle=""
            textAreaStyle="gap-4"
            sectionStyle="lg:!pb-10"
          />
        )}
        <section className="grid grid-cols-1 lg:grid-cols-2  gap-5 sm:gap-8  ">
          {slice?.items?.map((item, index) => (
            <div
              className={`flex ${slice?.primary?.show_image_last ? "flex-col-reverse" : "flex-col"} h-full border rounded-md border-gray-3003 `}
              key={index}
            >
              <div className="">
                <GatsbyImage
                  sizes="(max-width: 600px) 90vw, (max-width: 1200px) 70vw, 50vw"
                  image={getImage(item?.image)}
                  class={`${slice?.primary?.show_image_last ?"rounded-b-md": "rounded-t-md" }`}
                  alt={item?.image?.alt || `turner_image ${index + 1} `}
                />
              </div>
              <div className={`bg-white p-4 ${slice?.primary?.show_image_last ?"rounded-t-md": "rounded-b-md " } sm:p-6 flex flex-col space-y-2.5 flex-grow`}>
                <div className="flex-grow">
                  <p className="font-manrope strong_text text-2xl text-[#262626] font-semibold tracking-[-1.5px]">
                    {item?.title?.text}
                  </p>
                  <p className="text-gray-600 text-base mt-2.5 font-normal tracking-tight_1 sm:tracking-tighter_3">
                    {item?.description?.text}
                  </p>
                </div>
                {item?.show_cta && (
                  <CustomButton
                    direction="right"
                    buttonClass="bg-gray-1500 text-gray-2500"
                    icon={<Chevron />}
                    link={item.cta_link?.url}
                    text={item.cta_text?.text}
                  />
                )}
              </div>
            </div>
          ))}
        </section>
      </section>
    </section>
  );
};
export default SectionTurner;
