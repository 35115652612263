import React from "react";

export const ProductionAI = () => {
  return (
    <svg width="67" height="67" role="presentation" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.9231 34H35.6538M23.9231 43.3846H35.6538M23.9231 52.7692H35.6538M45.0385 55.1154H52.0769C53.9436 55.1154 55.7339 54.3738 57.0539 53.0539C58.3738 51.7339 59.1154 49.9436 59.1154 48.0769V15.5686C59.1154 12.0181 56.4721 9.00564 52.9341 8.71159C51.7641 8.61456 50.593 8.53114 49.4211 8.46133M49.4211 8.46133C49.6287 9.13416 49.731 9.83434 49.7308 10.5385C49.7308 11.1607 49.4836 11.7575 49.0436 12.1974C48.6036 12.6374 48.0069 12.8846 47.3846 12.8846H33.3077C32.0126 12.8846 30.9615 11.8335 30.9615 10.5385C30.9615 9.81585 31.071 9.11826 31.2744 8.46133M49.4211 8.46133C48.5358 5.58964 45.8581 3.5 42.6923 3.5H38C36.4961 3.50035 35.0318 3.9822 33.8215 4.87497C32.6113 5.76774 31.7187 7.02454 31.2744 8.46133M31.2744 8.46133C30.0982 8.53328 28.9282 8.61774 27.7583 8.71159C24.2203 9.00564 21.5769 12.0181 21.5769 15.5686V22.2692M21.5769 22.2692H11.0192C9.07662 22.2692 7.5 23.8458 7.5 25.7885V60.9808C7.5 62.9234 9.07662 64.5 11.0192 64.5H41.5192C43.4618 64.5 45.0385 62.9234 45.0385 60.9808V25.7885C45.0385 23.8458 43.4618 22.2692 41.5192 22.2692H21.5769ZM16.8846 34H16.9096V34.025H16.8846V34ZM16.8846 43.3846H16.9096V43.4096H16.8846V43.3846ZM16.8846 52.7692H16.9096V52.7943H16.8846V52.7692Z" stroke="white" stroke-width="4.44807" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
    
  );
};
