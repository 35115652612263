import React from "react";
import VideoComponent from "../../VideoComponent";


const SlicesVideoComponent = ({ src, poster, videoComponentClass,dataAosDelay="150",dataAos="fade" }) => {
  return (
    <VideoComponent
      poster={poster}
      data-aos={dataAos}
      data-aos-delay={dataAosDelay}
      key={src}
      className={`h-full ${videoComponentClass}`}
    >
      <source src={src} type="video/mp4" />
    </VideoComponent>
  );
};

export default SlicesVideoComponent;
