import React from "react";
import { formatDateYearShort } from "../../../utilities/FormatBlogDate";
import { Link } from "gatsby-link";

const CellMonetCard = ({ pressData }) => {
  return (
    <>
      {pressData?.title?.text && (
        <div
          className={`flex flex-col bg-white rounded-[18px] border border-solid border-gray-1600 h-[100%] card-3d`}
        >
          {pressData?.press_link?.url ? (
            <Link  aria-label="opens in a new tab" to={`${pressData?.press_link?.url}`} target="_blank">
              <img
                src={pressData?.image?.url}
                alt={`sampleImage_${pressData?.image?.alt}`}
                loading="lazy"
                className={`aspect-[16/9] object-contain p-10 w-full rounded-t-[18px] h-full sm:min-h-52 max-h-52`}
              />
            </Link>
          ) : (
            <img
              src={pressData?.image?.url}
              alt={`sampleImage_${pressData?.image?.alt}`}
              loading="lazy"
              className={`aspect-[16/9] object-contain p-10 w-full rounded-t-[18px] h-full sm:min-h-52 max-h-52`}
            />
          )}
          <div className="p-5 flex flex-col gap-3.5 h-full">
            {pressData?.press_link?.url ? (
              <Link  to={`${pressData?.press_link?.url}`} target="_blank"  aria-label="opens in a new tab">
                <p className="text-xl font-semibold line-clamp-2 font-manrope tracking-tightest text-purple-1100">
                  {pressData?.title?.text}
                </p>
              </Link>
            ) : (
              <p className="text-xl font-semibold line-clamp-2 font-manrope tracking-tightest text-purple-1100">
                {pressData?.title?.text}
              </p>
            )}
            <p className="text-base font-medium font-inter tracking-tighter_3 text-gray-1200 sm:line-clamp-6">
              {pressData?.description?.text}
            </p>
            <div className="flex items-center justify-between mt-auto">
              <div
                dangerouslySetInnerHTML={{
                  __html: pressData?.author_name?.html,
                }}
                className="text-xs font-semibold uppercase font-manrope -tracking-tightest_5 text-gray-1200 link_style"
              />
              {pressData?.date && (
                <p className="text-xs font-semibold uppercase font-manrope -tracking-tightest_5 text-gray-1200 link_style">
                  {pressData?.date
                    ? formatDateYearShort(pressData?.date)
                    : "--"}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CellMonetCard;
