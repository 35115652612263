import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { CloseModal, SuccessIcon } from "../../../assets/icons/ModalIcons";

const CustomModal = ({ isSuccess, closeSuccess, message }) => {
  return (
    <>
      <Transition appear show={isSuccess} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {}}
          preventOutsideClick
        >
          <div className="fixed inset-0 bg-black/25" aria-hidden="true" />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full sm:max-w-lg max-w-xs transform overflow-hidden rounded-3.5xl bg-white sm:p-9 py-6 px-5 text-left align-middle shadow-xl transition-all">
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={closeSuccess}
                    className="cursor-pointer flex justify-end mb-2.5"
                  >
                    <CloseModal fillColor="fill-gray-2500" />
                  </span>
                  <div className="flex flex-col items-center justify-between gap-y-3">
                    <SuccessIcon />
                    <Dialog.Title
                      as="h3"
                      className="text-xl text-center strong-text-font sm:text-2xl tracking-tighter_1 text-purple-1100"
                      dangerouslySetInnerHTML={{
                        __html: message,
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={closeSuccess}
                    className="bg-purple-1500 hover:bg-purple-400 font-semibold text-white py-2.5 px-7 tracking-tighter_1 sm:text-lg text-base shadow-lg rounded-full w-full mx-auto sm:mt-5 mt-3.5"
                  >
                    Continue Browsing
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CustomModal;
