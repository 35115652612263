import React from "react";

const ToptoBottomLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="409"
      viewBox="0 0 23 409"
      fill="none"
      className="absolute bottom-[92px] right-[-12px] sm:hidden "
      role="presentation"
    >
      <path
        d="M12.2713 406L17.2713 403.113V408.887L12.2713 406Z"
        fill="#5E44FF"
      />

      <path
        class="moving_dots_left_to_right"
        d="M0.248047 1.5H21.248"
        stroke="url(#paint0_linear_ToptoBottomLeft)"
        stroke-width="1.5"
        stroke-dasharray="2.4 2.4"
      />

      <path
        class="moving_dots_left_to_right"
        d="M22 405.5H15"
        stroke="url(#paint0_linear_ToptoBottomLeft)"
        stroke-width="1.5"
        stroke-dasharray="2.4 2.4"
      />

      <path
        class="moving_dots_left_to_right"
        d="M20 1.5V405.5"
        stroke="url(#paint0_linear_ToptoBottomLeft)"
        stroke-width="1.5"
        stroke-dasharray="2.4 2.4"
      />

      <path
        class="moving_dots"
        d="M23.248 1.5H25.748"
        stroke-width="1.5"
        stroke-dasharray="2.4 2.4"
        stroke="url(#paint0_linear_ToptoBottomLeft)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_ToptoBottomLeft"
          x1="2.5"
          y1="0"
          x2="2.5"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#BFBFBF" />
          <stop offset="1" stop-color="#5E44FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ToptoBottomLeft;
