import React from "react";

export const ResourcesIcon = ({stroke}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    role="presentation"
  >
    <path
      d="M15.1875 4.78125C15.1875 6.48975 12.417 7.875 9 7.875C5.583 7.875 2.8125 6.48975 2.8125 4.78125M15.1875 4.78125C15.1875 3.07275 12.417 1.6875 9 1.6875C5.583 1.6875 2.8125 3.07275 2.8125 4.78125M15.1875 4.78125V13.2188C15.1875 14.9273 12.417 16.3125 9 16.3125C5.583 16.3125 2.8125 14.9273 2.8125 13.2188V4.78125M15.1875 4.78125V7.59375M2.8125 4.78125V7.59375M15.1875 7.59375V10.4062C15.1875 12.1148 12.417 13.5 9 13.5C5.583 13.5 2.8125 12.1148 2.8125 10.4062V7.59375M15.1875 7.59375C15.1875 9.30225 12.417 10.6875 9 10.6875C5.583 10.6875 2.8125 9.30225 2.8125 7.59375"
      stroke={stroke}
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
