import React, { useState } from "react";
import { motion } from "framer-motion";
import CustomButton from "../CustomButton";
import SlicesVideoComponent from "../Slices/SlicesVideoComponent";
import { WhiteChevron } from "../../assets/icons";

const DetailedProductAccordian = ({ accordionData, isFirstItem }) => {
  const [isOpen, setIsOpen] = useState(isFirstItem);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div className="accordion">
      <div
       role="button" 
       tabIndex={0} 
        className={`accordion-header flex items-center justify-between cursor-pointer py-2.5 pr-5 ${isOpen ? "" : ""}`}
        onClick={toggleAccordion}
      >
        <div className="flex items-center gap-2 accordian_header_style">
          <img
            src={
              isOpen
                ? accordionData?.feature_hover_icon?.url
                : accordionData?.feature_icon?.url
            }
            alt={
              isOpen ? accordionData?.feature_hover_icon?.alt : "Accordian Icon"
            }
            className="size-3.5"
          />
          <p className={`${isOpen ? "!text-gray-2700" : "!text-gray-1200"}`}>
            {accordionData?.feature_title?.text}
          </p>
        </div>
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.5 }}
        >
          <path
            d="M10.3595 3H9.48062C9.42085 3 9.3646 3.0293 9.32945 3.07734L6.00015 7.66641L2.67085 3.07734C2.6357 3.0293 2.57945 3 2.51968 3H1.64077C1.5646 3 1.52007 3.08672 1.5646 3.14883L5.69663 8.84531C5.84663 9.05156 6.15366 9.05156 6.30249 8.84531L10.4345 3.14883C10.4802 3.08672 10.4357 3 10.3595 3Z"
            fill={isOpen ? "#595959" : "#8C8C8C"}
          />
        </motion.svg>
      </div>
      <motion.div
        className={` overflow-hidden flex flex-col gap-3.5 ${isOpen && "my-2"}`}
        initial={{ height: 0 }}
        animate={{ height: isOpen ? "auto" : 0 }}
        transition={{ duration: 0.5 }}
      >
        {accordionData?.feature_video_asset?.url ? (
          <SlicesVideoComponent
            loop
            autoPlay
            muted
            videoComponentClass={`rounded-md flex-shrink-0 aspect-square max-h-[190px] h-full w-full`}
            src={accordionData?.feature_video_asset?.url}
          />
        ) : (
          accordionData?.feature_image_asset?.url && (
            <img
              className="max-h-[190px]"
              src={accordionData?.feature_image_asset?.url}
              alt={
                accordionData?.feature_image_asset?.alt || "Feature Image Asset"
              }
            />
          )
        )}

        {accordionData?.feature_text?.html && (
          <div
            className="accordion_text"
            dangerouslySetInnerHTML={{
              __html: accordionData?.feature_text?.html,
            }}
          />
        )}
        {accordionData?.feature_cta_link?.url && (
          <CustomButton
            icon={<WhiteChevron />}
            direction="right"
            link={accordionData?.feature_cta_link?.url}
            text={accordionData?.feature_cta_text?.text}
            buttonClass="demo-cta text-sm !font-semibold bg-purple-1500 no-underline -tracking-[0.35px] !text-white !font-normal font-manrope !no-underline !px-3 hover:bg-[#5E24FF] !max-w-max"
          />
        )}
      </motion.div>
    </div>
  );
};

export default DetailedProductAccordian;
