import React from "react";

const RightToLeftArrow = ({ styleClass }) => {
  return (
    <svg
      width="127"
      height="80"
      viewBox="0 0 127 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${styleClass}`}
      role="presentation"
    >
      <path d="M0 40L5 37.1132V42.8868L0 40Z" fill="#5E44FF" />

      <path
        class="moving_dots"
        d="M2 40H142.3"
        stroke="url(#paint0_linear_211_12793)"
        stroke-width="1.2"
        stroke-dasharray="2.4 2.4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_211_12793"
          x1="2.5"
          y1="0"
          x2="2.5"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#BFBFBF" />
          <stop offset="1" stop-color="#5E44FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RightToLeftArrow;
