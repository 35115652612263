import React from "react";
import CustomButton from "../../CustomButton";
import SingleEmailForm2 from "../SingleEmailForm2";

const HeadingSection = ({
  data,
  headingStyle = "text-purple-1100",
  sectionStyle = "",
  descriptionStyle = " text-sm sm:text-base",
  labelStyle = "text-purple-1500",
  textAreaStyle = "gap-5",
  toggleModal,
  setEmail,
  ctaLink,
}) => {
  return (
    <section
      className={` ${sectionStyle} flex flex-col justify-center pb-10 lg:pb-20 sm:justify-start ${(data?.heading_section_position === "Center" || data?.heading_section_position === "center") && "!max-w-fit !mx-auto"} `}
    >
      {data?.caption_title?.text && data?.show_caption_title && (
        <div
          className={`${(data?.heading_section_position === "Center" || data?.heading_section_position === "center") && "self-center"}`}
        >
          <p
            className={`inline text-xs font-semibold leading-4 uppercase font-manrope tracking-widest_1  ${(data?.caption_title_style || data?.caption_title_dropdown) === "rounded rectangle" && "bg_text"} ${labelStyle}`}
          >
            {data?.caption_title?.text}
          </p>
        </div>
      )}
      <div className={`flex flex-col mt-6 ${textAreaStyle}`}>
        {(data?.title?.text ||
          data?.header_title?.text ||
          data?.section_header?.text) && (
          <p
            className={`text-2.5xl sm:text-3.25xl font-manrope text-left font-semibold leading-9 tracking-[-1.5px] max-w-full ${(data?.heading_section_position === "Center" || data?.heading_section_position === "center") && "text-center"} ${headingStyle}`}
          >
            {data?.title?.text
              ? data?.title?.text
              : data?.header_title?.text
                ? data?.header_title?.text
                : data?.section_header?.text}
          </p>
        )}
        {(data?.description?.text || data?.descriptor_text) && (
          <p
            className={` leading-6 sm:leading-7 text-gray-600 font-normal tracking-tight_1 font-inter ${descriptionStyle} ${(data?.heading_section_position === "Center" || data?.heading_section_position === "center") && "text-center"}`}
          >
            {data?.description?.text
              ? data?.description?.text
              : data?.descriptor_text?.text}
          </p>
        )}
        {data.show_cta ? (
          ctaLink ? (
            <CustomButton
              link={ctaLink}
              target="_blank"
              text={data?.cta_text?.text}
              buttonClass={`bg-purple-1500 text-white hover:bg-[#5E24FF] ${data?.heading_section_position === "Center" || data?.heading_section_position === "center" ? "self-center" : ""}`}
            />
          ) : (
            <CustomButton
              onClick={() => toggleModal()}
              text={data?.cta_text?.text}
              buttonClass={`bg-purple-1500 text-white hover:bg-[#5E24FF] ${data?.heading_section_position === "Center" || data?.heading_section_position === "center" ? "self-center" : ""}`}
            />
          )
        ) : data.show_email_cta ? (
          <SingleEmailForm2
            setEmail={setEmail}
            openModal={toggleModal}
            buttonText={data?.cta_text?.text}
          />
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default HeadingSection;
