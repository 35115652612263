
import "../../styles/components/CTAs.scss";

export const indexWebinarCTA = () => {
    return `
      <div class="cta_border_animation !rounded-[14px] p-[1px]">
      <section class="flex flex-col px-3 pt-4 pb-7 sm:px-6 sm:py-6 items-center !rounded-[14px] index_CTA_bg relative overflow-hidden z-10">
      <img
                src="https://encord.cdn.prismic.io/encord/Zr319UaF0TcGI9UB_mobile.svg"
                class="h-full w-full absolute inset-0 object-cover -z-10 rounded-[14px] block sm:hidden"
                alt="MobileBg"
              />
              <img
                src="https://encord.cdn.prismic.io/encord/Zr2s7UaF0TcGI8wo_desktop.svg"
                class="h-full w-full absolute inset-0 object-cover -z-10 rounded-[14px] hidden lg:block"
                alt="desktopBG"
              />
              <img
                src="https://encord.cdn.prismic.io/encord/Zr319kaF0TcGI9UC_tablet.svg"
                class="h-full w-full absolute inset-0 object-cover -z-10 rounded-[14px] hidden sm:block lg:hidden"
                alt="tabletBG"
              />
      <img
          src="https://encord.cdn.prismic.io/encord/Zr2p0EaF0TcGI8wL_Group48097075.svg"
          alt="Encord Logo"
          class="absolute -left-3  xl:left-2 -bottom-1 index_cta_image_width rounded-lg"
        />
          <img
          src="https://encord.cdn.prismic.io/encord/Zr2pAEaF0TcGI8wI_Group48097071.svg"
          height="130"
          width="159"
          alt="Encord Logo"
          class="absolute  -right-3  sm:-right-[26px] xl:right-8 index_cta_image_width sm:w-auto bottom-3 rounded-lg"
        />
      <div class="flex justify-end w-full">
      <img
          src="https://encord.cdn.prismic.io/encord/ZqoesB5LeNNTxpcG_EncordLogoDarkMode.svg"
          height="24"
          width="120"
          alt="Encord Logo"
        />
      </div>
      <div class="text-sm sm:text-base font-inter font-medium -tracking-wider sm:tracking-tight_1 text-white text-center index_CTA relative">
      <div class="w-full flex justify-end mb-2 sm:-my-3 xl:pr-12 " >
      <img
          src="https://images.prismic.io/encord/ZqouJh5LeNNTxpnM_upcomingwebinar.png?auto=format,compress"
          height="32"
          width=""
          alt="Upcoming banner"
          class="w-[116px] !mt-2.5 sm:w-[173px]"
        />
      </div>
    <h3>Drowning in Data? </h3> <strong class="block">How To Curate Your <br class="sm:hidden block"/> AI Data At Scale</strong> Thu, Aug 22, 6:00pm BST, 1pm EST,<br class="sm:hidden block"/> 10am PST
      </div>
    <a
            href="https://encord.com/webinar/index-webinar/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="opens in a new tab"
            class=" rounded-full flex justify-center sm:justify-start mt-[18px] sm:mt-3 hover:!no-underline index-webinar-cta"
          >
            <button class="bg-gray-1600 flex items-center gap-1 px-3 py-2 rounded-full text-sm tracking-tighter font-semibold  text-gray-2500 font-manrope">
          RSVP now
          <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          role="presentation"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M2.67337 9.66797L12.9583 9.66797L6.80247 15.0117C6.70403 15.0979 6.7638 15.2578 6.89387 15.2578L8.44954 15.2578C8.51809 15.2578 8.58313 15.2332 8.63411 15.1893L15.2769 9.42539C15.3377 9.37265 15.3865 9.30745 15.42 9.23421C15.4534 9.16097 15.4707 9.08139 15.4707 9.00088C15.4707 8.92036 15.4534 8.84079 15.42 8.76755C15.3865 8.6943 15.3377 8.62911 15.2769 8.57637L8.59544 2.77734C8.56907 2.75449 8.53743 2.74219 8.50403 2.74219L6.89563 2.74219C6.76555 2.74219 6.70579 2.90391 6.80422 2.98828L12.9583 8.33203L2.67337 8.33203C2.59602 8.33203 2.53274 8.39531 2.53274 8.47266L2.53274 9.52734C2.53274 9.60469 2.59602 9.66797 2.67337 9.66797Z"
            fill="#434343"
            className=" dark:fill-[#C5BBFF] fill-[#434343]"
          />
        </svg>
            </button>
        </a>
    </section>
      </div>
      
        `;
  };