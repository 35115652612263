import React from "react";

const ThreeLines = () => {
  return (
    <svg
      width="55"
      height="27"
      viewBox="0 0 55 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="rotate-90 sm:rotate-0"
      role="presentation"
    >
      <path
        className="moving_dots_left_to_right"
        d="M34.4805 0.600098L34.4805 26.6001"
        stroke="url(#paint0_linear_ThreeLines)"
        stroke-dasharray="2 2"
      />
      <path
      className="moving_dots_left_to_right"

        d="M27.4805 0.600098L27.4805 26.6001"
        stroke="url(#paint0_linear_ThreeLines)"
        stroke-dasharray="2 2"
      />
      <path
        className="moving_dots_left_to_right"
        d="M20.4805 0.600098L20.4805 26.6001"
        stroke="url(#paint0_linear_ThreeLines)"
        stroke-dasharray="2 2"
      />
       <defs>
        <linearGradient
          id="paint0_linear_ThreeLines"
          x1="2.5"
          y1="0"
          x2="2.5"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#BFBFBF" />
          <stop offset="1" stop-color="#5E44FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ThreeLines;
