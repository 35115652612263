import React from "react";

const UptoDownGradientArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="20"
      viewBox="0 0 6 20"
      fill="none"
      className="self-center lg:hidden"
      role="presentation"
    >
      {/* Arrow head path */}
      <path
        d="M3 20L5.88675 15H0.113249L3 20Z"
        fill="url(#paint0_linear_211_12793)"
      />
      {/* Arrow body path */}
      <path
      class="moving_dots_left_to_right"
        d="M2.9 0L2.9 15.7"
        stroke="url(#paint0_linear_211_12793)"
        strokeWidth="1"
        strokeDasharray="4,2" // Single dash pattern for the body
      />
      <defs>
        <linearGradient
          id="paint0_linear_211_12793"
          x1="2.5"
          y1="0"
          x2="2.5"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#BFBFBF" />
          <stop offset="1" stopColor="#5E44FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UptoDownGradientArrow;
