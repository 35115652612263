import React from "react";
import "../../styles/components/CTAs.scss";
import G2ReviewIcon from "./G2ReviewIcon";
import CustomButton from "../CustomButton";
import { useMediaQuery } from "react-responsive";

const CompanyCard = ({ cardData, key, toggleModal }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const encordSvgIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" role="presentation" width="13" height="12" viewBox="0 0 13 12" fill="none">
    <path d="M11.0207 2.22656H10.2016C10.0867 2.22656 9.97773 2.2793 9.90742 2.36953L5.07578 8.49023L2.75898 5.55469C2.72393 5.51019 2.67926 5.47421 2.62832 5.44945C2.57737 5.42469 2.52148 5.41179 2.46484 5.41172H1.6457C1.56718 5.41172 1.52382 5.50195 1.57187 5.56289L4.78164 9.6293C4.93164 9.81914 5.21992 9.81914 5.37109 9.6293L11.0945 2.37656C11.1426 2.3168 11.0992 2.22656 11.0207 2.22656Z" fill="#5E44FF"/>
  </svg>
`;

  const defaultSvgIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" role="presentation" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M2.5 10L9 2" stroke="#F5222D"/>
    <path d="M2.5 2L9 10" stroke="#F5222D"/>
  </svg>
`;

  const addSvgToListItems = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    doc.querySelectorAll("li").forEach((li) => {
      const svgToUse =
        cardData?.company_name?.text === "Encord"
          ? encordSvgIcon
          : defaultSvgIcon;
      li.innerHTML = `${svgToUse} ${li.innerHTML}`;
    });

    return doc.body.innerHTML;
  };

  const processedHtml = cardData?.key_features?.html
    ? addSvgToListItems(cardData.key_features.html)
    : "";
  const handleModelOpen = () => {
    toggleModal();
  };

  return (
    <div
      className={`${cardData?.company_name?.text === "Encord" && "encord_company_card"} py-4 px-3 company_card_width`}
    >
      <div
        className={` flex flex-col sm:flex-row lg:flex-col lg:items-center gap-[18px] lg:gap-3  company_card_style`}
        key={key}
      >
        <div className="flex flex-col items-center gap-3 sm:gap-2">
          <img
            src={cardData?.company_image?.url}
            alt={cardData?.company_image?.alt || "Company Image"}
            className="size-[100px] "
          />
          <p
            className={`${cardData?.company_name?.text === "Encord" ? "!text-purple-1100" : "!text-gray-2700"}`}
          >
            {cardData?.company_name?.text}
          </p>
        </div>

        <div className="max-w-[196px] h-[1px] w-full bg-gray-1600 hidden lg:block " />

        <div className="flex flex-col gap-2 pb-2.5 lg:items-center key_feature_style">
          <p className="sub_heading_style">Key features</p>
          <div
            dangerouslySetInnerHTML={{
              __html: processedHtml,
            }}
          />
        </div>

        {cardData?.company_name?.text === "Encord" && (
          <>
            <div className="max-w-[196px] h-[1px] w-full bg-gray-1600 hidden lg:block" />

            <div className="flex flex-col items-center gap-2 sm:items-end lg:items-center key_feature_style sm:ml-auto lg:ml-0">
              <p className=" sub_heading_style">Reviews</p>
              <G2ReviewIcon />
              <p className="my-0 !font-inter !font-medium !text-sm !text-gray-600">
                {cardData?.reviews?.text}/5
              </p>
            </div>
          </>
        )}
        {cardData?.cta_button_text?.text && isDesktop && (
          <CustomButton
            {...(cardData?.cta_button_link?.url
              ? { link: cardData.cta_button_link.url }
              : { onClick: handleModelOpen })}
            text={cardData?.cta_button_text?.text}
            buttonClass="demo-cta text-base !font-semibold bg-purple-1500 no-underline -tracking-[0.35px] !text-white !font-normal font-manrope !px-3 hover:bg-[#5E24FF] !w-full !max-w-full"
          />
        )}
      </div>
      {cardData?.cta_button_text?.text && !isDesktop && (
        <CustomButton
          {...(cardData?.cta_button_link?.url
            ? { link: cardData.cta_button_link.url }
            : { onClick: handleModelOpen })}
          text={cardData?.cta_button_text?.text}
          buttonClass="demo-cta text-base !font-semibold bg-purple-1500 no-underline -tracking-[0.35px] !text-white !font-normal font-manrope !px-3 hover:bg-[#5E24FF] !w-full !max-w-full !mt-3"
        />
      )}
    </div>
  );
};

export default CompanyCard;
