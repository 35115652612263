import React from "react";

export const RegisteredIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM14.1877 18.1679C14.3483 18.2346 14.4941 18.3324 14.6168 18.4556L18.4624 22.3012L25.694 15.0733C25.81 14.957 25.9479 14.8648 26.0996 14.8019C26.2513 14.7389 26.4139 14.7066 26.5782 14.7066C26.7424 14.7066 26.9051 14.7389 27.0568 14.8019C27.2085 14.8648 27.3463 14.957 27.4624 15.0733L27.5653 15.1762C27.6816 15.2923 27.7738 15.4301 27.8367 15.5818C27.8997 15.7335 27.9321 15.8962 27.9321 16.0604C27.9321 16.2247 27.8997 16.3873 27.8367 16.539C27.7738 16.6907 27.6816 16.8286 27.5653 16.9446L19.3484 25.1615C19.2324 25.2777 19.0945 25.37 18.9428 25.4329C18.7911 25.4958 18.6284 25.5282 18.4642 25.5282C18.2999 25.5282 18.1373 25.4958 17.9856 25.4329C17.8339 25.37 17.696 25.2777 17.58 25.1615L12.7454 20.3269C12.6222 20.2043 12.5244 20.0585 12.4577 19.8979C12.391 19.7373 12.3566 19.5652 12.3566 19.3913C12.3566 19.2174 12.391 19.0452 12.4577 18.8847C12.5244 18.7241 12.6222 18.5783 12.7454 18.4556C12.8681 18.3324 13.0139 18.2346 13.1745 18.1679C13.335 18.1012 13.5072 18.0668 13.6811 18.0668C13.855 18.0668 14.0271 18.1012 14.1877 18.1679Z"
      fill="#73D13D"
    />
  </svg>
);
