import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  FIRST_NAME_VALIDATION,
  isDomainBlocked,
  LAST_NAME_VALIDATION,
  WORK_EMAIL_VALIDATION,
} from "../../../utilities/helpers";
import {
  emailRegex,
  FDA_GUIDE_PDF_LINK,
  HUBSPOT_PROXY_URL,
  ROBOTICS_PDF_LINK,
} from "../../../constants";
import CustomModal from "../CustomModal";

// const SingleEmailFormFormSchema = Yup.object().shape({
//   email: WORK_EMAIL_VALIDATION,
// });

const CtaBannerMunchForm = ({
  setEmail,
  border,
  buttonText,
  formHsClass = "commercial-form",
  portalId = "25381551",
  formId = "c90a5ae1-1852-43f5-9346-a57837b9f470",
  pagePath,
}) => {
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setisSubmitted] = useState(false);
  const pdfLink = FDA_GUIDE_PDF_LINK;
  const [message, setMessage] = useState("");

  const initialValues =
    formId === "636a9fb4-a883-41f8-aee0-e397ce335c8a" || pagePath
      ? { email: "", firstName: "", lastName: "" }
      : { email: "" };

  const SingleEmailFormFormSchema =
    formId === "636a9fb4-a883-41f8-aee0-e397ce335c8a" || pagePath
      ? Yup.object().shape({
          email: WORK_EMAIL_VALIDATION,
          firstName: FIRST_NAME_VALIDATION,
          lastName: LAST_NAME_VALIDATION,
        })
      : Yup.object().shape({
          email: WORK_EMAIL_VALIDATION,
        });

  const handleSubmit = async (values, { resetForm }) => {
    const { email = "", firstName = "", lastName = "" } = values;
    try {
      const [cookie] = document.cookie
        .split("; ")
        .filter((x) => x.includes("hubspotutk="));
      const [, hubspotutk] = cookie?.split("=") || ["", ""];

      // Extract UTM parameters
      const urlParams = new URLSearchParams(window.location.search);
      const utmParams = {
        utm_medium: urlParams.get("utm_medium") || "",
        utm_source: urlParams.get("utm_source") || "",
        utm_campaign: urlParams.get("utm_campaign") || "",
      };

      if (emailRegex.test(email) && !isDomainBlocked(email)) {
        setLoading(true);
        const requestPayload = {
          fields: [
            {
              objectTypeId: "0-1",
              name: "email",
              value: email,
            },
          ],
          context: {
            pageUri: location?.href,
            pageName: location?.pathname,
          },
        };

        // Add UTM parameters for specific form ID
        if (formId === "f0cf2f52-d2e6-46fb-aeef-4d0ecc8ca70d") {
          requestPayload.fields.push(
            ...Object.entries(utmParams).map(([key, value]) => ({
              objectTypeId: "0-1",
              name: key,
              value: value,
            }))
          );
        }

        if (formId === "636a9fb4-a883-41f8-aee0-e397ce335c8a" || pagePath) {
          requestPayload.fields.push(
            {
              objectTypeId: "0-1",
              name: "firstname",
              value: firstName,
            },
            {
              objectTypeId: "0-1",
              name: "lastname",
              value: lastName,
            }
          );
        }

        if (hubspotutk) {
          requestPayload.context.hutk = hubspotutk;
        }

        const response = await axios.post(
          HUBSPOT_PROXY_URL,
          Object.assign(requestPayload, {
            portalId,
            formId: formId || "f0cf2f52-d2e6-46fb-aeef-4d0ecc8ca70d",
          })
        );
        // Add GTM DataLayer Push with UTM parameters
        if (
          formId === "f0cf2f52-d2e6-46fb-aeef-4d0ecc8ca70d" &&
          window.dataLayer
        ) {
          window.dataLayer.push({
            event: "form_submission",
            form_type: "lead_capture",
            form_id: formId,
            form_details: {
              email: email,
              firstName: firstName,
              lastName: lastName,
              ...utmParams,
            },
          });
        }

        setMessage(
          response.data?.inlineMessage ||
            `<strong>You're In!</strong>! 🚀 <br/><br/>Your e-book download is ready!`
        );

        /**
         * Append custom properties to Heap
         */
        if (window.heap) {
          window.heap.addUserProperties({
            marketing_email: email,
          });
        }

        setEmail && setEmail(email);
        // if (formId === "f0cf2f52-d2e6-46fb-aeef-4d0ecc8ca70d") {
        //   downloadFile();
        // }
        setisSubmitted(true);
        resetForm();
      }
    } catch (error) {
      console.log("Error while submitting form:", error);
    } finally {
      setLoading(false);
    }
  };
  //Download the pdf
  const downloadFile = () => {
    axios({
      url: ROBOTICS_PDF_LINK,
      responseType: "blob",
      method: "GET",
    })
      .then((response) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `The Rise of Intelligent Machines.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.error("Error while file download:", err);
      });
  };

  const closeSuccessModal = () => {
    setisSubmitted(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={SingleEmailFormFormSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          values,
          setTouched,
          validateForm,
          setFieldValue,
          form,
          isValid,
          dirty,
          ...rest
        }) => {
          return (
            <section
              className={`flex ${pagePath && "lg:pb-10"} flex-col w-full`}
            >
              <Form
                className={`w-full relative gap-3 ${formHsClass || "commercial-form"}  sm:flex-row`}
              >
                {formId === "636a9fb4-a883-41f8-aee0-e397ce335c8a" ||
                  (pagePath && (
                    <div className="flex flex-row my-3 gap-x-3 sm:flex-row gap-y-2 sm:gap-y-0">
                      <div className="flex flex-col w-full">
                        <Field
                          name="firstName"
                          placeholder="Enter your first name"
                          type="text"
                          className={`w-full text-gray-2500 border-gray-1400 placeholder-gray-600 focus:rounded-4xl rounded-4xl focus:!shadow-none focus:!outline-none focus:ring-0  placeholder:text-base placeholder:tracking-[-0.8px] sm:placeholder:text-start ${pagePath ? "placeholder:text-start" : "placeholder:text-center "}  leading-6 disabled:bg-gray-300 bg-white sm:pl-[18px] py-3 sm:py-3.5 pr-1.5 ring-0`}
                        />
                        {errors.firstName && touched.firstName ? (
                          <small className="pt-2 pl-5 text-red-500 ">
                            {errors.firstName}
                          </small>
                        ) : null}
                      </div>

                      <div className="flex flex-col w-full">
                        <Field
                          name="lastName"
                          placeholder="Enter your last name"
                          type="text"
                          className={`w-full  text-gray-2500 border-gray-1400 placeholder-gray-600 focus:rounded-4xl rounded-4xl focus:!shadow-none focus:!outline-none focus:ring-0  placeholder:text-base placeholder:tracking-[-0.8px] sm:placeholder:text-start ${pagePath ? "placeholder:text-start" : "placeholder:text-center "}  leading-6 disabled:bg-gray-300 bg-white sm:pl-[18px] py-3 sm:py-3.5 pr-1.5 ring-0`}
                        />
                        {errors.lastName && touched.lastName ? (
                          <small className="pt-2 pl-5 text-red-500 ">
                            {errors.lastName}
                          </small>
                        ) : null}
                      </div>
                    </div>
                  ))}
                <div className="relative">
                  <div
                    className={`cta_border_animation ${border ? "border-none" : "border"} !rounded-4xl  p-[1px]`}
                  >
                    <Field
                      name="email"
                      placeholder="Enter your email address"
                      type="email"
                      className={`w-full sm:min-w-[460px] border-none text-gray-2500 placeholder-gray-600 focus:rounded-4xl rounded-4xl focus:!shadow-none focus:!outline-none focus:ring-0 focus:!border-none placeholder:text-base placeholder:tracking-[-0.8px] sm:placeholder:text-start ${pagePath ? "placeholder:text-start" : "placeholder:text-center "}  leading-6 disabled:bg-gray-300 bg-white sm:pl-[18px] py-3 sm:py-3.5 ring-0`}
                    />
                  </div>

                  {errors.email && touched.email ? (
                    <small
                      className={`flex ${pagePath ? "text-start" : "text-center"} pt-2 pl-6 text-red-500 sm:hidden`}
                    >
                      {errors.email}
                    </small>
                  ) : null}

                  <div className="absolute right-1.5 top-1/2 -translate-y-1/2">
                    <div
                      className="hs-field-desc"
                      style={{ display: "none" }}
                    ></div>
                    <div className="actions">
                      <button
                        aria-label="submit"
                        type="submit"
                        disabled={loading || !(isValid && dirty)}
                        className="disabled:cursor-not-allowed hidden sm:flex gap-1 items-center px-4 py-2 w-full font-manrope tracking-[-0.4px] text-white bg-purple-1500 shrink-0 rounded-4xl font-semibold"
                      >
                        <span className="hidden sm:inline-block">
                          {buttonText}
                        </span>
                        {loading && (
                          <svg
                            className="w-5 h-5 text-white animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            role="presentation"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>
                  <button
                    aria-label="submit"
                    type="submit"
                    disabled={loading || !(isValid && dirty)}
                    className={`disabled:cursor-not-allowed mt-3 flex sm:hidden gap-1 items-center px-4 py-3 sm:py-2 text-center ${pagePath ? "max-w-full sm:max-w-fit w-full" : "max-w-fit"}  mx-auto font-manrope tracking-[-0.4px] text-white bg-purple-1500 shrink-0 rounded-4xl font-semibold`}
                  >
                    <span className="inline-block mx-auto text-center ">
                      {buttonText}
                    </span>
                    {loading && (
                      <svg
                        className="w-5 h-5 text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        role="presentation"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                  </button>
                </div>
                {errors.email && touched.email ? (
                  <small className="hidden pt-1 pl-5 text-red-500 sm:flex">
                    {errors.email}
                  </small>
                ) : null}
                {pagePath && (
                  <p
                    className={`px-2 text-xs text-center text-gray-600 font-inter custom-link pt-4 lg:px-5`}
                  >
                    Encord needs the contact information you provide to us to
                    contact you about our products and services. For information
                    on how to unsubscribe, as well as our privacy practices and
                    commitment to protecting your privacy, please review our
                    <a
                      href="https://encord.com/privacy/"
                      className="ml-1 underline"
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>
                )}
              </Form>
            </section>
          );
        }}
      </Formik>

      <CustomModal
        message={message}
        isSuccess={isSubmitted}
        closeSuccess={closeSuccessModal}
      />
    </>
  );
};

export default CtaBannerMunchForm;
