import React, { useContext } from "react";
import desktopBG from "../../../assets/backgrounds/emailBannerBG.png";
import tabletBG from "../../../assets/backgrounds/tabletEmailBanner.png";
import CustomButton from "../../CustomButton";
import ModalContext from "../../../Contexts/openModal";
import SingleEmailForm2 from "../../Pieces/SingleEmailForm2";

const FooterRaphael = ({ slice }) => {
  const { toggleModal, setEmail } = useContext(ModalContext);
  const emptySlice = !!(
    slice?.primary?.cta_heading?.text || slice?.primary?.cta_description?.text
  );
  const dark = "annotate_footer";
  return (
    <>
      {emptySlice && (
        <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
          <article className="py-10 lg:mx-11">
            <section
              className={`relative w-full lg:px-32 z-10 lg:py-20 sm:px-9 px-4 py-16 sm:mx-auto text-center text-[#FAFAFA] rounded-2.5xl  ${dark} shadow-card  max-w-6xl cta_bg border`}
            >
              <img
                src={desktopBG}
                className="h-full w-full absolute inset-0 -z-10 rounded-2.5xl hidden md:block"
                alt="desktopBG"
                loading="lazy"
              />
              <img
                src={tabletBG}
                className="h-full w-full absolute inset-0 -z-10 rounded-2.5xl hidden sm:block md:hidden"
                alt="tabletBG"
                loading="lazy"
              />
              <img
                src={tabletBG}
                className="h-full w-full absolute inset-0 -z-10 rounded-2.5xl block sm:hidden"
                alt="tabletBG"
                loading="lazy"
              />

              <div className="flex flex-col justify-center w-full max-w-5xl ">
                {slice?.primary?.cta_heading?.text && (
                  <h6 className="text-2xl font-semibold mx-auto font-manrope sm:text-3.25xl px-5 sm:px-24 lg:px-0 lg:text-4.5xl max-w-[700px] leading-8 sm:leading-10 lg:tracking-tightest_4 lg:leading-12 ">
                    {slice?.primary?.cta_heading?.text}
                  </h6>
                )}
                {slice?.primary?.cta_description?.text && (
                  <p className="mb-5 sm:mb-7 mt-3.5 sm:mt-6 text-sm sm:text-lg text-gray-2200 md:px-5 leading-5 sm:leading-7 max-w-[720px] mx-auto sm:px-10 lg:px-0">
                    {slice?.primary?.cta_description?.text}
                  </p>
                )}
                {slice?.primary?.cta_text?.text && (
                  <section className="flex justify-center">
                    <SingleEmailForm2
                      border={dark}
                      openModal={toggleModal}
                      setEmail={setEmail}
                      formHsClass={slice?.primary?.hs_class?.text}
                      formId={slice?.primary?.hs_id?.text}
                      buttonText={slice?.primary?.cta_text?.text}
                    />
                  </section>
                )}
              </div>
            </section>
          </article>
        </section>
      )}
    </>
  );
};

export default FooterRaphael;
