import React from "react";

const Farrow = ({styleClass}) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${styleClass}`}
    role="presentation"
  >
    <path
      d="M11.8809 6.66914L6.71562 2.18613C6.67598 2.15195 6.62539 2.13281 6.57207 2.13281H5.36211C5.26094 2.13281 5.21445 2.25859 5.29102 2.32422L10.0789 6.48047H2.07812C2.01797 6.48047 1.96875 6.52969 1.96875 6.58984V7.41016C1.96875 7.47031 2.01797 7.51953 2.07812 7.51953H10.0775L5.28965 11.6758C5.21309 11.7428 5.25957 11.8672 5.36074 11.8672H6.61172C6.6377 11.8672 6.66367 11.8576 6.68281 11.8398L11.8809 7.33086C11.9282 7.2897 11.9661 7.23887 11.9921 7.18179C12.0181 7.12471 12.0316 7.06272 12.0316 7C12.0316 6.93728 12.0181 6.87529 11.9921 6.81821C11.9661 6.76113 11.9282 6.7103 11.8809 6.66914Z"
      fill="black"
      fill-opacity="0.45"
    />
  </svg>
);

export default Farrow;
