import React from "react";

const ToptoBottomrightArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="183"
      viewBox="0 0 24 183"
      fill="none"
      role="presentation"
      className="absolute top-[60px] left-[-12px] sm:hidden "
    >
      <path d="M23.248 180L18.248 182.887V177.113L23.248 180Z" fill="#5E44FF" />

      <path
        class="moving_dots"
        d="M2 0H15"
        stroke="url(#paint0_linear_ToptoBottomrightArrow)"
        stroke-width="2.4"
        stroke-dasharray="2.4 2.4"
      />

      <path
        class="moving_dots_left_to_right"
        d="M2 180.5H20"
        stroke="url(#paint0_linear_ToptoBottomrightArrow)"
        stroke-width="1.2"
        stroke-dasharray="2.4 2.4"
      />

      <path
        class="moving_dots_left_to_right"
        d="M2 3V180"
        stroke-width="1.2"
        stroke-dasharray="2.4 2.4"
        stroke="url(#paint0_linear_ToptoBottomrightArrow)"
      />
       <defs>
        <linearGradient
          id="paint0_linear_ToptoBottomrightArrow"
          x1="2.5"
          y1="0"
          x2="2.5"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#BFBFBF" />
          <stop offset="1" stop-color="#5E44FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ToptoBottomrightArrow;
