import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import CarouselMonetCard from "../../Pieces/CarouselMonetCard";
import { Virtual, Keyboard, Mousewheel } from "swiper/modules";
import { useMediaQuery } from "react-responsive";

const NextArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
  >
    <circle
      cx="19"
      cy="19"
      r="19"
      transform="matrix(-1 0 0 1 40 2)"
      fill="#F0F0F0"
    />
    <path
      d="M19.6977 14.9689C19.3433 14.6145 18.792 14.6145 18.4377 14.9689C18.0833 15.3233 18.0833 15.8745 18.4377 16.2289L23.0445 20.9145L18.4377 25.6002C18.0833 25.9545 18.0833 26.5058 18.4377 26.8602C18.5952 27.0177 18.8314 27.0964 19.0677 27.0964C19.3039 27.0964 19.5402 27.0177 19.6977 26.8208L24.9345 21.5052C25.2889 21.1508 25.2889 20.5995 24.9345 20.2452L19.6977 14.9689Z"
      fill="#8C8C8C"
    />
  </svg>
);

const PrevArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
  >
    <circle cx="21" cy="21" r="19" fill="#F0F0F0" />
    <path
      d="M22.3023 14.9689C22.6567 14.6145 23.208 14.6145 23.5623 14.9689C23.9167 15.3233 23.9167 15.8745 23.5623 16.2289L18.9555 20.9145L23.5623 25.6002C23.9167 25.9545 23.9167 26.5058 23.5623 26.8602C23.4048 27.0177 23.1686 27.0964 22.9323 27.0964C22.6961 27.0964 22.4598 27.0177 22.3023 26.8208L17.0655 21.5052C16.7111 21.1508 16.7111 20.5995 17.0655 20.2452L22.3023 14.9689Z"
      fill="#8C8C8C"
    />
  </svg>
);

const CarouselMonetSlider = ({ carouselData }) => {
  const sliderRef = useRef();
  const [activeTab, setActiveTab] = useState(0);
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef(null);
  const tabs = carouselData.map((card) => card.card_title.text);
  const isMobile = useMediaQuery({ maxWidth: 639 });

  const startProgress = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(intervalRef.current);
          if (isMobile) {
            handleProgressBarEnd();
          } else {
            goToNextTab();
          }
          return 0;
        }
        return prev + 0.6;
      });
    }, 50);
  };

  useEffect(() => {
    startProgress();
    return () => clearInterval(intervalRef.current);
  }, [activeTab]);

  const goToNextTab = () => {
    if (isMobile) return; 

    const nextTab = (activeTab + 1) % carouselData.length;
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slideNext();
      setActiveTab(nextTab);
      setProgress(0);
    }
  };

  const goToPreviousTab = () => {
    if (isMobile) return; 

    const previousTab =
      activeTab === 0 ? carouselData.length - 1 : activeTab - 1;
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slidePrev();
      setActiveTab(previousTab);
      setProgress(0);
    }
  };

  const handleProgressBarEnd = () => {
    const nextIndex = activeTab + 1 < tabs?.length ? activeTab + 1 : 0;
    setActiveTab(nextIndex);
    setProgress(0);
  };
  const handleTabClick = (index) => {
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slideToLoop(index);
      setActiveTab(index);
      setProgress(0);
      startProgress();
    }
  };

  const handleMobileTabClick = (index) => {
    setActiveTab(index);
    setProgress(0);
    startProgress();
  };

  return (
    <div className="sm:py-5">
      {!isMobile && (
        <div className="grid grid-cols-1 gap-4 mb-10 lg:gap-5 xl:items-center sm:grid-cols-3 lg:flex">
          {tabs.map((title, index) => (
            <button key={index} onClick={() => handleTabClick(index)}>
              <p
                className={`font-inter text-lg sm:text-base lg:text-lg font-medium -tracking-widest_3 text-left ${activeTab === index ? "text-purple-1100" : "text-gray-2700"}`}
              >
                {title}
              </p>
              <div
                className={`w-full sm:max-w-[332px] h-1 mt-3 bg-gray-1400 ${activeTab === index ? "opacity-100" : "opacity-0"}`}
              >
                <div
                  className="h-1 bg-purple-1500"
                  style={{ width: `${activeTab === index ? progress : 0}%` }}
                />
              </div>
            </button>
          ))}
        </div>
      )}
      {isMobile && (
        <div className="grid grid-cols-1 gap-4 sm:mb-10">
          {tabs.map((title, index) => (
            <>
              <button key={index} onClick={() => handleMobileTabClick(index)}>
                <p
                  className={`font-inter text-lg sm:text-base lg:text-lg font-medium -tracking-widest_3 text-left ${activeTab === index ? "text-purple-1100" : "text-gray-2700 opacity-50"}`}
                >
                  {title}
                </p>
                <div
                  className={`w-full sm:max-w-[332px] h-1 mt-3 bg-gray-1400 ${activeTab === index ? "opacity-100" : "opacity-0"}`}
                >
                  <div
                    className="h-1 bg-purple-1500"
                    style={{ width: `${activeTab === index ? progress : 0}%` }}
                  />
                </div>
              </button>
              {activeTab === index && (
                <div
                  className={`my-5 transition-all ease-in-out duration-700 ${
                    activeTab === index ? "fade_up" : "fade_up_hidden"
                  }`}
                >
                  {isMobile && (
                    <CarouselMonetCard cardData={carouselData[activeTab]} />
                  )}
                </div>
              )}
            </>
          ))}
        </div>
      )}
      {/* Swiper for desktop view */}
      {!isMobile && (
        <Swiper
          mousewheel={true}
          keyboard={{ enabled: true }}
          modules={[Virtual, Keyboard, Mousewheel]}
          ref={sliderRef}
          slidesPerView="auto"
          spaceBetween={48}
          loop={true}
          onSlideChange={(swiper) => {
            setActiveTab(swiper.realIndex);
            setProgress(0);
            startProgress();
          }}
          className="slider carousel_monet_card_slide_opacity"
        >
          {carouselData.map((cardData, key) => (
            <SwiperSlide
              virtualIndex={key}
              key={key}
              style={{
                maxWidth: "537px",
                marginRight: "15px",
                width: "100%",
              }}
            >
              <CarouselMonetCard cardData={cardData} />
            </SwiperSlide>
          ))}
        </Swiper>

   /* <section className="flex items-center justify-center sm:justify-start mt-7 sm:mt-5 gap-9 sm:gap-11">
            <button onClick={goToPreviousTab}>
              <PrevArrow />
            </button>
            <button onClick={goToNextTab}>
              <NextArrow />
            </button>
          </section> */     
          )}
    </div>
  );
};

export default CarouselMonetSlider;
