import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { emailRegex, HUBSPOT_PROXY_URL } from "../../../constants";
import "../../../styles/components/Slices/heroKusama.scss";
import {
  isDomainBlocked,
  WORK_EMAIL_VALIDATION,
} from "../../../utilities/helpers";
const SingleEmailFormFormSchema = Yup.object().shape({
  email: WORK_EMAIL_VALIDATION,
});

export const SingleEmailForm = ({
  openModal,
  setEmail,
  formHsClass,
  buttonText = "Get started",
  portalId = "25381551",
  formId = "c90a5ae1-1852-43f5-9346-a57837b9f470",
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async ({ email = "" }, { resetForm }) => {
    try {
      const [cookie] = document.cookie
        .split("; ")
        .filter((x) => x.includes("hubspotutk="));
      const [, hubspotutk] = cookie?.split("=") || ["", ""];
      if (emailRegex.test(email) && !isDomainBlocked(email)) {
        setLoading(true);
        const requestPayload = {
          fields: [
            {
              objectTypeId: "0-1",
              name: "email",
              value: email,
            },
          ],
          context: {
            pageUri: location?.href,
            pageName: location.pathname,
          },
        };

        if (hubspotutk) {
          requestPayload.context.hutk = hubspotutk;
        }

        await axios.post(
          HUBSPOT_PROXY_URL,
          Object.assign(requestPayload, {
            portalId,
            formId,
          })
        );
        /**
         * Append custom properties to Heap
         */
        if (window.heap) {
          window.heap.addUserProperties({
            marketing_email: email,
          });
        }
        setEmail && setEmail(email);
        openModal && openModal();
        resetForm();
      }
    } catch (error) {
      console.log("error while submitting form");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={SingleEmailFormFormSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        touched,
        values,
        setTouched,
        validateForm,
        setFieldValue,
        form,
        isValid,
        dirty,
        ...rest
      }) => {
        return (
          <section className="flex flex-col w-full sm:max-w-[474px]">
            <Form
              className={`w-full relative flex flex-col flex-wrap gap-3 mb-4 ${formHsClass}  sm:flex-row`}
            >
              <div className="">
                <div class="cta_border_animation  border !rounded-4xl p-[1px]">
                  <Field
                    name="email"
                    placeholder="Enter your email address"
                    type="email"
                    className={`w-full  text-gray-2500 placeholder-gray-600 focus:rounded-4xl focus:!shadow-none focus:!outline-none focus:ring-0 focus:!border-none placeholder:text-base placeholder:tracking-[-0.8px] rounded-4xl leading-6 border border-transparent disabled:bg-gray-300 bg-white pl-[22px] pr-20 sm:pr-32 py-3.5 ring-0`}
                  />
                </div>
              </div>

              <div className="absolute right-3.5 sm:right-[92px] top-1/2 -translate-y-1/2">
                <div
                  className="hs-field-desc"
                  style={{ display: "none" }}
                ></div>
                <div className="actions">
                  <button
                    aria-label="submit"
                    type="submit"
                    disabled={loading || !(isValid && dirty)}
                    className="disabled:cursor-not-allowed flex gap-1 items-center px-4 py-2 w-full font-manrope tracking-[-0.4px] text-white bg-purple-1500 shrink-0 rounded-4xl font-semibold"
                  >
                    <span className="hidden sm:inline-block">{buttonText}</span>
                    <svg
                      className="block sm:hidden"
                      width="24"
                      height="24"
                      role="presentation"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.3672 11.4328L11.5125 3.74766C11.4445 3.68906 11.3578 3.65625 11.2664 3.65625H9.19219C9.01875 3.65625 8.93906 3.87187 9.07031 3.98438L17.2781 11.1094H3.5625C3.45937 11.1094 3.375 11.1937 3.375 11.2969V12.7031C3.375 12.8062 3.45937 12.8906 3.5625 12.8906H17.2758L9.06797 20.0156C8.93672 20.1305 9.01641 20.3438 9.18984 20.3438H11.3344C11.3789 20.3438 11.4234 20.3273 11.4562 20.2969L20.3672 12.5672C20.4483 12.4966 20.5134 12.4095 20.558 12.3116C20.6025 12.2138 20.6256 12.1075 20.6256 12C20.6256 11.8925 20.6025 11.7862 20.558 11.6884C20.5134 11.5905 20.4483 11.5034 20.3672 11.4328Z"
                        fill="white"
                      />
                    </svg>
                    {loading && (
                      <svg
                        className="w-5 h-5 text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        role="presentation"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </Form>
            {errors.email && touched.email ? (
              <small className="text-red-500">{errors.email}</small>
            ) : null}
          </section>
        );
      }}
    </Formik>
  );
};
