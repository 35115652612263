import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import {
  HUBSPOT_PROXY_URL,
  NEW_ROBOTICS_PDF_LINK,
  WHITE_PAPER_FINAL_PDF_LINK,
  emailRegex,
} from "../../../constants";
import "../../../styles/page/dicom_page.scss";
import {
  FIRST_NAME_VALIDATION,
  LAST_NAME_VALIDATION,
  WORK_EMAIL_VALIDATION,
  isDomainBlocked,
  parseURL,
} from "../../../utilities/helpers";

const Formcc96010fd9a642e1a736c9ce8ffc8a78Monet = ({
  data,
  slice,
  location,
}) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const hubspotID = slice?.primary?.hs_id?.text;
  // Custom validation function
  // const _data = data?.prismicWhitepaperLpPage?.data || {};
  // const uid = data?.prismicWhitepaperLpPage?.uid;
  // const uid = location?.pathname;

  const RequestTrialSchema = Yup.object().shape({
    firstName: FIRST_NAME_VALIDATION,
    lastName: LAST_NAME_VALIDATION,
    workEmail: WORK_EMAIL_VALIDATION,
  });

  const handleSubmit = async (values, { resetForm }) => {
    const [cookie] = document.cookie
      .split("; ")
      .filter((x) => x.includes("hubspotutk="));
    const [, hubspotutk] = cookie?.split("=") || ["", ""];
    setError("");
    try {
      if (
        emailRegex.test(values?.workEmail) &&
        !isDomainBlocked(values?.workEmail)
      ) {
        setLoading(true);

        const requestPayload = {
          fields: [
            {
              objectTypeId: "0-1",
              name: "firstname",
              value: values?.firstName,
            },
            {
              objectTypeId: "0-1",
              name: "lastname",
              value: values?.lastName,
            },
            {
              objectTypeId: "0-1",
              name: "email",
              value: values?.workEmail,
            },
          ].filter((x) => x.value),
          context: {
            pageUri: location?.href,
            pageName:
              parseURL(location.href) || "ai-data-readiness-report-test",
          },
        };

        if (hubspotutk) {
          requestPayload.context.hutk = hubspotutk;
        }

        let formId;
        let pdfLink;

        switch (hubspotID) {
          case "f0cf2f52-d2e6-46fb-aeef-4d0ecc8ca70d":
            formId = "f0cf2f52-d2e6-46fb-aeef-4d0ecc8ca70d";
            pdfLink = NEW_ROBOTICS_PDF_LINK;
            break;
          default:
            formId = "cc96010f-d9a6-42e1-a736-c9ce8ffc8a78";
            pdfLink = WHITE_PAPER_FINAL_PDF_LINK;
            break;
        }

        const response = await axios.post(
          HUBSPOT_PROXY_URL,
          Object.assign(requestPayload, {
            portalId: "25381551",
            formId: formId || "cc96010f-d9a6-42e1-a736-c9ce8ffc8a78",
          })
        );

        /**
         * Append custom properties to Heap
         */
        if (window.heap) {
          window.heap.addUserProperties({
            marketing_email: values?.workEmail,
            use_case: values?.useCaseFreeTextForm,
          });
        }
        if (response?.data?.inlineMessage || response?.data?.redirectUri) {
          toast.success(
            <div
              dangerouslySetInnerHTML={{
                __html: response?.data?.inlineMessage,
              }}
            />
          );

          if (pdfLink) {
            if (response?.data?.redirectUri) {
              window.open(response?.data?.redirectUri, "_blank");
            } else {
              window.open(pdfLink, "_blank");
            }
          } else {
            console.error("PDF Link is undefined or empty.");
          }
        }
        resetForm();
      } else {
        setError("Email must be formatted correctly.");
      }
    } catch (error) {
      if (
        error?.response?.data &&
        error?.response?.data.errors &&
        error?.response?.data.errors[0]?.message
      ) {
        const [, errorMessage] =
          error?.response?.data?.errors[0]?.message.split(". ") || ["", ""];
        if (errorMessage) {
          setError(errorMessage ?? error?.response?.data?.errors[0]?.message);
        }
      } else {
        setError("Please enter your work e-mail.");
      }
    } finally {
      setLoading(false);
    }
  };

  const formClass = slice?.primary?.form_class?.text || "";
  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <article className=" lg:py-8 lg:mx-11">
        <div className="flex items-center justify-center min-h-full px-5 py-10 bg-white rounded-2.5xl sm:px-9 mobile_box_shadow ">
          <div className="flex flex-col justify-center lg:flex-row rounded-[20px] demo_container w-full box_alignment all items-center lg:items-stretch">
            <div>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  workEmail: "",
                }}
                validationSchema={RequestTrialSchema}
                onSubmit={handleSubmit}
              >
                {({
                  errors,
                  touched,
                  values,
                  setTouched,
                  validateForm,
                  setFieldValue,
                  form,
                  isValid,
                  dirty,
                  ...rest
                }) => {
                  return (
                    <Form
                      id="cc96010f-d9a6-42e1-a736-c9ce8ffc8a78"
                      className={`${formClass}`}
                    >
                      <div className="flex flex-col gap-y-2.5 w-full text-xs md:text-sm">
                        <div className="flex flex-col md:flex-row gap-2.5">
                          <div className="flex flex-col w-full">
                            <label className="text-gray-2500 placeholder:text-[#a8a4a4] font-medium mb-1.5">
                              First name
                              <sup className="text-red-500">*</sup>
                            </label>
                            <Field
                              name="firstName"
                              placeholder="Enter your first name"
                              type="text"
                              className="text-gray-2500 placeholder:text-gray-2200 py-2.5 pl-5 rounded-lg  border-solid border-gray-2200 border-[0.5px]"
                            />
                            {errors.firstName && touched.firstName ? (
                              <small className="text-red-500">
                                {errors.firstName}
                              </small>
                            ) : null}
                          </div>
                          <div className="flex flex-col w-full">
                            <label className="text-gray-2500 placeholder:text-gray-2200 font-medium mb-1.5">
                              Last name
                              <sup className="text-red-500">*</sup>
                            </label>
                            <Field
                              name="lastName"
                              placeholder="Enter your last name"
                              type="text"
                              className="text-gray-600 placeholder:text-gray-2200 rounded-lg py-2.5 pl-5 border-solid border-gray-2200 border-[0.5px]"
                            />
                            {errors.lastName && touched.lastName ? (
                              <small className="text-red-500">
                                {errors.lastName}
                              </small>
                            ) : null}
                          </div>
                        </div>

                        <div className="flex flex-col gap-3 lg:gap-5 lg:flex-row">
                          <div className="flex flex-col flex-1 w-full">
                            <label className="text-gray-600 placeholder:text-[#a8a4a4] font-medium mb-1.5">
                              Work email
                              <sup className="text-red-500">*</sup>
                            </label>
                            <Field
                              name="workEmail"
                              placeholder="Enter your work email"
                              type="text"
                              className="text-gray-600 placeholder:text-[#a8a4a4] rounded-lg py-2.5 pl-5 placeholder-gray-1600 border-solid border-gray-2200 border-[0.5px]"
                            />
                            {errors.workEmail && touched.workEmail ? (
                              <small className="text-red-500">
                                {errors.workEmail}
                              </small>
                            ) : null}
                          </div>
                        </div>
                        {error && (
                          <small className="text-red-500">{error}</small>
                        )}
                        <div className="flex">
                          <button
                            type={"submit"}
                            disabled={loading || !(isValid && dirty)}
                            className={`disabled:cursor-not-allowed  bg-purple-1500 hover:bg-[#5e24ff]  text-white py-2 mt-3.5 mb-2.5 text-base tracking-tighter_1 shadow-lg font-semibold rounded-full w-full font-manrope`}
                            {...rest}
                          >
                            {loading ? (
                              <div className="flex justify-center w-6 h-6 m-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent"></div>
                            ) : (
                              "Download report"
                            )}
                          </button>
                        </div>
                        <div
                          className="px-2 text-xs text-center text-gray-600 font-inter custom-link lg:px-5"
                          dangerouslySetInnerHTML={{
                            __html: slice?.primary?.form_bottom_text?.html,
                          }}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Formcc96010fd9a642e1a736c9ce8ffc8a78Monet;
