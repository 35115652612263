import React from "react";

export const TwitterIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    role="presentation"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2716 1.58655H18.0828L11.9411 8.60612L19.1663 18.1582H13.509L9.07804 12.3649L4.00796 18.1582H1.19503L7.7642 10.65L0.833008 1.58655H6.63393L10.6392 6.88182L15.2716 1.58655ZM14.2849 16.4755H15.8427L5.7875 3.18082H4.11589L14.2849 16.4755Z"
     className="group-hover:fill-[#262626] fill-[#595959]"
    />
  </svg>
);
