import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

const NewAccordion = ({ children, tag = "li", title, active }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const accordion = useRef(null);
  const Component = tag;

  useEffect(() => {
    setAccordionOpen(active);
  }, [accordion, active]);

  return (
    <Component>
      <button
        className={`flex items-center w-full text-xl font-medium text-left p-6 border-t border-gray-200 justify-between`}
        onClick={(e) => {
          e.preventDefault();
          setAccordionOpen(!accordionOpen);
        }}
        aria-expanded={accordionOpen}
      >
        <span className="text-xl font-medium leading-7 text-gray-2500 font-inter">
          {title}
        </span>
        {accordionOpen ? (
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            role="presentation"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="min-w-[28px] min-h-[28px]"
          >
            <path
              d="M5.96861 19.7974H3.85493C3.67173 19.7974 3.57056 19.5869 3.68267 19.4447L13.3104 7.11812C13.3918 7.01339 13.4961 6.92864 13.6153 6.87035C13.7345 6.81206 13.8654 6.78175 13.9981 6.78175C14.1308 6.78175 14.2617 6.81206 14.3809 6.87035C14.5001 6.92864 14.6043 7.01339 14.6858 7.11812L24.3135 19.4447C24.4256 19.5896 24.3245 19.7974 24.1413 19.7974H22.0276C21.8936 19.7974 21.7651 19.7345 21.6831 19.6306L13.9995 9.78687L6.31314 19.6306C6.23111 19.7345 6.10259 19.7974 5.96861 19.7974Z"
              fill="#434343"
            />
          </svg>
        ) : (
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            role="presentation"
            xmlns="http://www.w3.org/2000/svg"
            className="min-w-[28px] min-h-[28px]"
          >
            <path
              d="M5.96861 8.20254H3.85493C3.67173 8.20254 3.57056 8.41308 3.68267 8.55527L13.3104 20.8818C13.3918 20.9866 13.4961 21.0713 13.6153 21.1296C13.7345 21.1879 13.8654 21.2182 13.9981 21.2182C14.1308 21.2182 14.2617 21.1879 14.3809 21.1296C14.5001 21.0713 14.6043 20.9866 14.6858 20.8818L24.3135 8.55527C24.4256 8.41035 24.3245 8.20254 24.1413 8.20254H22.0276C21.8936 8.20254 21.7651 8.26543 21.6831 8.36933L13.9995 18.2131L6.31314 8.36933C6.23111 8.26543 6.10259 8.20254 5.96861 8.20254Z"
              fill="#8C8C8C"
            />
          </svg>
        )}
      </button>
      <div
        ref={accordion}
        className="overflow-hidden text-lg font-normal leading-7 transition-all duration-300 ease-in-out text-gray-2700 font-inter "
        style={
          accordionOpen
            ? { maxHeight: accordion.current.scrollHeight, opacity: 1 }
            : { maxHeight: 0, opacity: 0 }
        }
      >
        <div className=" pt-[10px] pb-[30px] px-9 strong_style">{children}</div>
      </div>
    </Component>
  );
};

export default NewAccordion;

NewAccordion.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string.isRequired]),
  tag: PropTypes.string,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
};
