import "../../styles/components/CTAs.scss";

export const rlhfCTA = () => {
  return `
    <section class="flex flex-col-reverse sm:flex-row gap-3 2xl:gap-4 my-9 rounded-2.5xl px-5 xl:px-8 sm:!pr-0 cta_bg_dark">
      <section class="flex flex-col flex-1 justify-center py-10 text-white">
        <div class="text-2xl lgxl_mid:text-xl xl:text-2xl max-w-sm md:max-w-[358px] leading-7 tracking-tightest font-semibold">
        Power the next generation of LLMs & VLMs with Reinforcement Learning from Human Feedback
        </div>
  
        <a
        href="https://encord.com/rlhf/?&utm_campaign=blog-cta"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="opens in a new tab"
        class="mb-2 max-w-fit flex mt-6 !no-underline demo-cta text-purple-600 px-[20px] py-[5px] sm:px-7 sm:py-3 rounded-full sm:text-[14.94px] text-2xs leading-[18.08px] font-medium bg-white"
      >
        Learn More
      </a>
      </section>
  
      <section class="flex-1 mt-6 sm:mt-0">
        <img
          src="https://images.prismic.io/encord/10c9e3fe-d62f-46f3-af3c-a980561abea4_rlhf-png.png?auto=compress,format"
          height="747"
          width="949"
          alt="medical banner"
          class="h-full"
        />
      </section>
    </section>`;
};
