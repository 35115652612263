import React from "react";

export const TwitterWhiteIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
  >
    <path
      d="M21.7494 5.95968C21.0322 6.26905 20.2518 6.49171 19.4478 6.57843C20.2825 6.08257 20.9075 5.29921 21.2057 4.3753C20.4225 4.84121 19.5645 5.16792 18.6697 5.34093C18.2958 4.94115 17.8435 4.62266 17.3411 4.40532C16.8386 4.18798 16.2968 4.07644 15.7494 4.07765C13.5346 4.07765 11.7533 5.87296 11.7533 8.07608C11.7533 8.38546 11.7908 8.69483 11.8518 8.99249C8.53535 8.81905 5.57754 7.23468 3.61113 4.8089C3.25283 5.42089 3.06507 6.11771 3.06738 6.82686C3.06738 8.21437 3.77285 9.4378 4.84863 10.1573C4.21466 10.1324 3.59553 9.95811 3.0416 9.64874V9.69796C3.0416 11.6409 4.41504 13.2511 6.24551 13.6214C5.90182 13.7107 5.54826 13.7563 5.19316 13.7573C4.93301 13.7573 4.68691 13.7316 4.43848 13.6964C4.94473 15.2808 6.41894 16.4316 8.17441 16.4691C6.80098 17.5448 5.08066 18.1776 3.2127 18.1776C2.87754 18.1776 2.56816 18.1659 2.24707 18.1284C4.01895 19.2651 6.12129 19.9214 8.38535 19.9214C15.7354 19.9214 19.7572 13.8323 19.7572 8.54718C19.7572 8.37374 19.7572 8.2003 19.7455 8.02687C20.5236 7.45733 21.2057 6.75187 21.7494 5.95968Z"
      fill="#F0F0F0"
    />
  </svg>
);
