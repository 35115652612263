import React from "react";
import EncordLogo from "../../assets/images/EncordBlueLogo.svg";
import NewHomeLeadFormWithAPIForm from "../NewHomeLeadFormWithAPI";
import {  XIcon } from "../../assets/backgrounds/blogPopUp";
import PopUpBg from "../../assets/blog_modal_bg.png"

const BlogPopUp = ({ closeFunction, blogPopUpData }) => {
  return (
    <section className="relative flex flex-col justify-between mx-auto my-6 bg-white md:flex-row rounded-[51px] shadow-card overflow-hidden w-full">
      <div className="absolute z-30 right-4 top-3">
        <button
          type="button"
          onClick={closeFunction}
          className="flex items-center justify-center w-16 h-16"
        >
          <XIcon />
        </button>
      </div>
      <div className="absolute bottom-0 left-0 right-0 z-0 w-full transform">
        <img src={PopUpBg} alt="PopUpBg"/>
      </div>
      <div>
      </div>
      <div className="flex-1 max-w-xl text-left py-18 pl-18">
        <div className="flex-shrink-0 mr-4">
          {/* Logo */}
          <EncordLogo />
        </div>
        <h4 className="mt-5 text-3xl font-semibold leading-9 text-black">
          Gain control of your training data, today.
        </h4>
        <div className="grid grid-cols-[auto_auto_auto] max-w-xl gap-x-7 gap-y-5 md:justify-start mt-10 w-[540px] ">
          {blogPopUpData?.map(({ imgUrl, labelText }, index) => {
            return (
              <div
              role="button" 
              tabIndex={0} 
                className="flex items-center bg-transparent cursor-pointer text-gray-2500"
                key={index}
              >
                <img
                  src={imgUrl}
                  width={0}
                  height={0}
                  alt="icon"
                  loading="lazy"
                  className="w-4 h-4 mr-2"
                />
                <p className="text-xs font-medium">{labelText}</p>
              </div>
            );
          })}
        </div>
        <section className="!mt-12 w-4/5">
          <NewHomeLeadFormWithAPIForm
            formId="b10be952-5240-49d8-885e-7c83b00358eb"
            pageName="Blog list page"
            location={location}
            btnText="Get started"
            btnColor="bg-purple-1500"
            isCustomBtnBg={true}
            size="md"
          />
        </section>
      </div>
      <div className="z-20 mt-5 lg:mt-0">
        <img
          src="https://images.prismic.io/encord/77e1e84e-1c83-40d5-904d-191e13f655c4_blog_popup_asset.png?auto=compress,format"
          width={0}
          height={0}
          alt="blog popup image"
          loading="eager"
          className="w-auto max-w-lg mb-4 h-128 mt-9"
        />
      </div>
    </section>
  );
};
export default BlogPopUp;
