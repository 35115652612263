import { Link } from "gatsby-link";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import "../../../styles/components/Slices/CarouselMonet.scss";
import SlicesVideoComponent from "../../Slices/SlicesVideoComponent";

const SectionRandCard = ({ cardData, key }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isHovered = hoveredIndex === key;
  const content_2_visible =
    cardData?.content_2_title?.text || cardData?.content_2_description?.text;

  const handleMouseEnter = (key) => {
    setHoveredIndex(key);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <>
      {cardData?.card_link ? (
        <Link
          to={cardData?.card_link}
          className="cursor-pointer"
          target="_blank"
           aria-label="opens in a new tab"
        >
          <div
            className="flex flex-col SectionRand_image_width lg:w-full gap-[18px] max-w-full lg:overflow-hidden group pb-5"
            onMouseEnter={() => handleMouseEnter(key)}
            onMouseLeave={handleMouseLeave}
          >
            {cardData?.card_video?.url ? (
              <div className="justify-center lg:justify-self-start flex w-full h-[154px]">
                <SlicesVideoComponent
                  src={cardData?.card_video?.url}
                  videoComponentClass="w-full object-cover"
                />
              </div>
            ) : (
              cardData?.card_image?.url && (
                <img
                  src={cardData?.card_image?.url}
                  alt={cardData?.card_image?.alt || "Carousel Monet Card Asset"}
                  className=" w-full h-[154px]"
                />
              )
            )}

            <div className="flex gap-[6px]">
              {isHovered && isDesktop ? (
                <img
                  className="size-[18px] mt-1"
                  src={cardData?.hover_icon?.url || cardData?.icon?.url}
                  alt={cardData?.hover_icon?.alt || "Hovered Icon"}
                />
              ) : !isDesktop ? (
                <img
                  className="size-[18px] mt-1"
                  src={cardData?.hover_icon?.url || cardData?.icon?.url}
                  alt={cardData?.hover_icon?.alt || "hovered Icon"}
                />
              ) : (
                <img
                  className="size-[18px] mt-1"
                  src={cardData?.icon?.url}
                  alt={cardData?.icon?.alt || "Icon"}
                />
              )}

              <p className="text-lg font-semibold text-purple-1100 lg:text-gray-2700 sm:text-xl lg:group-hover:text-purple-1100 font-manrope tracking-tighter_1">
                {cardData?.card_title?.text}
              </p>
            </div>
            <div className="px-[14px] sm:px-6">
              <h3 className="text-base font-semibold text-purple-1100 lg:text-gray-2700 lg:group-hover:text-purple-1100 font-manrope tracking-tight_1">
                {cardData?.content_1_title?.text}
                <span className="text-base font-normal text-gray-2500 font-inter tracking-tight_1">
                  {cardData?.content_1_description?.text}
                </span>
              </h3>
            </div>
            {content_2_visible && (
              <div className="px-[14px] sm:px-6">
                {cardData?.content_2_title?.text && (
                  <h3 className="text-base font-semibold text-purple-1100 lg:text-gray-2700 lg:group-hover:text-purple-1100 font-manrope tracking-tight_1">
                    {cardData?.content_2_title?.text}
                    {cardData?.content_2_description?.text && (
                      <span className="text-base font-normal text-gray-2500 font-inter tracking-tight_1">
                        {cardData?.content_2_description?.text}
                      </span>
                    )}
                  </h3>
                )}
              </div>
            )}
          </div>
        </Link>
      ) : (
        <div
          className="flex flex-col SectionRand_image_width lg:w-full gap-[18px] max-w-full lg:overflow-hidden group pb-5"
          onMouseEnter={() => handleMouseEnter(key)}
          onMouseLeave={handleMouseLeave}
        >
          {cardData?.card_video?.url ? (
            <div className="justify-center lg:justify-self-start flex w-full h-[154px]">
              <SlicesVideoComponent
                src={cardData?.card_video?.url}
                videoComponentClass="w-full object-cover"
              />
            </div>
          ) : (
            cardData?.card_image?.url && (
              <img
                src={cardData?.card_image?.url}
                alt={cardData?.card_image?.alt || "Carousel Monet Card Asset"}
                className=" w-full h-[154px]"
              />
            )
          )}

          <div className="flex gap-[6px] items-start">
            {isHovered && isDesktop ? (
              <img
                className="size-[18px] mt-1"
                src={cardData?.hover_icon?.url || cardData?.icon?.url}
                alt={cardData?.hover_icon?.alt || "Hovered Icon"}
              />
            ) : !isDesktop ? (
              <img
                className="size-[18px] mt-1"
                src={cardData?.hover_icon?.url || cardData?.icon?.url}
                alt={cardData?.hover_icon?.alt || "hovered Icon"}
              />
            ) : (
              <img
                className="size-[18px] mt-1"
                src={cardData?.icon?.url}
                alt={cardData?.icon?.alt || "Icon"}
              />
            )}

            <p className="text-lg font-semibold text-purple-1100 lg:text-gray-2700 sm:text-xl lg:group-hover:text-purple-1100 font-manrope tracking-tighter_1">
              {cardData?.card_title?.text}
            </p>
          </div>
          <div className="px-[14px] sm:px-6">
            <h3 className="text-base font-semibold text-purple-1100 lg:text-gray-2700 lg:group-hover:text-purple-1100 font-manrope tracking-tight_1">
              {cardData?.content_1_title?.text}
              <span className="text-base font-normal text-gray-2500 font-inter tracking-tight_1">
                {cardData?.content_1_description?.text}
              </span>
            </h3>
          </div>
          {content_2_visible && (
            <div className="px-[14px] sm:px-6">
              {cardData?.content_2_title?.text && (
                <h3 className="text-base font-semibold text-purple-1100 lg:text-gray-2700 lg:group-hover:text-purple-1100 font-manrope tracking-tight_1">
                  {cardData?.content_2_title?.text}
                  {cardData?.content_2_description?.text && (
                    <span className="text-base font-normal text-gray-2500 font-inter tracking-tight_1">
                      {cardData?.content_2_description?.text}
                    </span>
                  )}
                </h3>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SectionRandCard;
