import React from "react";

export const IdeaIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="30" rx="6" fill="#E7EEE1" fill-opacity="0.7" />
    <path
      d="M16.7981 22.6153H12.1518C12.0666 22.6153 11.9969 22.685 11.9969 22.7702V23.3897C11.9969 23.7323 12.2738 24.0092 12.6164 24.0092H16.3335C16.6761 24.0092 16.953 23.7323 16.953 23.3897V22.7702C16.953 22.685 16.8833 22.6153 16.7981 22.6153ZM14.475 6.66296C10.9689 6.66296 8.125 9.50689 8.125 13.0129C8.125 15.3632 9.40273 17.4153 11.3 18.513V20.7568C11.3 21.0994 11.5768 21.3763 11.9195 21.3763H17.0304C17.3731 21.3763 17.6499 21.0994 17.6499 20.7568V18.513C19.5472 17.4153 20.8249 15.3632 20.8249 13.0129C20.8249 9.50689 17.981 6.66296 14.475 6.66296ZM16.951 17.3069L16.256 17.7096V19.9824H12.6939V17.7096L11.9989 17.3069C10.4753 16.426 9.51889 14.8037 9.51889 13.0129C9.51889 10.2755 11.7375 8.05686 14.475 8.05686C17.2124 8.05686 19.431 10.2755 19.431 13.0129C19.431 14.8037 18.4746 16.426 16.951 17.3069Z"
      fill="#237804"
    />
    <path d="M20.2568 7.48902L21.9089 6.25" stroke="#237804" />
    <path d="M23.1249 9.19376L21.083 9.50135" stroke="#237804" />
    <path
      d="M17.7788 12.0317C17.7788 11.7063 17.7147 11.3841 17.5902 11.0834C17.4657 10.7828 17.2831 10.5096 17.053 10.2795C16.8229 10.0494 16.5497 9.86687 16.2491 9.74234C15.9484 9.61781 15.6262 9.55371 15.3008 9.55371"
      stroke="#237804"
      stroke-linecap="round"
    />
  </svg>
);

export const WebinarIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="30" rx="6" fill="#E6E4F7" />
    <path
      d="M23.0357 10.8587L20.4643 12.3393V9.28571C20.4643 8.57656 19.8877 8 19.1786 8H7.28571C6.57656 8 6 8.57656 6 9.28571V20.8571C6 21.5663 6.57656 22.1429 7.28571 22.1429H19.1786C19.8877 22.1429 20.4643 21.5663 20.4643 20.8571V17.8036L23.0357 19.2842C23.4636 19.5312 24 19.2219 24 18.7297V11.4152C24 10.921 23.4636 10.6116 23.0357 10.8587ZM19.0179 20.6964H7.44643V9.44643H19.0179V20.6964ZM22.5536 17.3415L20.4643 16.1402V14.0047L22.5536 12.8013V17.3415ZM8.89286 12.0179H11.1429C11.2312 12.0179 11.3036 11.9455 11.3036 11.8571V10.8929C11.3036 10.8045 11.2312 10.7321 11.1429 10.7321H8.89286C8.80446 10.7321 8.73214 10.8045 8.73214 10.8929V11.8571C8.73214 11.9455 8.80446 12.0179 8.89286 12.0179Z"
      fill="#434343"
    />
  </svg>
);

export const LearningIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="30" rx="6" fill="#E3EAF9" />
    <path
      d="M21.3636 6H8.63636C8.28437 6 8 6.28437 8 6.63636V23.1818C8 23.5338 8.28437 23.8182 8.63636 23.8182H21.3636C21.7156 23.8182 22 23.5338 22 23.1818V6.63636C22 6.28437 21.7156 6 21.3636 6ZM16.1932 7.43182H18.1023V11.606L17.1776 10.9318L16.1932 11.6358V7.43182ZM20.5682 22.3864H9.43182V7.43182H15V13.3361C15 13.4017 15.0199 13.4673 15.0597 13.521C15.0838 13.5552 15.1145 13.5842 15.1499 13.6064C15.1853 13.6286 15.2248 13.6435 15.266 13.6503C15.3073 13.6572 15.3495 13.6558 15.3902 13.6462C15.4309 13.6366 15.4693 13.6191 15.5031 13.5946L17.1696 12.4034L18.7884 13.5847C18.842 13.6244 18.9077 13.6463 18.9753 13.6463C19.1503 13.6463 19.2935 13.5031 19.2935 13.3281V7.43182H20.5662V22.3864H20.5682Z"
      fill="#434343"
    />
  </svg>
);

export const DocumentIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="30" rx="6" fill="#E8E8E8" />
    <path
      d="M21.3636 6H8.63636C8.28437 6 8 6.28437 8 6.63636V23.1818C8 23.5338 8.28437 23.8182 8.63636 23.8182H21.3636C21.7156 23.8182 22 23.5338 22 23.1818V6.63636C22 6.28437 21.7156 6 21.3636 6ZM20.5682 22.3864H9.43182V18.3892H11.3787C11.6094 19.0415 12.0151 19.6281 12.554 20.0736C13.2401 20.6403 14.1091 20.9545 15 20.9545C15.8909 20.9545 16.7599 20.6423 17.446 20.0736C17.9849 19.6281 18.3906 19.0415 18.6213 18.3892H20.5682V17.1364H17.617L17.5136 17.6276C17.275 18.8168 16.217 19.6818 15 19.6818C13.783 19.6818 12.725 18.8168 12.4844 17.6276L12.381 17.1364H9.43182V7.43182H20.5682V22.3864ZM11.1818 11.5085H18.8182C18.9057 11.5085 18.9773 11.4369 18.9773 11.3494V10.3949C18.9773 10.3074 18.9057 10.2358 18.8182 10.2358H11.1818C11.0943 10.2358 11.0227 10.3074 11.0227 10.3949V11.3494C11.0227 11.4369 11.0943 11.5085 11.1818 11.5085ZM11.1818 14.6903H18.8182C18.9057 14.6903 18.9773 14.6187 18.9773 14.5312V13.5767C18.9773 13.4892 18.9057 13.4176 18.8182 13.4176H11.1818C11.0943 13.4176 11.0227 13.4892 11.0227 13.5767V14.5312C11.0227 14.6187 11.0943 14.6903 11.1818 14.6903Z"
      fill="#434343"
    />
  </svg>
);

export const ChevronRightIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4596 8.55705L5.53535 2.36779C5.51465 2.35149 5.48976 2.34136 5.46356 2.33856C5.43735 2.33576 5.41089 2.34041 5.38721 2.35198C5.36353 2.36354 5.34359 2.38155 5.32969 2.40394C5.31578 2.42632 5.30847 2.45218 5.3086 2.47853V3.83732C5.3086 3.92345 5.34903 4.00607 5.41582 4.0588L11.7439 9.00002L5.41582 13.9412C5.34727 13.994 5.3086 14.0766 5.3086 14.1627V15.5215C5.3086 15.6393 5.44395 15.7043 5.53535 15.6322L13.4596 9.44298C13.5269 9.39045 13.5814 9.32324 13.6189 9.24649C13.6563 9.16973 13.6758 9.08543 13.6758 9.00002C13.6758 8.9146 13.6563 8.8303 13.6189 8.75354C13.5814 8.67679 13.5269 8.60958 13.4596 8.55705Z"
      fill="#434343"
    />
  </svg>
);

export const NextQuestionIcon = ({ iconStyle, iconColor }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${iconStyle}`}
    role="presentation"
  >
    <path
      d="M2.67251 9.66797L12.9575 9.66797L6.80161 15.0117C6.70318 15.0979 6.76294 15.2578 6.89302 15.2578L8.44868 15.2578C8.51724 15.2578 8.58228 15.2332 8.63325 15.1893L15.276 9.42539C15.3369 9.37265 15.3857 9.30745 15.4191 9.23421C15.4525 9.16097 15.4698 9.08139 15.4698 9.00088C15.4698 8.92036 15.4525 8.84079 15.4191 8.76755C15.3857 8.6943 15.3369 8.62911 15.276 8.57637L8.59458 2.77734C8.56821 2.75449 8.53657 2.74219 8.50318 2.74219L6.89478 2.74219C6.7647 2.74219 6.70493 2.90391 6.80337 2.98828L12.9575 8.33203L2.67251 8.33203C2.59517 8.33203 2.53189 8.39531 2.53189 8.47266L2.53189 9.52734C2.53189 9.60469 2.59517 9.66797 2.67251 9.66797Z"
      fill={iconColor ? `${iconColor}` : "#ffffff"}
    />
  </svg>
);
