import React from "react";
import "../../styles/components/CTAs.scss";
import CustomButton from "../CustomButton";
import { Chevron } from "../../assets/icons";

const OverviewVersionCTA = ({ slice }) => {
  const topSpacing = slice?.primary?.top_spacing;
  const bottomSpacing = slice?.primary?.bottom_spacing;
  return (
    <section
      className={`bg-gray-2800  ${topSpacing === "80" && "sm:pt-20 pt-8"} ${topSpacing === "40" && "sm:pt-10 pt-8"} ${topSpacing === "0" && "pt-0"} ${bottomSpacing === "80" && "sm:pb-20 pb-8"} ${bottomSpacing === "40" && "sm:pb-10 pb-8 "} ${bottomSpacing === "0" && "pb-0"}`}
    >
      <section className="flex flex-col-reverse lg:flex-row gap-5  bg-[#ffff] lg:pl-6 rounded-md overflow-hidden overview_version_cta_rotating_border">
        <div className="flex flex-col gap-3.5 overview_version_CTA_style pb-3.5 pt-3.5 sm:pt-6 pl-6 lg:pl-0">
          {slice?.primary?.cta_heading?.html && (
            <p
              dangerouslySetInnerHTML={{
                __html: slice?.primary?.cta_heading?.html,
              }}
            />
          )}
          {slice?.items && (
            <div>
              {slice?.items?.map((item, index) => {
                return (
                  <p key={index}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      role="presentation"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M9.93772 4.21055H7.35608L9.67756 1.27734C9.72561 1.21523 9.68225 1.125 9.60374 1.125H5.1096C5.07678 1.125 5.04514 1.14258 5.02874 1.17188L1.99241 6.41602C1.95608 6.47813 2.00061 6.55664 2.07327 6.55664H4.11702L3.06936 10.7473C3.0471 10.8387 3.15725 10.9031 3.22522 10.8375L10.0022 4.37109C10.0631 4.31367 10.0221 4.21055 9.93772 4.21055ZM4.43225 8.58398L5.13889 5.75977H3.29436L5.51624 1.92305H8.14827L5.70725 5.00859H8.17991L4.43225 8.58398Z"
                        fill="#595959"
                      />
                    </svg>
                    {item?.overview_bullet_point?.text}
                  </p>
                );
              })}
            </div>
          )}
          {slice?.primary?.cta_button_link?.url && (
            
            <CustomButton
              text={slice?.primary?.cta_button_text?.text}
              link={slice?.primary?.cta_button_link?.url}
              buttonClass={`bg-purple-1500 hover:bg-[#5E24FF] flex !text-white !text-sm !no-underline !px-3`}
              icon={<Chevron fill={"#fff"}/>}
              direction={`right`}
            />
          )}
        </div>
        {slice?.primary?.cta_image_asset?.url && (
          <img
            src={slice?.primary?.cta_image_asset?.url}
            alt={
              slice?.primary?.cta_image_asset?.alt ||
              "Overview Version CTA Asset"
            }
            className="lg:max-w-[338px] px-[1px]"
          />
        )}
      </section>
    </section>
  );
};

export default OverviewVersionCTA;
