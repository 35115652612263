import React from "react";
import NewAccordion from "../../../utilities/NewAccordion";
import "../../../styles/components/Slices/FAQ.scss";

const FAQ = ({ slice }) => {
  const darkTheme = slice?.primary?.theme === "dark";

  return (
    <div className={` ${darkTheme && "bg-purple-1100"}`}>
      <div
        className={`flex max-w-6xl py-10 sm:py-20 xl:px-0 lg:px-6 sm:px-10 px-4 justify-center mx-auto`}
      >
        <div
          className={`flex flex-col gap-8 lg:flex-row lg:gap-9 ${darkTheme && "faqDarkTheme "}`}
        >
          <div
            className={`min-w-[275px] ${darkTheme ? "text-white" : "text-purple-1100"} sm:leading-10 tracking-[-1.8px] text-2.5xl sm:text-3.25xl text-center lg:text-left font-bold sm:font-semibold`}
          >
            {slice?.primary?.title?.text}
          </div>

          <ul className="list_gap font-inter text-lg font-normal leading-7 tracking-tighter_3">
            {slice?.items?.map((item, index) => (
              <NewAccordion key={`anwser_${index}`} title={item?.heading?.text}>
                {item?.description?.html ? (
                  <div
                    className={`link-style`}
                    dangerouslySetInnerHTML={{
                      __html: item?.description?.html,
                    }}
                  />
                ) : (
                  <div
                    className="link-style"
                    dangerouslySetInnerHTML={{
                      __html: item?.description?.text,
                    }}
                  />
                )}
              </NewAccordion>
            ))}
            <span
              className="block border-t border-gray-200"
              aria-hidden="true"
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
