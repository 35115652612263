import React, { useState, useEffect } from "react";
import "../../../styles/components/Pieces/BlogDemoModal.scss";
import { BlogPopUpCloseModal } from "../../../assets/icons/ModalIcons";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  isDomainBlocked,
  WORK_EMAIL_VALIDATION,
  FIRST_NAME_VALIDATION,
  LAST_NAME_VALIDATION,
} from "../../../utilities/helpers";
import axios from "axios";
import {
  emailRegex,
  HUBSPOT_PROXY_URL,
  NEW_ROBOTICS_PDF_LINK,
} from "../../../constants";
import { RegisteredIcon } from "../../../assets/icons/RegisteredIcon";
import { useLocation } from "@reach/router";

const SingleEmailFormFormSchema = Yup.object().shape({
  firstName: FIRST_NAME_VALIDATION,
  lastName: LAST_NAME_VALIDATION,
  email: WORK_EMAIL_VALIDATION,
});

// const topics = [
//   "/blog/physical-ai/",
//   "/blog/intralogistics/",
//   "/blog/smart-robotics/",
//   "/blog/autonomous-mobile-robots/",
//   "/blog/ai-after-hours-physical-ai/",
//   "/blog/multi-object-tracking/",
//   "/blog/video-annotation-challenges/",
//   "/blog/conversational-ai/",
//   "/blog/supply-chain-automation/",
//   "/blog/gemini-robotics/",
//   "/blog/agricultural-drone/",
//   "/blog/embodied-ai/",
//   "/blog/ai-and-robotics/"
// ];

const BlogPopUpModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const location = useLocation();

  useEffect(() => {
    const email = localStorage.getItem("Blog-Pop-Up-Email-Form");
    const popUpClosed = localStorage.getItem("blog-pop-up-closed");

    if (email || popUpClosed) {
      setIsFormFilled(true);
    } else {
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, 12000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const [cookie] = document.cookie
        .split("; ")
        .filter((x) => x.includes("hubspotutk="));
      const [, hubspotutk] = cookie?.split("=") || ["", ""];
      if (emailRegex.test(values?.email) && !isDomainBlocked(values?.email)) {
        setLoading(true);
        const requestPayload = {
          fields: [
            {
              objectTypeId: "0-1",
              name: "firstname",
              value: values?.firstName,
            },
            {
              objectTypeId: "0-1",
              name: "lastname",
              value: values?.lastName,
            },
            {
              objectTypeId: "0-1",
              name: "email",
              value: values.email,
            },
          ],
          context: {
            pageUri: location?.href,
            pageName: location?.pathname,
          },
        };

        if (hubspotutk) {
          requestPayload.context.hutk = hubspotutk;
        }

        const response = await axios.post(HUBSPOT_PROXY_URL, {
          ...requestPayload,
          portalId: "25381551",
          formId: "052326a8-5351-4093-a423-ebed46ff3434",
        });
        setResponseMessage(
          response?.data?.inlineMessage ||
            "Thank you\nfor Downloading the e-book!"
        );

        console.log("responseMessage", response?.data);
        resetForm();
        localStorage.setItem("Blog-Pop-Up-Email-Form", values.email);
        toggleModal();
        setIsFormFilled(true);
        // downloadFile()
        setIsSubmitted(true);
      }
    } catch (error) {
      console.log("Error while submitting form:", error);
    } finally {
      setLoading(false);
    }
  };
  // const downloadFile = () => {
  //   axios({
  //     url: NEW_ROBOTICS_PDF_LINK,
  //     responseType: "blob",
  //     method: "GET",
  //   })
  //     .then((response) => {
  //       // Create blob link to download
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `The Rise of Intelligent Machines.pdf`);

  //       // Append to html link element page
  //       document.body.appendChild(link);

  //       // Start download
  //       link.click();

  //       // Clean up and remove the link
  //       link.parentNode.removeChild(link);
  //     })
  //     .catch((err) => {
  //       console.error("Error while file download:", err);
  //     });
  // };

  // useEffect(() => {
  //   let reopenTimer;
  //   if (!isFormFilled && !isOpen) {
  //     reopenTimer = setInterval(() => {
  //       setIsOpen(true);
  //     }, 10000);
  //   }

  //   return () => clearInterval(reopenTimer);
  // }, [isFormFilled, isOpen]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
    localStorage.setItem("blog-pop-up-closed", true);
  };

  const closeSuccessModal = () => {
    setIsSubmitted(false);
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center modal_bg">
          <div className="px-4 sm:px-20 lg:px-5">
            <section
              className={`bg-[#F2F1F0] lg:pt-0 modal_design relative overflow-hidden flex flex-col max-w-[635px]  lg:flex-row lg:max-w-6xl xl:min-w-[1152px] lg:max-h-[539px] rounded-lg shadow-lg`}
            >
              <div
                role="button"
                tabIndex={0}
                onClick={toggleModal}
                className="absolute cursor-pointer lg:right-9 top-4 right-3 lg:top-5"
              >
                <BlogPopUpCloseModal fillColor="fill-black lg:fill-black" />
              </div>
              <div className="xl:min-w-[743px] mt-auto xl:max-w-[743px]">
                <img
                  className="w-full max-h-[300px] sm:max-h-[460px] object-scale-down lg:max-h-full"
                  src="https://images.prismic.io/encord/Z-4kmndAxsiBwQK3_BlogPopUp.jpg?auto=format,compress"
                  alt="Blog Popup"
                />
              </div>
              <div className="bg-white lg:max-w-[409px] py-4 xs:pb-5 lg:min-w-[409px] xs:pt-5 lg:pt-[60px] px-4 sm:px-9">
                <div>
                  <div className="flex items-center gap-2 lg:pt-4">
                    <img
                      src="https://encord.cdn.prismic.io/encord/ZtrNDbzzk9ZrXF0B_VideoCamera.svg"
                      alt="Video Camera Icon"
                      className="w-7 h-7 sm:w-8 sm:h-8"
                    />
                    <p className="text-xl font-semibold sm:text-2xl font-manrope tracking-tightest_5 text-purple-1100">
                      Register for this webinar
                    </p>
                  </div>
                  <p className="text-sm text-gray-1200 tracking-[-0.35px] pt-2.5">
                    Fill out your details below and we’ll send through the
                    dial-in link. If you can't make it fill out the form and
                    we'll send you the webinar recording.
                  </p>
                  <div className="flex flex-col flex-1 w-full mt-4">
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      validationSchema={SingleEmailFormFormSchema}
                      onSubmit={handleSubmit}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        isValid,
                        dirty,
                        ...rest
                      }) => (
                        <Form className={`webinar-form sam2-video-modal`}>
                          <section className="flex flex-col gap-y-2 xs:gap-y-4">
                            <div className="flex gap-4 xs:gap-x-7.5 flex-row gap-y-2 sm:gap-y-0">
                              <div className="flex flex-col w-full">
                                <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-xs mb-1.5 font-medium">
                                  First name
                                  <sup className="text-red-500">*</sup>
                                </label>
                                <Field
                                  name="firstName"
                                  placeholder="Enter first name"
                                  type="text"
                                  className="text-gray-2700 w-full focus:border-[#0958D9] placeholder:text-gray-2200 text-sm rounded-lg placeholder-gray-1600 border-gray-2200 border py-2.5 px-2 xs:px-3"
                                />
                                {errors.firstName && touched.firstName ? (
                                  <small className="text-red-500">
                                    {errors.firstName}
                                  </small>
                                ) : null}
                              </div>

                              <div className="flex flex-col w-full">
                                <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-xs font-medium mb-1.5">
                                  Last name
                                  <sup className="text-red-500">*</sup>
                                </label>
                                <Field
                                  name="lastName"
                                  placeholder="Enter last name"
                                  type="text"
                                  className="text-gray-2700 focus:border-[#0958D9] placeholder:text-gray-2200 text-sm w-full rounded-lg placeholder-gray-1600 border-gray-2200 border py-2.5 px-2 xs:px-3"
                                />
                                {errors.lastName && touched.lastName ? (
                                  <small className="text-red-500">
                                    {errors.lastName}
                                  </small>
                                ) : null}
                              </div>
                            </div>
                            <div className="flex flex-col flex-1 w-full">
                              <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-xs font-medium mb-1.5">
                                Work email
                                <sup className="text-red-500">*</sup>
                              </label>
                              <Field
                                name="email"
                                placeholder="Enter work email"
                                type="text"
                                className="text-gray-2700 focus:border-[#0958D9] placeholder:text-gray-2200 text-sm rounded-lg placeholder-gray-1600 border-gray-2200 border py-2.5 px-2 xs:px-3"
                              />
                              {errors.email && touched.email && (
                                <small className="text-red-500">
                                  {errors.email}
                                </small>
                              )}
                            </div>
                            <div className="flex w-full gap-5">
                              <button
                                type="submit"
                                disabled={loading || !isValid || !dirty}
                                className="w-full py-2 mx-auto text-base font-semibold text-white rounded-full shadow-lg bg-purple-1500 hover:bg-purple-400 px-7 tracking-tighter_1 disabled:bg-purple-400 disabled:cursor-not-allowed"
                                {...rest}
                              >
                                {loading ? (
                                  <div role="status">
                                    <div className="flex gap-x-2.5 justify-center items-center">
                                      <span>Submitting</span>
                                      <span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="18"
                                          height="19"
                                          role="presentation"
                                          viewBox="0 0 18 19"
                                          fill="none"
                                          className="inline animate-spin"
                                        >
                                          <path
                                            d="M9 18.5C7.78535 18.5 6.60586 18.2627 5.49668 17.7934C4.42441 17.3398 3.46289 16.6895 2.63672 15.8633C1.81055 15.0371 1.16016 14.0756 0.706641 13.0033C0.237305 11.8941 0 10.7146 0 9.5C0 9.1502 0.283008 8.86719 0.632812 8.86719C0.982617 8.86719 1.26562 9.1502 1.26562 9.5C1.26562 10.5441 1.46953 11.5566 1.87383 12.5111C2.26406 13.4322 2.82129 14.2602 3.53145 14.9703C4.2416 15.6805 5.06953 16.2395 5.99062 16.6279C6.94336 17.0305 7.95586 17.2344 9 17.2344C10.0441 17.2344 11.0566 17.0305 12.0111 16.6262C12.9322 16.2359 13.7602 15.6787 14.4703 14.9686C15.1805 14.2584 15.7395 13.4305 16.1279 12.5094C16.5305 11.5566 16.7344 10.5441 16.7344 9.5C16.7344 8.45586 16.5305 7.44336 16.1262 6.48887C15.7373 5.56999 15.1744 4.73494 14.4686 4.02969C13.7641 3.3229 12.9288 2.75991 12.0094 2.37207C11.0566 1.96953 10.0441 1.76562 9 1.76562C8.6502 1.76562 8.36719 1.48262 8.36719 1.13281C8.36719 0.783008 8.6502 0.5 9 0.5C10.2146 0.5 11.3941 0.737305 12.5033 1.20664C13.5756 1.66016 14.5371 2.31055 15.3633 3.13672C16.1895 3.96289 16.8381 4.92617 17.2916 5.99668C17.7609 7.10586 17.9982 8.28535 17.9982 9.5C17.9982 10.7146 17.7609 11.8941 17.2916 13.0033C16.8398 14.0756 16.1895 15.0371 15.3633 15.8633C14.5371 16.6895 13.5738 17.3381 12.5033 17.7916C11.3941 18.2627 10.2146 18.5 9 18.5Z"
                                            fill="white"
                                          />
                                        </svg>
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  "Register"
                                )}
                              </button>
                            </div>
                          </section>
                        </Form>
                      )}
                    </Formik>
                    <p className="mt-2.5 xs:mt-5 text-xs text-center text-gray-1200">
                      By pressing “Register”, you agree to Encord’s{" "}
                      <a className="underline" href="/terms-and-conditions-uk">
                        terms and conditions
                      </a>{" "}
                      and{" "}
                      <a className="underline" href="/privacy/">
                        privacy policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}

      {isSubmitted && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className=" max-w-[300px] sm:max-w-[340px] py-6 px-[22px] mx-4 xs:mx-auto bg-white rounded-[14px] shadow-lg">
            <div className="flex justify-center">
              <RegisteredIcon />
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: responseMessage }}
              className="mt-[15px] text-center text-gray-600 font-inter text-base font-normal tracking-tight_1 response_message_strong_style"
            />
            <button
              onClick={closeSuccessModal}
              className="w-full px-4 py-2 mt-[15px] font-semibold text-white rounded-full bg-purple-1500 hover:bg-purple-400"
            >
              Continue Browsing
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogPopUpModal;
