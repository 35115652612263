import React from "react";

const SlackIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      role="presentation"
    >
      <path
        className="group-hover:fill-[#262626] fill-[#595959]"
        // fill="#262626"
        fillRule="evenodd"
        d="M7.333 0a2 2 0 1 0 0 4h2.001V2a2.001 2.001 0 0 0-2.001-2Zm0 5.333H2a2 2 0 0 0 0 4h5.333a2 2 0 1 0 0-4ZM20 7.333a2 2 0 0 0-4 0v2h2a2 2 0 0 0 2-2Zm-5.333 0V2a2 2 0 0 0-4 0v5.333a2 2 0 1 0 4 0ZM12.666 20a2 2 0 1 0 0-4h-2v2c0 1.103.895 1.998 2 2Zm0-5.334H18a2 2 0 0 0 0-4h-5.333a2 2 0 0 0 0 4ZM0 12.666a2 2 0 0 0 4 0v-2H2a2 2 0 0 0-2 2Zm5.334 0V18a2 2 0 0 0 4 0v-5.332a2 2 0 0 0-4-.002Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SlackIcon;
