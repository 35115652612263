import "../../styles/components/CTAs.scss";

export const tryEncordTodayBar = () => {
  return `
          <div class="pb-[15px] pr-[13px] flex-1 flex flex-col !text-white z-10 ">       
              <a class="!text-white max-w-fit mt-12 demo-cta bg-purple-500 px-[38px] py-[14px] rounded-full text-[14.94px] leading-[18.08px] font-bold" href="https://encord.com/try-it-free/" target="_blank" aria-label="opens in a new tab">
                Try Encord today
              </a>
          </div>
          `;
};
