import BG from "../../assets/images/tryEncordBg.png";
import "../../styles/components/CTAs.scss";


export const tryEncordVisual = () => {
    return `<section class="relative flex flex-col-reverse sm:flex-row my-9 rounded-2.5xl min-h-[210px]">
            F<image src=${BG} alt="background image" class="absolute inset-0 w-full h-full object-cover rounded-2.5xl"/>
            <div class="pt-7 sm:pt-[21px] pb-[15px] pl-6 pr-[13px] flex-1 flex flex-col !text-white z-10 ">
              <p class="!text-[22px] !m-0 !leading-[26.63px] !text-white font-normal">One platform for creating better training data and debugging models.</p>
          
              <a class="max-w-fit !text-white mt-12 demo-cta bg-purple-500 px-[38px] py-[14px] rounded-full text-[14.94px] leading-[18.08px] font-bold" href="https://encord.com/try-it-free/" target="_blank" aria-label="opens in a new tab">
                See Encord in Action
              </a>
              
            </div>
            <div class="flex-1 z-10 relative">
          <video
                loop
                autoPlay
                muted
                playsinline
                width="100%"
                height="100%"
                class="static -right-1 -bottom-1 -mt-3 sm:mt-0 sm:absolute inset-0 h-64 sm:h-full object-cover max-w-full mx-auto sm:max-w-none aspect-video rounded-tl-[20px] sm:rounded-tl-none rounded-t-[20px] sm:rounded-r-[20px]"
              >
                <source src="https://prismic-io.s3.amazonaws.com/encord/169372ff-f6b1-4b90-b967-5856c5f81f5b_Comp+4.mp4" />
              </video>
            </div>
          </section>`;
  };