import React, { useContext, useRef, useState, useEffect } from "react";
import HeadingSection from "../../Pieces/HeadingSection";
import ModalContext from "../../../Contexts/openModal";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Chevron2 } from "../../../assets/icons";
import posterVideo from "../../../assets/videoPoster.png";
import SlicesVideoComponent from "../SlicesVideoComponent";
import "../../../styles/components/Slices/SectionVermeer.scss";
import { AnimatePresence, motion } from "framer-motion";
import useWindowWidth from "../../../hooks/useBetterMediaQuery";

const SectionVermeer = ({ slice }) => {
  const containerRef = useRef(null);
  const lastItemRef = useRef(null);
  const [isLastVisible, setIsLastVisible] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const emptyHeading = !!(
    slice?.primary?.caption_title?.text ||
    slice?.primary?.show_cta ||
    slice?.primary?.description?.text ||
    slice?.primary?.title?.text
  );
  const isDesktop = useWindowWidth(1059);
  const topSpacing = slice?.primary?.top_spacing;
  const bottomSpacing = slice?.primary?.bottom_spacing;
  const { toggleModal } = useContext(ModalContext);

  useEffect(() => {
    if (lastItemRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => setIsLastVisible(entry.isIntersecting),
        { root: containerRef.current, threshold: 1.0 }
      );
      observer.observe(lastItemRef.current);

      return () => observer.disconnect();
    }
  }, []);

  const scrollToSection = (url) => {
    if (url && url?.charAt(0) === "#") {
      const sectionId = url.substring(1);
      const section = document.getElementById(sectionId);
      if (section) {
        const elementPosition = section.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY -200;
        window.scrollTo({ top: offsetPosition, behavior: "smooth" });
      }
    }
  };

  return (
    <section
      className={`bg-gray-2800 scroll-smooth  ${topSpacing === "80" && "sm:pt-20 pt-8"} ${topSpacing === "40" && "sm:pt-10 pt-8"} ${topSpacing === "0" && "pt-0"} ${bottomSpacing === "80" && "sm:pb-20 pb-8"} ${bottomSpacing === "40" && "sm:pb-10 pb-8 "} ${bottomSpacing === "0" && "pb-0"}`}
    >
      <section className="max-w-6xl px-4 mx-auto lg:px-6 xl:px-0 sm:px-12">
        {emptyHeading && (
          <HeadingSection
            data={slice?.primary}
            toggleModal={toggleModal}
            sectionStyle="lg:!pb-10"
          />
        )}
        <section
          ref={containerRef}
          className={`gap-3 sm:gap-6 ${isLastVisible ? "" : "vermeer_card_slide_opacity"} ${isDesktop ? "grid grid-cols-3" : "flex overflow-x-auto scroll-smooth no-scrollbar"}`}
        >
          {slice?.items?.map((value, index) => {
            const isLastItem = index === slice.items.length - 1;
            return value?.card_link?.url ? (
              <a
                href={value?.card_link?.url}
                onClick={(e) => {
                  const url = value?.card_link?.url;
                  if (url && url.includes("#")) {
                    e.preventDefault();
                    scrollToSection(url);
                  }
                }}
                className="cursor-pointer group p-1.5 relative  rounded-lg  flex flex-col gap-2.5"
                key={index}
                ref={isLastItem ? lastItemRef : null}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <AnimatePresence>
                  {hoveredIndex === index && (
                    <motion.span
                      className="absolute inset-0 h-full w-full bg-[#EFECFF] dark:bg-slate-800/[0.8] block rounded-lg z-0"
                      layoutId="hoverBackground"
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: { duration: 0.15 },
                        filter: "blur(2px)",
                      }}
                      exit={{
                        opacity: 0,
                        transition: { duration: 0.15, delay: 0.2 },
                      }}
                    />
                  )}
                </AnimatePresence>
                <div className="z-20 max-w-[356px] relative vermeer_border p-5 bg-white">
                  <div className="flex items-center justify-between ">
                    <div className="flex items-center gap-1">
                      <img
                        src={value?.card_logo?.url}
                        className="size-6 sm:size-[34px]"
                        alt={`vermeer-section-icon ${index + 1} `}
                        loading="lazy"
                      />
                      <p
                        className={`text-xl sm:text-2xl font-manrope text-gray-2700 group-hover:text-purple-1100 font-semibold tracking-tightest_6 ${value?.card_image?.url || value?.video_link?.url ? "" : "min-w-[200px] sm:min-w-[314px] lg:min-w-fit"}`}
                      >
                        {value?.card_title?.text}
                      </p>
                    </div>
                    <div className="transition-transform duration-700 group-hover:translate-x-2">
                      <Chevron2 />
                    </div>
                  </div>
                  {(value?.card_image?.url || value?.video_link?.url) && (
                    <div
                      role="button"
                      tabIndex={0}
                      className={`max-w-[240px] min-h-[300px] mt-3 max-h-[300px] overflow-hidden flex justify-center ${isDesktop ? "min-w-full" : "min-w-[314px]"} rounded-[10px]  bg-gray-1500 cursor-pointer`}
                    >
                      {value?.video_link?.url ? (
                        <SlicesVideoComponent
                          ref={videoRef}
                          src={value?.video_link?.url}
                          posterImage={posterVideo}
                          className="rounded-md"
                        />
                      ) : (
                        <GatsbyImage
                          sizes="(max-width:1024px)314px,314px"
                          alt={`vermeer-section-image${index + 1} `}
                          className="object-cover rounded-md w-[314px] h-[300px]"
                          image={getImage(value?.card_image)}
                        />
                      )}
                    </div>
                  )}
                </div>
              </a>
            ) : (
              <div
                className=" group p-1.5 relative rounded-lg  flex flex-col gap-2.5"
                key={index}
                ref={isLastItem ? lastItemRef : null}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <AnimatePresence>
                  {hoveredIndex === index && (
                    <motion.span
                      className="absolute inset-0 h-full w-full bg-[#EFECFF] dark:bg-slate-800/[0.8] block rounded-lg z-0"
                      layoutId="hoverBackground"
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: { duration: 0.15 },
                        filter: "blur(2px)",
                      }}
                      exit={{
                        opacity: 0,
                        transition: { duration: 0.15, delay: 0.2 },
                      }}
                    />
                  )}
                </AnimatePresence>
                <div className="relative z-20 p-5 bg-white vermeer_border">
                  <div className="flex items-center justify-between ">
                    <div className="flex items-center gap-1">
                      <img
                        src={value?.card_logo?.url}
                        className="size-6 sm:size-[34px]"
                        alt={`vermeer-section-icon ${index + 1} `}
                        loading="lazy"
                      />
                      <p
                        className={`text-xl sm:text-2xl font-manrope text-gray-2700 group-hover:text-purple-1100 font-semibold tracking-tightest_6 ${value?.card_image?.url || value?.video_link?.url ? "" : "min-w-[200px] sm:min-w-[314px] lg:min-w-fit"}`}
                      >
                        {value?.card_title?.text}
                      </p>
                    </div>
                    <div className="transition-transform duration-700 group-hover:translate-x-2">
                      <Chevron2 />
                    </div>
                  </div>
                  {(value?.card_image?.url || value?.video_link?.url) && (
                    <div
                      role="button"
                      tabIndex={0}
                      className={`max-w-[240px] min-h-[300px] max-h-[300px] mt-3 flex overflow-hidden justify-center ${isDesktop ? "min-w-full" : "min-w-[314px]"} rounded-[10px] bg-gray-1500 cursor-pointer`}
                    >
                      {value?.video_link?.url ? (
                        <SlicesVideoComponent
                          ref={videoRef}
                          src={value?.video_link?.url}
                          posterImage={posterVideo}
                          className="rounded-md"
                        />
                      ) : (
                        <GatsbyImage
                          sizes="(max-width:1024px)314px,314px"
                          alt={`vermeer-section-image${index + 1} `}
                          className="object-cover rounded-md w-[314px] h-[300px]"
                          image={getImage(value?.card_image)}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </section>
      </section>
    </section>
  );
};

export default SectionVermeer;
