import React from "react";

export const CompanyIcon = ({stroke}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    role="presentation"
  >
    <path
      d="M2.8125 15.75H15.1875M3.375 2.25H14.625M3.9375 2.25V15.75M14.0625 2.25V15.75M6.75 5.0625H7.875M6.75 7.3125H7.875M6.75 9.5625H7.875M10.125 5.0625H11.25M10.125 7.3125H11.25M10.125 9.5625H11.25M6.75 15.75V13.2188C6.75 12.753 7.128 12.375 7.59375 12.375H10.4062C10.872 12.375 11.25 12.753 11.25 13.2188V15.75"
      stroke={stroke}
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
