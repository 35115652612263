import React, { useContext, useEffect, useState, useRef } from "react";
import HeadingSection from "../../Pieces/HeadingSection";
import ModalContext from "../../../Contexts/openModal";
import "../../../styles/components/Slices/CarouselMonet.scss";
import CarouselMonetSlider from "../CarouselMonetSlider";


const CarouselMonet = ({ slice }) => {
  const topSpacing = slice?.primary?.top_spacing;
  const bottomSpacing = slice?.primary?.bottom_spacing;
  const { toggleModal } = useContext(ModalContext);
  const emptyHeading = !!(
    slice?.primary?.caption_title?.text ||
    slice?.primary?.show_cta ||
    slice?.primary?.description?.text ||
    slice?.primary?.title?.text
  );

  return (
    <section
      className={`py-8 ${topSpacing === "80" && "sm:pt-20"} ${
        topSpacing === "40" && "sm:pt-10"
      } ${bottomSpacing === "80" && "sm:pb-20"} ${
        bottomSpacing === "40" && "sm:pb-10"
      } relative px-4 mx-auto sm:px-12 lg:px-5 xl:px-0 max-w-6xl`}
    >
      {emptyHeading && (
        <HeadingSection
          headingStyle="text-2.5xl sm:text-3.25xl text-purple-1100"
          data={slice?.primary}
          toggleModal={toggleModal}
          // descriptionStyle="max-w-[890px]"
          textAreaStyle={`gap-4 !mt-0`}
          sectionStyle="lg:!pb-10"
        />
      )}
      <CarouselMonetSlider carouselData={slice?.items} />
    </section>
  );
};

export default CarouselMonet;
