import React from "react";
const InfoCard = ({ icon, title, text }) => {
  return (
    <div className="bg-gray-2800 flex-[0.5] rounded-[10px] border-2 border-solid border-gray-1600 p-6 flex flex-col gap-3 items-start">
      <div className="flex flex-row gap-1.5 items-center">
        {icon && <img src={icon} alt={"title"} />}
        {title && (
          <p className="text-xl font-semibold text-purpleclear-1100 tracking-[-0.8px]">
            {title}
          </p>
        )}
      </div>
      {text && (
        <p className="sm:text-base text-sm font-normal sm:font-medium text-gray-2500 sm:tracking-[-0.8px] tracking-[-0.7px]">
          {text}
        </p>
      )}
    </div>
  );
};
export default InfoCard;