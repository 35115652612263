import React, { useContext } from "react";
import ModalContext from "../../../Contexts/openModal";
import HeadingSection from "../../Pieces/HeadingSection";
import FeatureTurnerSlider from "./FeatureTurnerSlider";
import SlicesVideoComponent from "../SlicesVideoComponent";

const FeatureTurner = ({ slice }) => {
  const { toggleModal } = useContext(ModalContext);
  const topSpacing = slice?.primary?.top_spacing;
  const bottomSpacing = slice?.primary?.bottom_spacing;
  const emptyHeading = !!(
    slice?.primary?.caption_title?.text ||
    slice?.primary?.show_cta ||
    slice?.primary?.description?.text ||
    slice?.primary?.title?.text
  );
  return (
    <section
      className={`bg-gray-2800  ${topSpacing === "80" && "sm:pt-20 pt-8"} ${topSpacing === "40" && "sm:pt-10 pt-8"} ${topSpacing === "0" && "pt-0"} ${bottomSpacing === "80" && "sm:pb-20 pb-8"} ${bottomSpacing === "40" && "sm:pb-10 pb-8 "} ${bottomSpacing === "0" && "pb-0"}`}
    >
      <article className="max-w-6xl px-4 mx-auto lg:px-6 xl:px-0 sm:px-12">
        {emptyHeading && (
          <HeadingSection
            data={slice?.primary}
            toggleModal={toggleModal}
            sectionStyle="sm:max-w-[1152px] tracking-[-0.45px] md:max-w-[1200px] lg:!pb-10"
            textAreaStyle="gap-4"
            headingStyle="sm:text-3.25xl sm:leading-10 sm:!-tracking-[0.9px]"
          />
        )}
        {slice?.primary?.show_slider_tab ? (
          <FeatureTurnerSlider primary={slice} carouselData={slice?.items} />
        ) : slice?.primary?.video_asset?.url ? (
          <SlicesVideoComponent
            loop
            autoPlay
            muted
            videoComponentClass="rounded-md flex-shrink-0 aspect-square max-h-[288px] sm:max-h-[568px] h-full w-full"
            src={slice?.primary?.video_asset?.url}
          />
        ) : (
          <img
            key={slice?.primary?.image_asset?.url}
            src={slice?.primary?.image_asset?.url}
            alt={slice?.primary?.image_asset?.alt || "image_asset"}
            loading="lazy"
            className="rounded-md flex-shrink-0 object-cover lg:object-contain aspect-square max-h-[288px] sm:max-h-[568px] h-full w-full"
          />
        )}
      </article>
    </section>
  );
};

export default FeatureTurner;
