import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import ModalContext from "../../../Contexts/openModal";
import "../../../styles/components/Slices/HeroDali.scss";
import CustomButton from "../../CustomButton";
import SingleEmailForm2 from "../../Pieces/SingleEmailForm2";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import CtaBannerMunchForm from "../../Pieces/CtaBannerMunchForm";

export const DaliHeroSection = ({ slice,location }) => {
  const { toggleModal, setEmail } = useContext(ModalContext);
  const robotics = location?.pathname === "/the-rise-of-intelligent-machines/";
  
  const Rectangle =
    slice?.primary?.caption_title_dropdown == "rounded rectangle";
  return (
    <section className="space-y-5 lg:max-w-[553px] sm:space-y-7">
      <p
        className={`text-xs text-purple-1100 -tracking-tightest_5 font-semibold font-manrope  uppercase ${
          Rectangle ? "bg-purple-2001 rounded-md max-w-fit px-3 py-1.5 " : ""
        }`}
      >
        {slice?.primary?.caption_title?.text}
      </p>
      <div
        role="heading"
        aria-level="2"
        className="text-3.25xl leading-10 sm:text-[60px] sm:leading-[68px] text-purple-1100 dali_strong font-semibold font-manrope sm:-tracking-[3px] tracking-tightest_7"
        dangerouslySetInnerHTML={{ __html: slice?.primary?.title?.html }}
      />
      <div
        className="text-base text-gray-600 sm:text-lg tracking-tight_1 h-full sm:max-w-[550px] lg:max-w-full dali_description"
        dangerouslySetInnerHTML={{
          __html: slice?.primary?.descriptor_text?.html,
        }}
      />
      {slice?.primary?.show_email_cta && robotics ? (
        <CtaBannerMunchForm
          openModal={toggleModal}
          setEmail={setEmail}
          buttonText={slice?.primary?.cta_text?.text || "Get started"}
          formHsClass={slice?.primary?.hs_class?.text}
          formId={slice?.primary?.hs_id?.text}
          pagePath={robotics}
        />
      ) : slice?.primary?.show_email_cta ? (
        <SingleEmailForm2
          openModal={toggleModal}
          setEmail={setEmail}
          buttonText={slice?.primary?.cta_text?.text || "Get started"}
          formHsClass={slice?.primary?.hs_class?.text}
          formId={slice?.primary?.hs_id?.text}
        />
      ) : slice?.primary?.cta_text?.text ? (
        <CustomButton
          onClick={toggleModal}
          link={slice?.primary?.cta_link?.url}
          buttonClass="bg-purple-1500 text-white"
          text={slice?.primary?.cta_text?.text}
        />
      ) : null}
    </section>
  );
};

export const HeroDali = ({ slice,location }) => {
  return (
    <article className="pt-10">
      <div className="full-width-split-screen">
        <DaliHeroSection location={location} slice={slice} />

        <div className={` bg_image_dali`}>
          <GatsbyImage
            image={getImage(slice?.primary?.image)}
            alt="Hero image"
            loading="lazy"
            sizes="(max-width: 512px) 100vw, 512px,(max-width: 1440px) 720px, 1197px"
          />
        </div>
      </div>
    </article>
  );
};
