
import "../../styles/components/CTAs.scss";

export const productSamCta = () => {
    return `<section class="flex-1 max-w-full p-5 border-t border-b border-[#EDE2FF] space-y-7">
        <div class="text-xl font-semibold lg:text-2xl md:text-xl text-center">
          Build Better Models, Faster with Encord's Leading Annotation
          Tool
        </div>
        <video loop autoPlay muted class="w-auto h-auto">
          <source
            src="https://prismic-io.s3.amazonaws.com/encord/220fba4a-c72b-4e1e-974e-cff6019cf67d_SAM+HERO_1.mp4"
            type="video/mp4"
          />
        </video>
        <a
          href="https://encord.com/try-it-free/?&utm_campaign=cta-blog-product-sam"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="opens in a new tab"
          class="my-2 max-w-fit mx-auto flex justify-center demo-cta text-purple-500 px-[20px] border-2 border-purple-500 py-[5px] sm:px-[38px] sm:py-[14px] rounded-full sm:text-[14.94px] text-2xs leading-[18.08px] font-bold"
        >
            Try Encord today
        </a>
      </section>`;
  };