import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "../../hooks/usePagination";
import "../../styles/components/pagination.scss";
const Pagination = (props) => {
  const {
    onPageChange,
    totalPages,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalPages,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
    >
      <li
        className={classnames("arrow", {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <svg
          width="16"
          height="16"
          role="presentation"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="arrow left"
        >
          <path
            d="M10.8242 14.325C10.6742 14.325 10.5242 14.275 10.4242 14.15L4.77422 8.40002C4.54922 8.17502 4.54922 7.82503 4.77422 7.60002L10.4242 1.85002C10.6492 1.62502 10.9992 1.62502 11.2242 1.85002C11.4492 2.07502 11.4492 2.42502 11.2242 2.65002L5.97422 8.00003L11.2492 13.35C11.4742 13.575 11.4742 13.925 11.2492 14.15C11.0992 14.25 10.9742 14.325 10.8242 14.325Z"
            fill="#434343"
          />
        </svg>
      </li>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
            className={classnames("pagination-item", {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames("arrow", {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          role="presentation"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="arrow right"
        >
          <path
            d="M5.17578 14.325C5.32578 14.325 5.47578 14.275 5.57578 14.15L11.2258 8.40002C11.4508 8.17502 11.4508 7.82503 11.2258 7.60002L5.57578 1.85002C5.35078 1.62502 5.00078 1.62502 4.77578 1.85002C4.55078 2.07502 4.55078 2.42502 4.77578 2.65002L10.0258 8.00003L4.75078 13.35C4.52578 13.575 4.52578 13.925 4.75078 14.15C4.90078 14.25 5.02578 14.325 5.17578 14.325Z"
            fill="#434343"
          />
        </svg>
      </li>
    </ul>
  );
};

export default Pagination;
