import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import CustomButton from "../../../components/CustomButton";

const AuthorCard = ({ author }) => {
  const [isSeeMore, setIsSeeMore] = useState(true);
  const bio = author?.data?.bio?.text?.slice(
    0,
    author?.data?.bio?.text.indexOf("LinkedIn")
  );

  const bioText = bio.slice(0, 190);
  return (
    <section className="bg-white p-3.5 flex flex-col gap-3.5 border border-gray-1600 rounded-[14px] lg:rounded-[10px] text-left">
      
      <div className="flex py-1.5 items-center gap-2.5 ">
        <GatsbyImage
          image={getImage(author?.data?.avatar)}
          className="rounded-full size-12"
          alt="author-avatar-url"
        />

        <div>
          <p className="tracking-[-0.9px] font-manrope text-purple-1100 text-lg leading-7 font-semibold">
            {author?.data?.full_name?.text}
          </p>
          {author?.data?.role?.text && (
            <p className="tracking-[-0.35px] font-manrope text-gray-600 text-sm font-semibold">
              {author?.data?.role?.text}
            </p>
          )}
        </div>
      </div>

      <CustomButton
        link={`/author/${author.uid}/`}
        text={"View more posts"}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            role="presentation"
            fill="none"
          >
            <path
              d="M2.07874 7.51953L10.0782 7.51953L5.29027 11.6758C5.2137 11.7428 5.26019 11.8672 5.36136 11.8672L6.57132 11.8672C6.62464 11.8672 6.67523 11.848 6.71487 11.8139L11.8815 7.33086C11.9288 7.28984 11.9667 7.23913 11.9928 7.18217C12.0188 7.1252 12.0322 7.06331 12.0322 7.00068C12.0322 6.93806 12.0188 6.87617 11.9928 6.8192C11.9667 6.76224 11.9288 6.71153 11.8815 6.67051L6.6848 2.16016C6.66429 2.14238 6.63968 2.13281 6.6137 2.13281L5.36273 2.13281C5.26155 2.13281 5.21507 2.25859 5.29163 2.32422L10.0782 6.48047L2.07874 6.48047C2.01858 6.48047 1.96937 6.52969 1.96937 6.58984L1.96937 7.41016C1.96937 7.47031 2.01858 7.51953 2.07874 7.51953Z"
              fill="#434343"
            />
          </svg>
        }
        direction="right"
        buttonClass="text-sm !font-semibold no-underline tracking-tight !text-gray-2500 !font-normal bg-gray-1600 font-manrope !px-3"
      />
    </section>
  );
};

export default AuthorCard;
