import React from "react";
import VinciTeamMemberCard from "../../Pieces/VinciTeamMemberCard";
import lightVector from "../../../assets/backgrounds/HeroRaphael/darkVector.png";
const SectionVinci = ({ slice }) => {
  const teamMembers = slice?.items || [];
  return (
    <article className="relative">
      {
        <img
          src={lightVector}
          loading="eager"
          alt="stats"
          className="absolute z-0 hidden -top-[80px] xl:-top-[200px] lg:block"
        />
      }
      <section className="px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
        <article className="py-10 lg:py-20 lg:mx-11">
          <div className="flex flex-col gap-10 ">
            <div
              role="heading"
              aria-level="2"
              className={`text-purple-1100 text-center text-lg lg:max-w-[852px] sm:text-3.25xl sm:leading-10 sm:-tracking-[1.8px] font-manrope font-semibold self-center`}
              dangerouslySetInnerHTML={{
                __html: slice?.primary?.header_title?.html,
              }}
            />
            <div className="flex flex-col gap-5">
              <div className="flex flex-col items-center justify-center gap-5 sm:flex-row">
                {teamMembers.slice(0, 2).map((member, index) => (
                  <VinciTeamMemberCard key={index} authordata={member} />
                ))}
              </div>
              <div className="flex flex-col flex-wrap items-center justify-center gap-5 sm:flex-row lg:flex-nowrap">
                {teamMembers.slice(2).map((member, index) => (
                  <VinciTeamMemberCard key={index + 2} authordata={member} />
                ))}
              </div>
            </div>
          </div>
        </article>
      </section>
    </article>
  );
};

export default SectionVinci;
