import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { emailRegex, HUBSPOT_PROXY_URL } from "../constants";
import { isDomainBlocked } from "../utilities/helpers";

const EaModalLeadForm = ({
  handleClose,
  portalId = "25381551",
  formId = "9a58906a-b31f-4ecf-98d3-e11aa70fc904",
  btnText = "Get Free Trial",
  size = "lg",
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    if (!error) {
      e.preventDefault();
      const [cookie] = document.cookie
        .split("; ")
        .filter((x) => x.includes("hubspotutk="));
      const [, hubspotutk] = cookie?.split("=") || ["", ""];
      setError("");
      try {
        if (emailRegex.test(email) && !isDomainBlocked(email)) {
          setLoading(true);

          const requestPayload = {
            fields: [
              {
                objectTypeId: "0-1",
                name: "email",
                value: email,
              },
            ],
            context: {
              pageUri: location?.href,
              pageName: location?.pathname,
            },
          };

          if (hubspotutk) {
            requestPayload.context.hutk = hubspotutk;
          }
          const res = await axios.post(
            HUBSPOT_PROXY_URL,
            Object.assign(requestPayload, {
              portalId,
              formId,
            })
          );
          /**
           * Append custom properties to Heap
           */
          if (window.heap) {
            window.heap.addUserProperties({
              marketing_email: email,
            });
          }

          handleClose && handleClose();
          if (res?.data?.inlineMessage) {
            toast.success(
              <div
                dangerouslySetInnerHTML={{ __html: res?.data?.inlineMessage }}
              />,
              {
                position: "top-right",
              }
            );
          }
        } else {
          setError("Email must be formatted correctly.");
        }
      } catch (error) {
        if (
          error?.response?.data &&
          error?.response?.data.errors &&
          error?.response?.data.errors[0]?.message
        ) {
          const [, errorMessage] =
            error?.response?.data?.errors[0]?.message.split(". ") || ["", ""];
          if (errorMessage) {
            setError(errorMessage ?? error?.response?.data?.errors[0]?.message);
          }
        } else {
          setError("Please enter your work e-mail.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const buttonSize = {
    md: { input: "py-2 text-sm", button: "btn-md text-sm" },
    lg: { input: "py-3 text-lg", button: "btn" },
  };

  return (
    <div>
      <form
        className="relative flex flex-col flex-wrap gap-3 mb-4 commercial-form sm:flex-row"
        onSubmit={handleSubmit}
      >
        <div className="w-full md:w-[418px] min-h-[auto]">
          <legend
            className="hs-field-desc"
            style={{ display: "none" }}
          ></legend>
          <div className="">
            <input
              aria-label="email"
              name="email"
              placeholder="Your work email"
              type="email"
              className={`w-full ${buttonSize[size]?.input} placeholder-[#8C8C8C] rounded-4xl leading-6 border border-transparent disabled:bg-gray-300 text-gray-1200 bg-gray-50 px-4 focus:outline-none focus:ring-1 focus:ring-purple-500 shadow-lg`}
              required
              disabled={loading}
              onChange={(event) => {
                setEmail(event.target.value);
                setError("");
              }}
              value={email}
            />
          </div>
          {error && (
            <ul className="pt-1 text-left text-red-500" role="alert">
              <li>
                <label>{error}</label>
              </li>
            </ul>
          )}
        </div>
        <div className="relative w-full md:w-auto md:absolute md:right-0">
          <div className="hs-field-desc" style={{ display: "none" }}></div>
          <div className="actions">
            <button
              type="submit"
              disabled={loading || !!error}
              className={`hs-button ${buttonSize[size]?.button} w-full text-white bg-purple-500 hover:bg-purple-400 shrink-0 disabled:bg-purple-400 rounded-full font-semibold border border-purple-500`}
            >
              {loading ? (
                <div className="flex items-center">
                  Submitting
                  <div className="w-4 h-4 ml-2 border-2 border-white border-solid rounded-full animate-spin border-t-transparent"></div>
                </div>
              ) : (
                <>{btnText}</>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EaModalLeadForm;
