import React, { useState, useEffect } from "react";
import { Chevron } from "../../../assets/icons";
import CustomButton from "../../CustomButton";
import { Link } from "gatsby-link";

const CTAPicassolCard = ({ data }) => {
  const twoCards = data?.items?.length === 2;
  return (
    <section>
      {data?.primary?.section_heading?.text && (
        <section className="flex justify-start">
          <p className=" text-purple-1100 pb-5 sm:pb-10 text-2xl sm:text-3.25xl font-manrope text-left tracking-tighter_3 font-semibold sm:tracking-[-1.8px] max-w-60 sm:max-w-full ">
            {data?.primary?.section_heading?.text}
          </p>
        </section>
      )}

      <div
        className={`flex flex-row gap-6 ${twoCards && "justify-between flex-1"} overflow-x-auto HideScrollBar scroll-smooth cs_slide_opacity_right`}
      >
        {data?.items.map((item, index) => (
          <Link
            key={index}
            href={item?.cta_link?.url}
            className={`cta_picasso_card flex flex-col p-5 sm:p-7 justify-between ${twoCards ? " flex-[0.5]" : "lg:max-w-[368px] max-w-[360px]"}  lg:min-w-[368px] min-w-[360px]  sm:min-w-[564px] rounded-md border dark:border-[#f0f0f04d] border-gray-1600 bg-white dark:bg-[#ffffff0d]`}
          >
            <div className="flex flex-col gap-3.5 md:gap-4 ">
              <img
                src={item?.card_image?.url}
                alt="Cta picasso card image"
                loading="lazy"
                width={320}
                height={0}
                className={`rounded-md ${twoCards ? "w-full" : "lg:max-w-[312px] min-w-[320px] sm:min-w-[476px]  lg:min-w-[312px]"}`}
              />
              <div className="flex flex-row gap-1.5 mt-1 sm:mt-6 items-center">
                <img
                  src={item?.product_image?.url}
                  width={28}
                  height={28}
                  loading="lazy"
                  alt="Cta picasso card product image"
                />
                <p className="text-base sm:text-xl font-medium text-gray-2500 tracking-[-0.8px]">
                  {item?.product_name?.text}
                </p>
              </div>

              <h2
                className={`font-manrope tracking-[-1.2px] mx-0 max-w-[476px] ${
                  index === 2
                    ? "lg:max-w-[275px]"
                    : `${twoCards ? "max-w-[318px] sm:max-w-full" : "lg:max-w-[312px]"}`
                } sm:tracking-tighter_3 mt-1 leading-9 font-semibold text-2xl sm:text-2.5xl  text-purple-1100 dark:text-white`}
              >
                {item?.title?.text}
              </h2>
              <p className="text-sm sm:text-lg lg:text-base leading-6 text-gray-2500 dark:text-gray-2200 font-normal tracking-tight_1 font-inter min-h-12">
                {item?.description?.text}
              </p>
            </div>
            <CustomButton
              text={item?.cta_text?.text}
              icon={<Chevron />}
              direction="right"
              buttonClass="bg-gray-1600 mt-5 hover:bg-gray-2900 dark:hover:bg-purple-1100 text-gray-2500 text-base dark:bg-[#9c8cff4d] dark:!text-purple-1900 tracking-tighter_2"
            />
          </Link>
        ))}
      </div>
    </section>
  );
};

export default CTAPicassolCard;
