import axios from "axios";
import React, { useState } from "react";
import { HUBSPOT_PROXY_URL } from "../constants";
import { footerTheme } from "../partials/Footer";

const SignUpForm = ({ subscribe, theme = "default" }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submittedState, setSubmittedState] = useState("none");
  console.log(theme, "theme");
  const subscribeToNewsLetter = async (e) => {
    try {
      e.preventDefault();
      setMessage("");
      const [cookie] = document.cookie
        .split("; ")
        .filter((x) => x.includes("hubspotutk="));
      const [, hubspotutk] = cookie?.split("=") || ["", ""];

      if (email.length < 3) {
        return false;
      }

      const requestPayload = {
        fields: [
          {
            objectTypeId: "0-1",
            name: "email",
            value: email,
          },
        ],
        context: {
          pageUri: location?.href,
          pageName: location?.pathname,
        },
      };

      if (hubspotutk) {
        requestPayload.context.hutk = hubspotutk;
      }

      const res = await axios.post(
        HUBSPOT_PROXY_URL,
        Object.assign(requestPayload, {
          portalId: "25381551",
          formId: "7a9fb3d8-2bac-4efd-9c4c-186560ce075c",
        })
      );

      setSubmittedState("success");
      setEmail("");
      setMessage(res?.data?.inlineMessage || "Thanks for subscribing!");
    } catch (error) {
      setMessage("");
      setSubmittedState("error");
    }
  };

  return (
    <div>
      <div className="flex flex-wrap mb-4">
        <div className="w-full">
          <label className="block text-sm sr-only" htmlFor="newsletter">
            Email
          </label>
          <form
            className={`${footerTheme[theme]?.subFormContainerCalsses}`}
            onSubmit={(e) => subscribeToNewsLetter(e)}
          >
            <input
              aria-label="email"
              type="email"
              name="EMAIL"
              className={`h-11 w-full px-3 py-2.5 text-sm lg:text-base text-gray-800 border-none rounded-none focus:ring-white focus:border-white placeholder:text-gray-2200`}
              placeholder="Your work email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div className="bg-white">
              <button
                type="submit"
                className={` bg-purple-1500 px-3 py-2 rounded-full mx-[5px] my-[3px]`}
                aria-label="Subscribe"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  role="presentation"
                  class="size-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                  />
                </svg>
              </button>
            </div>
          </form>
          {submittedState === "success" && (
            <p className="mt-2 text-sm text-green-600">
              {subscribe ? (
                <div>
                  {message ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: message,
                      }}
                    />
                  ) : (
                    "Thanks for subscribing!"
                  )}
                </div>
              ) : (
                "Thanks for subscribing!"
              )}
            </p>
          )}
          {submittedState === "error" && (
            <p className="mt-2 text-sm text-red-600">Something went wrong</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
