import React from "react";

export const BottomToTopArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="60"
      viewBox="0 0 7 60"
      fill="none"
      role="presentation"
      className="absolute bottom-[175px] left-[50px] sm:hidden "
    >
      <path d="M3.24805 0L0.361296 5L6.1348 5L3.24805 0Z" fill="#5E44FF" />

      <path
        class="moving_dots_left_to_right"
        d="M2.74805 60L3.24805 4.9"
        stroke="url(#paint0_linear_BottomToTopArrow)"
        stroke-width="1.2"
        stroke-dasharray="2.4 2.4"
      />
          <defs>
        <linearGradient
          id="paint0_linear_BottomToTopArrow"
          x1="2.5"
          y1="0"
          x2="2.5"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#BFBFBF" />
          <stop offset="1" stop-color="#5E44FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
