import React, { useContext } from "react";
import HeadingSection from "../../Pieces/HeadingSection";
import ModalContext from "../../../Contexts/openModal";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useWindowWidth from "../../../hooks/useBetterMediaQuery";

const FeaturePicasso = ({ slice }) => {
  const { toggleModal } = useContext(ModalContext);
  const emptyHeading = !!(
    slice?.primary?.caption_title?.text ||
    slice?.primary?.show_cta ||
    slice?.primary?.description?.text ||
    slice?.primary?.title?.text
  );
  const isMobile = useWindowWidth(640);
  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      {emptyHeading && (
        <article className="lg:mx-11">
          <HeadingSection
            data={slice?.primary}
            toggleModal={toggleModal}
            sectionStyle="sm:max-w-[648px] tracking-[-0.45px] md:max-w-[1152px] lg:!pb-10"
            descriptionStyle="lg:text-lg lg:tracking-tighter_1"
            textAreaStyle="gap-4"
            headingStyle="sm:text-3.25xl sm:leading-10 sm:!-tracking-[0.9px]"
          />
        </article>
      )}
      <div className="flex flex-col gap-10 sm:gap-2.5 xl:px-0 mx-auto sm:flex-row justify-center pb-10 flex-wrap sm:gap-y-3">
        {slice?.items?.map((picasso, index) => {
          return (
            <div
              key={index}
              className={`flex flex-col mx-auto gap-3 lg:max-w-[420px] xl:max-w-[516px] flex-1 lg:flex-none`}
            >
              {!isMobile ? (
                <GatsbyImage
                  sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw"
                  objectFit="contain"
                  image={getImage(
                    picasso?.mobile_asset.url
                      ? picasso?.mobile_asset
                      : picasso?.feature_image
                  )}
                  alt={picasso?.mobile_asset?.alt || "picasso feature_image"}
                  className="block object-scale-down sm:hidden"
                  loading="lazy"
                />
              ) : (
                <GatsbyImage
                  sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw"
                  image={getImage(picasso?.feature_image)}
                  objectFit="contain"
                  alt={picasso?.feature_image?.alt || "picasso feature_image"}
                  className="lg:min-h-[360px] h-full max-h-[360px] object-scale-down hidden sm:block"
                  loading="lazy"
                />
              )}

              <p className="text-purple-1100 font-manrope text-xl tracking-[-0.5px] font-semibold lg:min-h-fit">
                {picasso?.title?.text}
              </p>
              <p className="text-base font-normal text-gray-600 tracking-[-0.4px] flex-1">
                {picasso?.description?.text}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};
export default FeaturePicasso;
