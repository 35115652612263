import React, { useContext, useEffect, useState, useRef } from "react";
import posterVideo from "../../../assets/videoPoster.png";
import VideoComponent from "../../VideoComponent";
import HeadingSection from "../../Pieces/HeadingSection";
import ModalContext from "../../../Contexts/openModal";
import "../../../styles/components/Slices/sectionMunch.scss";
import { useMediaQuery } from "react-responsive";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Video = ({ src, posterImage }) => {
  return (
    <VideoComponent
      poster={posterImage || posterVideo}
      data-aos="fade"
      data-aos-delay="150"
      key={src}
      className="h-full rounded-md"
    >
      <source src={src} type="video/mp4" />
    </VideoComponent>
  );
};

const SectionMunch = ({ slice }) => {
  const topSpacing = slice?.primary?.top_spacing;
  const bottomSpacing = slice?.primary?.bottom_spacing;
  const { toggleModal } = useContext(ModalContext);
  const [activeAnnotation, setActiveAnnotation] = useState(0);
  const [progress, setProgress] = useState(0);
  const [hovered, setHovered] = useState(false);
  const videoRef = useRef(null);
  const intervalRef = useRef(null);
  const isMobileOrTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  useEffect(() => {
    if (!hovered) {
      intervalRef.current = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(intervalRef.current);
            handleVideoEnd();
            return 100;
          }
          return prevProgress + 0.6;
        });
      }, 50);
    }

    return () => clearInterval(intervalRef.current);
  }, [activeAnnotation, hovered]);

  const handleVideoEnd = () => {
    const nextIndex =
      activeAnnotation + 1 < slice?.items?.length ? activeAnnotation + 1 : 0;
    setActiveAnnotation(nextIndex);
    setProgress(0);
  };

  const emptyHeading = !!(
    slice?.primary?.caption_title?.text ||
    slice?.primary?.show_cta ||
    slice?.primary?.description?.text ||
    slice?.primary?.title?.text
  );

  return (
    <section
      className={`py-8 ${topSpacing === "80" && "sm:pt-20"} ${
        topSpacing === "40" && "sm:pt-10"
      } ${bottomSpacing === "80" && "sm:pb-20"} ${
        bottomSpacing === "40" && "sm:pb-10"
      } relative px-4 mx-auto sm:px-12 lg:px-5 max-w-6xl`}
    >
      {emptyHeading && (
        <HeadingSection
          data={slice?.primary}
          toggleModal={toggleModal}
          // descriptionStyle="max-w-[890px]"
          textAreaStyle="gap-4"
          sectionStyle="lg:!pb-10"
        />
      )}
      <section
        className={`flex flex-col gap-5 sm:gap-[18px] lg:gap-8 mx-auto sm:flex-row `}
      >
        <div className="hidden sm:block lg:mt-0 w-full sm:max-w-[322] lg:max-w-[284px]  xl:max-w-[412px] sm:h-[417px]">
          {slice?.items[activeAnnotation]?.label_slide_video?.url ? (
            <div className="justify-center lg:justify-self-start flex w-full sm:w-auto !h-auto max-w-2xl lg:h-[412px] duration-[1s] ease-in-out">
              <Video
                ref={videoRef}
                src={slice?.items[activeAnnotation]?.label_slide_video?.url}
                posterImage={posterVideo}
              />
            </div>
          ) : (
            <GatsbyImage
              image={getImage(
                slice?.items[activeAnnotation]?.label_slide_image
              )}
              // src={slice?.items[activeAnnotation]?.label_slide_image?.url}
              width={0}
              height={0}
              alt={
                slice?.items[activeAnnotation]?.label_slide_image?.alt ||
                "supporting image"
              }
              loading="lazy"
              className={`w-full h-auto max-w-2xl rounded-md fade_up`}
            />
          )}
        </div>
        <div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 sm:gap-y-5 lg:gap-y-8 gap-x-6 w-full sm:w-[332px] lg:w-[668px] overflow-hidden">
            {slice?.items?.map(
              (
                {
                  label_icon,
                  label,
                  active_label_icon,
                  label_slide_video,
                  label_slide_image,
                },
                index
              ) => (
                <div
                role="button" 
                tabIndex={0} 
                  className={`flex flex-col cursor-pointer w-full sm:max-w-[332px] `}
                  key={index}
                  onMouseEnter={
                    !isMobileOrTablet
                      ? () => {
                          if (activeAnnotation !== index) {
                            setActiveAnnotation(index);
                            setProgress(0);
                          }
                          setHovered(true);
                        }
                      : () => {
                          setActiveAnnotation(index);
                          setProgress(0);
                          // setHovered(false);
                        }
                  }
                  onMouseLeave={
                    !isMobileOrTablet ? () => setHovered(false) : undefined
                  }
                >
                  <div className="block sm:hidden">
                    {label_slide_video?.url ? (
                      <div
                        className={`justify-center  flex w-full h-full  max-w-2xl video_container ${
                          activeAnnotation === index
                            ? ""
                            : "video_container_hidden"
                        }`}
                      >
                        <Video
                          ref={videoRef}
                          src={label_slide_video?.url}
                          posterImage={posterVideo}
                        />
                      </div>
                    ) : (
                      label_slide_image?.url &&
                      activeAnnotation === index && (
                        <GatsbyImage
                          image={getImage(label_slide_image)}
                          width={0}
                          height={0}
                          alt={label_slide_image?.alt || "supporting image"}
                          loading="lazy"
                          className={`w-full max-w-2xl h-full rounded-md ${
                            activeAnnotation === index
                              ? "fade_up"
                              : "fade_up_hidden"
                          }`}
                        />
                      )
                    )}
                  </div>
                  <div
                    className={`flex items-center ${
                      activeAnnotation === index && "mt-3"
                    } sm:mt-0 gap-2.5`}
                  >
                    <GatsbyImage
                      image={getImage(
                        activeAnnotation === index
                          ? active_label_icon
                          : label_icon
                      )}
                      // src={
                      //   activeAnnotation === index
                      //     ? active_label_icon?.url
                      //     : label_icon?.url
                      // }
                      width={0}
                      height={0}
                      alt={label_icon?.alt || "label icon"}
                      loading="lazy"
                      className={`w-5 h-5`}
                    />
                    <p
                      className={`text-xl font-semibold font-manrope tracking-tighter_1 ${
                        activeAnnotation !== index
                          ? "text-gray-2700"
                          : "text-purple-1100"
                      } sm:tracking-tighter_2`}
                    >
                      {label?.text}
                    </p>
                  </div>
                  <div
                    className={`w-full sm:max-w-[332px] h-1 mt-3 bg-gray-1400 ${
                      activeAnnotation === index ? "opacity-100" : "opacity-0"
                    }`}
                  >
                    <div
                      className="h-1 bg-purple-1500"
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </section>
    </section>
  );
};

export default SectionMunch;
