import "../../styles/components/CTAs.scss";

export const debugModelsEACTA = () => {
  return `
        <section class="flex flex-col-reverse sm:flex-row gap-3 2xl:gap-4 my-9 rounded-2.5xl px-5 2xl:px-8 bg-gray-2300">
      <section class="flex flex-col flex-1 justify-center py-10 text-purple-1100 relative z-10">
      <section class="-z-10 hidden sm:block">
        <img src="https://images.prismic.io/encord/7cc79d51-2418-46f2-9435-8db0c70c3f89_Vector.png?auto=compress,format" class=" absolute left-16 top-5" alt="vector-try-encord" alt="debugModelsEACTA image"/>
      </section>
      <div class="text-2xl xl:text-xl lgxl_mid:text-xl 2xl:text-2xl max-w-sm 2xl:max-w-none font-semibold">Scale your annotation workflows and power your model performance with data-driven insights</div>
  
      
      <div class="text-base my-5 xl:text-sm 2xl:text-lg"></div> 
  
      <a
          href="https://encord.com/try-it-free/?&utm_campaign=cta-blog-encord-light"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="opens in a new tab"
          class="mb-2 flex demo-cta max-w-fit !text-white px-[20px] py-[5px] sm:px-[38px] sm:py-[14px] rounded-full sm:text-[14.94px] text-2xs leading-[18.08px] font-bold bg-purple-500"
        >
          Try Encord today
        </a>
      </section>
  
        <section class="flex-1 mt-6 z-10">
          <img 
            src="https://images.prismic.io/encord/b5dc034e-8fa0-4a5e-99e8-4b55a60479e9_cta-img-3.png?auto=compress,format" 
            height="747" 
            width="949" 
            alt="medical banner" 
            class="h-full"
          />
        </section>
    </section>`;
};
